import * as React from 'react';

export interface State {
    value: string | number;
    blockUpdates: boolean;
}

export interface Props {
    value: string | number;
    name: string;
    editValue: (name: string, value: string) => void;
    inputClassname?: string;
    disabled?: boolean;
}

export class InlineInputEdit extends React.Component<Props, State> {
    constructor(props: Props) {
        super(props);

        this.state = {
            value: props.value,
            blockUpdates: false
        };

        this.onFocus = this.onFocus.bind(this);
        this.onBlur = this.onBlur.bind(this);
        this.onChange = this.onChange.bind(this);
    }

    componentWillReceiveProps(nextProps: Props) {
        // Only update this component if we're not currently editing it
        if (!this.state.blockUpdates) {
            this.setState({
                value: nextProps.value
            });
        }
    }

    render() {
        return (
            <input
                className={this.props.inputClassname}
                value={this.state.value}
                onChange={this.onChange}
                onFocus={this.onFocus}
                onBlur={this.onBlur}
                disabled={this.props.disabled}
            />
        );
    }

    private onFocus(e: React.SyntheticEvent<HTMLInputElement>) {
        this.setState({
            blockUpdates: true
        });
    }

    private onBlur(e: React.SyntheticEvent<HTMLInputElement>) {
        // Call method to update value if value has changed
        if (this.props.value !== this.state.value) {
            this.props.editValue(this.props.name, this.state.value.toString());
        }

        // Reenable updating for this component
        this.setState({
            blockUpdates: false
        });
    }

    private onChange(e: React.SyntheticEvent<HTMLInputElement>) {
        this.setState({
            value: e.currentTarget.value
        });
    }
}