import { ApiResult  } from '../models/Api';
import { httpRequest, Urls } from './Shared';

const RESETTOTAL_URL = '/ResetTotal';

export const resetTotal = (deviceId: number, totalName: string):
    Promise<ApiResult<object>> => {
    return httpRequest( `${Urls.BASE_URL}${RESETTOTAL_URL}/${deviceId}`,
                        'post',
                        { 
                            'totalName': totalName
                        });
};