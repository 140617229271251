import decodeJwt from 'jwt-decode';
import { LogInResponse } from '../models/Api';

const ROLE_CLAIM: string = 'http://schemas.microsoft.com/ws/2008/06/identity/claims/role';

export enum USER_ROLE {
    CUSTOMER = 'Customer',
    ADMINISTRATOR = 'Administrator',
}

export const isAuthenticated = (): boolean => {
    return getToken() !== '';
};

export const getRole = (): string => {
    let token = getToken();
    if (token !== '') {
        let decodedToken = decodeJwt<any>(token);
        return decodedToken[ROLE_CLAIM];
    }
    return '';
};

const getUserRolesEnum = (): USER_ROLE[] => {
    const rolesString: string | null = getRole();
    if (rolesString) {
      const r: string[] = rolesString.split(', ');
      let roles = r.map(role => role = USER_ROLE[role.toUpperCase()]);
      return roles;
    } else {
      return [];
    }
  };

export const isAdminRole = (): boolean => {
    let roles: USER_ROLE[] = getUserRolesEnum();
    return roles.indexOf(USER_ROLE.ADMINISTRATOR) >= 0;
};

export const getToken = (): string => {
    return localStorage.getItem('token') || '';
};

export const getRefreshToken = (): string => {
    return localStorage.getItem('refreshToken') || '';
};

export const setTokens = (response: LogInResponse) => {
    localStorage.setItem('token', response.token);
    localStorage.setItem('refreshToken', response.refreshToken);
};

export const clearTokens = () => {
    localStorage.removeItem('token');
    localStorage.removeItem('refreshToken');
};
