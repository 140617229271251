import { ApiResult, LogInResponse } from '../models/Api';
import * as identity from '../identity';

const REFRESH_URL = '/Account/Refresh';

// If a URL is provided during the build, use that, otherwise
// default to the production URL
const BASE_URL: string = 
    process.env.REACT_APP_API_URL ?
    process.env.REACT_APP_API_URL :
    'https://agri-inject-api.azurewebsites.net/api';

console.log(`URL: '${BASE_URL}'`);
export const Urls = {
    BASE_URL
};

// promise used during automatic token refresh attempts
let tokenRefreshPromise: Promise<void> | null = null;

export function buildRequest(requestUrl: string, method: string = 'get', body?: any): Request {
    let headers: Headers = new Headers();
  
    headers.append('Accept', 'application/json');
    if (identity.isAuthenticated()) {
      headers.append('Authorization', `Bearer ${identity.getToken()}`);
    }
  
    let bodyStringified: string | undefined = undefined;
  
    if (body) {
      bodyStringified = JSON.stringify(body);
      headers.append('Content-Type', 'application/json');
    }
  
    return new Request(requestUrl, {
      method: method,
      body: bodyStringified,
      headers: headers
    });
}

export function updateRequestAuth(request: Request) {
    if (request.headers.has('Authorization')) {
        request.headers.delete('Authorization');
    }
    request.headers.append('Authorization', `Bearer ${identity.getToken()}`);
    return request;
}

export function checkTokenExpiration(request: Request, initialResponse: Response)
    : Promise<Response> {
        // console.log(initialResponse.status);
        if (initialResponse.status === 401) { 
            if (!tokenRefreshPromise) {
                let refreshRequest = buildRequest(`${Urls.BASE_URL}${REFRESH_URL}`, 
                                                  'post', 
                                                  { 'refreshToken': identity.getRefreshToken() });
                tokenRefreshPromise = fetch(refreshRequest)
                    .then((response) => checkStatus(response))
                    .then((response) => parseJSON<LogInResponse>(response))
                    .then((data) => {
                        identity.setTokens(data!);
                        tokenRefreshPromise = null;
                    })
                    .catch((error) => {
                        console.log(error);
                        tokenRefreshPromise = null;
                        identity.clearTokens();
                        window.location.reload();
                    });
            }

            return tokenRefreshPromise
                .then(() => {
                    return fetch(updateRequestAuth(request));
                });
        }    
        return Promise.resolve(initialResponse);
}

export function checkStatus(response: Response): Promise<Response> {
    if (response.status >= 200 && response.status < 300) {
      return Promise.resolve(response);
    } else {
      let error = new Error(response.statusText);
      throw error;
    }
}

export function parseJSON<T>(response: Response): Promise<T | undefined> {
    if (response.status !== 204) {
        return response.json() as Promise<T>;
    } else {
        return Promise.resolve(undefined);
    }
    
}

export function httpRequest<T>(requestUrl: string, method: string = 'get', body?: any): Promise<ApiResult<T>> {
  let request = buildRequest(requestUrl, method, body);
    
  return fetch(request)
    .then((response) => checkTokenExpiration(request, response))
    .then((response) => checkStatus(response))
    .then((response) => parseJSON<T>(response))
    .then((data) => {
        return {
            success: true,
            data: data
        };
    })
    .catch((error) => {
        console.log(`Error in httpRequest. ${error}`);
        return {
            success: false,
            error: error.message
        };
    });
}

export const downloadFile = (url: string, fileName: string): Promise<boolean | void> => {
    // Setup authorization:
    let headers = new Headers();
    const token: string = identity.getToken();
    headers.append('Authorization', `Bearer ${token}`);

    let anchor = document.createElement('a');
    // Ajax fetch the url with Authorization Header
    return fetch(url, { headers })
    // Convert the 'text/csv' response to a blob
    .then(response => response.blob())
    .then(blob => {
            // Create a dummy url that contains the file as a blob
            let objectUrl = window.URL.createObjectURL(blob);

            // Set our dummy url as the HRef in our hidden anchor tag
            anchor.href = objectUrl;
            // Set the downloaded file name
            anchor.download = fileName;
            // Click the hidden anchor tag to download the blobFile
            anchor.click();

            // Destroy the dummy URL
            window.URL.revokeObjectURL(objectUrl);
        });
};