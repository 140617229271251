import * as React from 'react';
import { withRouter, RouteComponentProps, Route, Switch, Redirect } from 'react-router-dom';
import { Device, DeviceConfiguration, DeviceStatus, GetEmptyDevice,
    GetEmptyDeviceConfig, GetEmptyDeviceStatus, Version } from '../models/Api';
import * as deviceApi from '../api/Devices';
import * as deviceConfigApi from '../api/DeviceConfiguration';
import * as deviceStatusApi from '../api/DeviceStatus';
import Footer from '../components/Footer';
import Header from '../components/Header';
import { DeviceDetailsStatusBar } from '../components/DeviceDetailsStatusBar';
import NavButton from '../components/NavButton';
import DeviceOverviewSubPage from './DeviceDetails/DeviceOverviewSubPage';
import DeviceAlarmsSubPage from './DeviceDetails/DeviceAlarmsSubPage';
import DeviceSettingsSubPage from './DeviceDetails/DeviceSettingsSubPage';
import DeviceLogSubPage from './DeviceDetails/DeviceLogSubPage';
import '../styles/DeviceDetails.css';
import { ConfigurationSelectType } from 'src/components/ConfigurationSelect';
import { ConfigurationSelect } from 'src/components/ConfigurationSelect';
import { postEvent } from 'src/api/Events';
import { isNullOrUndefined } from 'util';

interface State {
    isFound: boolean | null;
    device: Device;
    deviceConfiguration: DeviceConfiguration;
    deviceStatus: DeviceStatus;
    // Devices that are selectable from the dropdown
    customerDevices: ConfigurationSelectType[];
    newDeviceSelected: boolean;
}

export interface Props extends RouteComponentProps<{id: string}> {

}

class DeviceDetailsPage extends React.Component<Props, State> {
    private dataTimerId: number | null = null;

    constructor(props: Props) {
        super(props);

        this.state = {
            isFound: null,
            device: GetEmptyDevice(Number(this.props.match.params.id)),
            deviceConfiguration: GetEmptyDeviceConfig(),
            deviceStatus: GetEmptyDeviceStatus(),
            customerDevices: [],
            newDeviceSelected: false
        };
    }

    componentWillMount() {
        this.updateDevices();
    }

    updateDevices = () => {
        deviceApi.getDevices()
        .then((result) => {
            if (result.success) {
                // convert the DeviceSummary objects to simple
                // ConfigurationSelect objects
                let s: ConfigurationSelectType[] = result.data!.map(r => {
                    return {name: r.name, value: r.id};
                });
                
                this.setState({
                    ...this.state,
                    customerDevices: s,
                });
            } else {
                console.log('get devices error: ' + result.error);
            }
        });
    }
    
    componentDidMount() {
        window.scrollTo(0, 0);
        this.getCurrentData();
    }

    componentWillUnmount() {
        if (this.dataTimerId !== null) {
            window.clearInterval(this.dataTimerId);
            this.dataTimerId = null;
        }
    }

    deviceDropDownSelectionChanged = (name: string, id: string) => {
        this.setState({
            device: GetEmptyDevice(parseInt(id, 10)),
            deviceConfiguration: GetEmptyDeviceConfig(),
            deviceStatus: GetEmptyDeviceStatus(),
            newDeviceSelected: true
        });
    }

    render() {

        if (this.state.newDeviceSelected) {
            this.setState({newDeviceSelected: false});
            return <Redirect to={`/device/${this.state.device.id}`} />;
        }
        // device might be undefined if an api error occurred
        if (isNullOrUndefined(this.state.device)) {
            console.log('failed to load device information');
            return <Redirect to="/" />;
        }
        var version: Version = {
            major: this.state.device.versionMajor,
            minor: this.state.device.versionMinor,
            revision: this.state.device.versionRev
        };
        
        return (
            <div className="flex-root">
            <Header/>
            <div className="device-details-header">
                <div className="col">
                    <div className="row text-medium-blue label-medium">
                        NAME
                    </div>
                    <div className="row">
                        <ConfigurationSelect 
                            name="DeviceName"
                            style="deviceSelection-style text-white label-very-large"
                            options={this.state.customerDevices}
                            value={Number(this.props.match.params.id)}
                            editValue={this.deviceDropDownSelectionChanged}
                        />
                    </div>
                </div>
                <div className="row">
                    <div className="col-12 col-md-8">
                        <DeviceDetailsStatusBar
                            group={this.state.deviceConfiguration.group}
                            serialNumber={this.state.device.name}
                            status={this.state.deviceStatus.systemStatus}
                            heartbeat={this.state.device.heartbeat}
                            iotKey={this.state.device.iotKey}
                            isDisconnected={this.state.device.isDisconnected}
                        />
                    </div>
                </div>

                <div className="row">
                    <NavButton
                        text="OVERVIEW"
                        path={'/device/' + this.props.match.params.id + '/overview'}
                        alternatePaths={['/device/' + this.props.match.params.id]}
                        className="ml-0"
                    />
                    <NavButton
                        text="ALARMS"
                        path={'/device/' + this.props.match.params.id + '/alarms'}
                        className="ml-4"
                    />
                    <NavButton
                        text="SETTINGS"
                        path={'/device/' + this.props.match.params.id + '/settings'}
                        className="ml-4"
                    />
                    <NavButton
                        text="LOG"
                        path={'/device/' + this.props.match.params.id + '/log'}
                        className="ml-4"
                    />
                </div>
            </div>
            <Switch>
                <Route 
                    path={'/device/:id'} 
                    exact={true}
                    render={() => 
                        <DeviceOverviewSubPage
                            device={this.state.device}
                            deviceConfig={this.state.deviceConfiguration}
                            deviceStatus={this.state.deviceStatus}
                            version={version}
                            editConfigValue={this.editConfiguration}
                        />
                    } 
                />
                <Route 
                    path={'/device/:id/overview'} 
                    render={() => 
                        <DeviceOverviewSubPage 
                            device={this.state.device}
                            deviceConfig={this.state.deviceConfiguration}
                            deviceStatus={this.state.deviceStatus}
                            version={version}
                            editConfigValue={this.editConfiguration}
                        />
                    } 
                />
                <Route 
                    path={'/device/:id/alarms'} 
                    render={() =>
                        <DeviceAlarmsSubPage
                            deviceConfig={this.state.deviceConfiguration}
                            version={version}
                            editConfigValue={this.editConfiguration}
                        />
                    } 
                />
                <Route 
                    path={'/device/:id/settings'} 
                    render={() => 
                        <DeviceSettingsSubPage 
                            deviceConfig={this.state.deviceConfiguration}
                            deviceInfo={this.state.device}
                            editConfigValue={this.editConfiguration}
                        />
                    } 
                />
                <Route 
                    path={'/device/:id/log'} 
                    render={() => <DeviceLogSubPage deviceId={Number(this.props.match.params.id)} />}
                />
            </Switch>
            <Footer/>
        </div>
        );
    }

    private getCurrentData = () => {
        if (this.dataTimerId !== null) {
            window.clearInterval(this.dataTimerId);
            this.dataTimerId = null;
        }

        const device = deviceApi.getDevice(Number(this.props.match.params.id))
        .then((result) => {
            if (result.success) {
                return result.data;
                // Device found
            } else {
                return undefined;
            }
        });

        // Get device configuration
        const devConfig = this.getDeviceConfiguration();
        const devStatus = this.getDeviceStatus();

        Promise.all([device, devConfig, devStatus])
        .then((values) => {
            const dev = values[0];
            const config = values[1];
            const status = values[2];
            if (dev === undefined || config === undefined || status === undefined) {
                this.setState({
                    isFound: false
                });
            } else {
                this.setState({
                    ...this.state,
                    device: dev!,
                    deviceConfiguration: config!,
                    deviceStatus: status!,
                    isFound: true
                });
            }

            if (this.dataTimerId === null) {
                this.dataTimerId = window.setInterval(this.getCurrentData, 5000);
            }
        });
    }

    private getDeviceConfiguration = (): Promise<DeviceConfiguration | undefined> => {
        return deviceConfigApi.getDeviceConfiguration(Number(this.props.match.params.id))
        .then((apiResult) => {
            if (apiResult.success) {
                // Device found
                return apiResult.data;
            } else {
                return undefined;
            }
        });
    }

    private getDeviceStatus = (): Promise<DeviceStatus | undefined> => {
        return deviceStatusApi.getDevice(Number(this.props.match.params.id))
            .then((apiResult) => {
                if (apiResult.success) {
                    // Device found
                    return apiResult.data;
                } else {
                    // Device not found
                    return undefined;
                }
            });
    }

    // This method is passed to dozens of children through PROPs.
    // It posts to the API to update the configuration property, then the api
    // will send an MQTT message to the PLC so the plc gets the new value
    private editConfiguration = (propertyName: string, propertyValue: string) => {
        deviceConfigApi.editDeviceConfiguration(Number(this.props.match.params.id), 
                                                propertyName, 
                                                propertyValue
        ).then((result) => {
            // Update configuration to get new data regardless of success or failure
            this.getDeviceConfiguration();
        });

        // Post a log value of the change
        var value;
        switch (propertyValue) {
            case 'true':
                value = 'On';
                break;
            case 'false':
                value = 'Off';
                break;
            case '1001':
                value = 'Manual';
                break;
            case '1002':
                value = 'Flow Proportional - Center Pivot';
                break;
            case '1003':
                value = 'Flow Proportional - Corner Pivot';
                break;
            case '1004':
                value = 'Flow Proportional - Drip';
                break;
            case '1005':
                value = 'Flow Proportional - Golf';
                break;
            case '1006':
                value = 'uVRF';
                break;
            case '1007':
                value = 'VRI/VRF';
                break;
            case '1008':
                value = 'Speed Sense';
                break;
            case '1009':
                value = 'Flow Proportional - Basic';
                break;
            default:
                value = propertyValue;
        }

        postEvent(
            this.state.device.id,
            {
                eventCode: 'Command',
                details: `${propertyName} set to ${value}`,
                id: 0,
                timestamp: new Date( Date.now()),
                user: 'a',
            });
    }
}

export default withRouter(DeviceDetailsPage);