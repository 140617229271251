import * as React from 'react';
import '../styles/AlarmIndicator.css';
import { AlarmLevel } from 'src/models/Api';

export interface Props {
    severity: string;
}

export class AlarmIndicator extends React.Component<Props> {
    constructor(props: Props) {
        super(props);
    }

    render() {
        return (
            <div
                className={
                    'alarm-indicator ' +
                    (
                        this.props.severity === AlarmLevel.alarm ? 'high' :
                            (this.props.severity === AlarmLevel.warning ? 'medium' : 'low')
                    )
                }
            />
        );
    }
}
