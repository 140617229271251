import * as React from 'react';
import '../styles/Header.css';
import * as identity from '../identity/index';
// import * as Roles from '../identity/Roles';
import { SyntheticEvent } from 'react';
import { history } from '../history';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import NavButton, { NavButtonSize } from './NavButton';

const headerLogo = require('../images/header-logo.png');

interface State {

}

export interface Props extends RouteComponentProps<{}> {
    
}

class Header extends React.Component<Props, State> {
    constructor(props: Props) {
        super(props);

        this.logOut = this.logOut.bind(this);
    }

    render() {
        return (
            <header className="header">
                <div className="container-fluid d-flex row align-items-center">
                    <img className="mr-auto mw-100 mh-100 ml-4" src={headerLogo} />
                    <div className="row d-flex align-items-center">
                        <NavButton
                            // icon={historicalIcon} 
                            // iconSelected={historicalIconSelected}
                            text="SYSTEMS"
                            path="/systems"
                            size={NavButtonSize.large}
                        />
                        <NavButton
                            // icon={historicalIcon} 
                            // iconSelected={historicalIconSelected}
                            text="ALARMS"
                            path="/alarms"
                            size={NavButtonSize.large}
                        />
                        {identity.isAuthenticated() &&
                        <NavButton
                            text="USER"
                            path="/user"
                            size={NavButtonSize.large}
                        />}                                                
                        {identity.isAdminRole() &&
                        <NavButton
                            // icon={historicalIcon} 
                            // iconSelected={historicalIconSelected}
                            text="ADMIN"
                            path="/admin"
                            size={NavButtonSize.large}
                        />}
                        {identity.isAuthenticated() &&
                        <button type="button" className="btn logout-button ml-4" onClick={this.logOut}>
                            {/* <img src={userIcon} /> */}
                            LOGOUT
                        </button>}
                    </div>
                </div>
            </header>
        );
    }

    private logOut(e: SyntheticEvent<HTMLButtonElement>) {
        identity.clearTokens();
        history.push('/log-in');
    }
}

export default withRouter(Header);