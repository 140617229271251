import { ApiResult, DeviceConfiguration, UVRFConfig  } from '../models/Api';
import { httpRequest, Urls } from './Shared';

const DEVICECONFIG_URL = '/DeviceConfiguration';
const UVRFCONFIG_URL = '/uvrf';
const TANKLEVELADD_URL = '/TankLevelAdd';

export const getDeviceConfiguration = (deviceId: number): Promise<ApiResult<DeviceConfiguration>> => {
    return httpRequest(`${Urls.BASE_URL}${DEVICECONFIG_URL}/${deviceId}`,
                       'get'
                        );
};

export const editDeviceConfiguration = (deviceId: number, propertyName: string, propertyValue: string):
    Promise<ApiResult<DeviceConfiguration[]>> => {
    return httpRequest( `${Urls.BASE_URL}${DEVICECONFIG_URL}/${deviceId}`,
                        'put',
                        { 
                            'propertyName': propertyName,
                            'propertyValue': propertyValue
                        });
};

export const getUVRFConfiguration = (deviceId: number): Promise<ApiResult<UVRFConfig>> => {
    return httpRequest(`${Urls.BASE_URL}${UVRFCONFIG_URL}/${deviceId}`,
                       'get'
                        );
};

export const updateUVRFConfiguration = (uvrfConfig: UVRFConfig):
    Promise<ApiResult<DeviceConfiguration[]>> => {
    return httpRequest( `${Urls.BASE_URL}${UVRFCONFIG_URL}`,
                        'post', uvrfConfig);
};

export const updateTankLevel = (deviceId: number, tankLevelAdd: number):
    Promise<ApiResult<DeviceConfiguration[]>> => {
    return httpRequest( `${Urls.BASE_URL}${DEVICECONFIG_URL}/${deviceId}${TANKLEVELADD_URL}`,
                        'post', tankLevelAdd);
};