import * as React from 'react';
import GoogleMapReact from 'google-map-react';
import { DeviceMapData, SystemStatus } from '../models/Api';
import { MapMarker } from './MapMarker';
import '../styles/DevicesMap.css';

export interface Props {
    devices: DeviceMapData[];
}

export interface State {
    latLongZoom: LatLongZoom;
    mapLoadComplete: boolean;
}

interface LatLongZoom {
    latitude: number;
    longitude: number;
    zoom: number;
}

// Default lat/long and zoom level if there is no device specified
const DEFAULT_LATITUDE: number = 39.09;
const DEFAULT_LONGITUDE: number = -95.71;
const DEFAULT_ZOOM: number = 4;

// Constants for zoom calculation
const MAX_ZOOM: number = 21;
const MAP_HEIGHT: number = 300;
const MAP_WIDTH: number = 400;
const WORLD_HEIGHT: number = 256;
const WORLD_WIDTH: number = 256;

export class DevicesMap extends React.Component<Props, State> {
    constructor(props: Props) {
        super(props);

        // Determine center of all devices entered
        this.state = {
            latLongZoom: {
                latitude: DEFAULT_LATITUDE,
                longitude: DEFAULT_LONGITUDE,
                zoom: DEFAULT_ZOOM
            },
            mapLoadComplete: false
        };

        this.mapLoaded = this.mapLoaded.bind(this);
    }

    componentWillReceiveProps(nextProps: Props) {
        if (this.state.mapLoadComplete) {
            this.setState({
                latLongZoom: this.calculateCenter(nextProps.devices)
            });
        }
    }

    render() {
        return (
            <div className="devices-map">
                <div className="map-container">
                    <GoogleMapReact
                        bootstrapURLKeys={{ key: 'AIzaSyC7YxVn65qOy9SzE0-CgjGijkSzvwuxN84'}}
                        defaultCenter={
                            {
                                lat: DEFAULT_LATITUDE,
                                lng: DEFAULT_LONGITUDE
                            }
                        }
                        defaultZoom={DEFAULT_ZOOM}
                        zoom={this.state.latLongZoom.zoom}
                        center={
                            {
                                lat: this.state.latLongZoom.latitude,
                                lng: this.state.latLongZoom.longitude,
                            }
                        }
                        options={{
                            mapTypeId: 'hybrid'
                        }}
                        onGoogleApiLoaded={this.mapLoaded}
                        yesIWantToUseGoogleMapApiInternals={true}
                    >
                        {this.props.devices.map(d =>
                            d.showPivotPoint ?
                            <MapMarker
                                key={d.id}
                                name={d.irrigationSystemName}
                                id={d.id}
                                group={undefined}
                                status={d.systemStatus === null ? SystemStatus.unknown : d.systemStatus}
                                lat={d.pivotPointLatitude === null ? DEFAULT_LATITUDE : d.pivotPointLatitude}
                                lng={d.pivotPointLongitude === null ? DEFAULT_LONGITUDE : d.pivotPointLongitude}
                                showPin={true}
                            />
                            : null
                        )}
                        {this.props.devices.map(d =>
                            d.showLabel ? 
                            <MapMarker
                                key={d.id}
                                name={d.name}
                                id={d.id}
                                group={d.group}
                                status={d.systemStatus === null ? SystemStatus.unknown : d.systemStatus}
                                lat={d.deviceLatitude === null ? DEFAULT_LATITUDE : d.deviceLatitude}
                                lng={d.deviceLongitude === null ? DEFAULT_LONGITUDE : d.deviceLongitude}
                                showPin={!d.showPivotPoint}
                            />
                            : null
                        )}
                        
                    </GoogleMapReact>
                </div>
            </div>
        );
    }

    private mapLoaded() {
        // Map has loaded - set state to true
        this.setState({
            mapLoadComplete: true,
            latLongZoom: this.calculateCenter(this.props.devices)
        });
    }

    private latRad(lat: number): number {
        let sin = Math.sin(lat * Math.PI / 180);
        let radX2 = Math.log((1 + sin) / (1 - sin)) / 2;
        return Math.max(Math.min(radX2, Math.PI), -Math.PI) / 2;
    }

    private calculateCenter(devices: DeviceMapData[]): LatLongZoom {
        let latLongZoom: LatLongZoom = {
            latitude: DEFAULT_LATITUDE,
            longitude: DEFAULT_LONGITUDE,
            zoom: DEFAULT_ZOOM
        };

        // Build new arrays with latitude and longitude of device and pivot point
        let latitude: number[] = [];
        let longitude: number[] = [];

        // Populate arrays with lat/long as necessary
        devices.forEach(d => {
            if (d.deviceLatitude !== null && d.deviceLongitude) {
                // Add lat/long of device to list
                latitude.push(d.deviceLatitude);
                longitude.push(d.deviceLongitude);
            }

            if (d.showPivotPoint && d.pivotPointLatitude !== null && d.pivotPointLongitude !== null) {
                // Add lat/long of pivot point to list
                latitude.push(d.pivotPointLatitude);
                longitude.push(d.pivotPointLongitude);
            }
        });

        // TODO: This calculation has an edge case where longitude wraps from pos to neg - how likely is this?
        if (devices.length > 0) {
            // Calculate min and max latitude and longitude
            let minLat = Math.min(...latitude);
            let maxLat = Math.max(...latitude);
            let minLng = Math.min(...longitude);
            let maxLng = Math.max(...longitude);

            latLongZoom.latitude = (minLat + maxLat) / 2;
            latLongZoom.longitude = (minLng + maxLng) / 2;

            let latFraction = (this.latRad(maxLat) - this.latRad(minLat)) / Math.PI;
            let lngFraction = (maxLng - minLng) / 360;

            let latZoom = Math.floor(Math.log(MAP_HEIGHT / WORLD_HEIGHT / latFraction) / Math.LN2);
            let lngZoom = Math.floor(Math.log(MAP_WIDTH / WORLD_WIDTH / lngFraction) / Math.LN2);

            latLongZoom.zoom = Math.min(latZoom, lngZoom, MAX_ZOOM);
        }

        return latLongZoom;
    }
}