import { ApiResult, Alarm } from '../models/Api';
import { httpRequest, Urls } from './Shared';

const DEVICES_URL = '/Devices';
const ALARMS_URL = '/Alarms';

export const getAlarms = (deviceId: number): Promise<ApiResult<Alarm[]>> => {
    return httpRequest(`${Urls.BASE_URL}${DEVICES_URL}/${deviceId}${ALARMS_URL}`,
                       'get'
                        );
};
