import * as React from 'react';
import '../styles/Footer.css';

const footerLogo = require('../images/footer-logo.png');

export interface Props {

}

function Footer(props: Props) {
    let currentYear = (new Date()).getFullYear();

    return (
        <footer className={'footer d-flex'}>
            <div className="container-fluid">
                <div className="row">
                    <div className="col-md-4">
                        <img className="mr-auto" src={footerLogo} />
                        <br/>
                        <br/>
                        <span className="footer-address">
                            5500 E Highway 34<br/>Yuma CO 80759, USA
                        </span>
                    </div>
                    <div className="col-md-2">
                        <ul className="nav flex-column">
                            <li className="nav-item">
                                <span className="nav-link active">ABOUT</span>
                            </li>
                            <li className="nav-item">
                                <a className="nav-link" href="http://www.agri-inject.com/support/faqs">FAQs</a>
                            </li>
                            <li className="nav-item">
                            <a
                                className="nav-link"
                                href="http://www.agri-inject.com/products-page/reflex-proportional-injection-systems"
                            >
                                    Products
                            </a>
                            </li>
                            <li className="nav-item">
                                <a
                                    className="nav-link"
                                    href="http://www.agri-inject.com/our-customers-say"
                                >
                                    Testimonials
                                </a>
                            </li>
                        </ul>
                    </div>
                    <div className="col-md-2">
                        <ul className="nav flex-column">
                            <li className="nav-item">
                                <span className="nav-link active">CONTACT</span>
                            </li>
                            <li className="nav-item">
                                <a
                                    className="nav-link"
                                    href="http://www.agri-inject.com/support_requests/new"
                                >
                                    Support Request
                                </a>
                            </li>
                            <li className="nav-item">
                                <a className="nav-link" href="http://www.agri-inject.com/dealers">Find a Dealer</a>
                            </li>
                            <li className="nav-item">
                                <a className="nav-link" href="http://www.agri-inject.com/contact">Contact Us</a>
                            </li>
                        </ul>
                    </div>
                    <div className="col-md-2">
                        <ul className="nav flex-column">
                            <li className="nav-item">
                                <span className="nav-link">LEGAL</span>
                            </li>
                            <li className="nav-item">
                                <a
                                    className="nav-link"
                                    href="http://www.agri-inject.com/support/terms-and-conditions-end-user"
                                >
                                    Terms of Use
                                </a>
                            </li>
                            <li className="nav-item">
                                <a className="nav-link" href="http://www.agri-inject.com/support/safety">Safety</a>
                            </li>
                        </ul>
                    </div>
                </div>
                <br/>
                <hr/>
                <br/>
                <div className="row">
                    <div className="col">
                        <span className="footer-copyright">
                            &copy;{currentYear} Agri-Inject, Inc. All rights reserved
                        </span>
                    </div>
                </div>
            </div>
        </footer>
    );
}

export default Footer;