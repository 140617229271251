import * as React from 'react';
import '../styles/StatusIndicator.css';
import { getStatusIcon, SystemStatus } from '../models/Api';

export interface Props {
    status: SystemStatus;
}

export class StatusIndicator extends React.Component<Props> {
    constructor(props: Props) {
        super(props);
    }

    render() {
        return (
            <div className="d-flex align-content-center">
                <img src={getStatusIcon(this.props.status).default} className="status-indicator"/>
            </div>
        );
    }
}
