import * as React from 'react';
import * as Modal from 'react-modal';
import '../styles/Modal.css';
import { CustomerInfo, DeviceManagement, DropdownList } from 'src/models/Api';
import { ConfigurationSelect } from './ConfigurationSelect';
import { getCustomers } from 'src/api/Users';
import { UserAddFieldComponent } from './UserAddFieldComponent';
import { putDeviceManagement } from 'src/api/Devices';
import { ConfigurationToggle } from './ConfigurationToggle';

interface State {
    setpoint: DeviceManagement;
    customerOptions: DropdownList[];
    message: string;
}

export interface Props {
    // addInfo: (responseInfo: AccountInfo) => void;
    currentInfo: DeviceManagement;
    isModalOpen: boolean;
    closeModal: () => void;
}

export class EditDeviceModal extends React.Component<Props, State> {

    constructor(props: Props) {
        super(props);
        
        this.state = {
            setpoint: this.getDefaultDeviceInfo(),
            customerOptions: [],
            message: ''
        };
    }

    getDropDownOptions = () => {
        // Variables to promises
        // Wait till all promises are fullfiled to set the state once
        let cPromise: Promise<CustomerInfo[]>;
        cPromise = getCustomers();

        Promise.resolve(cPromise).then(value =>
            this.setDropDownOptions(value)
        );
    }
    
      // Given an array of arrays, set the values as the drop down options:
      setDropDownOptions = (
        value: CustomerInfo[]
      ) => {    
        let cOptions = value.map(c => {
          return { name: c.name, value: c.id };
        });
    
        // Add an 'ALL' selection to the beginning of the options
        // cOptions.unshift({ name: "All", value: 0 });
    
        this.setState({
          customerOptions: cOptions,
        });
      }

      componentWillReceiveProps(nextProps: Props) {
        if (!nextProps.isModalOpen || (nextProps.currentInfo.deviceId !== 0)) {
            this.setState({
                setpoint: JSON.parse(JSON.stringify(nextProps.currentInfo))
            });
            this.getDropDownOptions();
        }
      }

    componentDidMount() {
        this.getDropDownOptions();
    }

    render() {
        return ( 
            <div className="Modal">
                <Modal 
                    ariaHideApp={false}
                    isOpen={this.props.isModalOpen}
                    shouldCloseOnEsc={true}
                    shouldCloseOnOverlayClick={true}
                    onRequestClose={this.props.closeModal}
                    className={{
                        base: 'modal-content',
                        afterOpen: 'modal-content_after-open',
                        beforeClose: 'modal-content_before-close'
                    }}
                    overlayClassName={{
                        base: 'modal-overlay',
                        afterOpen: 'modal-overlay_after-open',
                        beforeClose: 'modal-overlay_before-close'
                    }}
                >
                    <div className="container add-user-modal">
                        <label className="label-large col-form-label">
                            EDIT DEVICE
                        </label>
                        <div className="row form-group"/>
                        <UserAddFieldComponent 
                            handleChange={this.onInputChange}
                            value={this.state.setpoint.name}
                            fieldName="NAME"
                            APIName="name"
                        />
                        <div className="row flex-container list-divider">
                            <div className="col-4 text-medium-blue">
                                SERIAL NUMBER
                            </div>
                            <div className="col">
                                <div className="row justify-content-end align-items-center">
                                    <div className="col align-items-center">
                                        {this.state.setpoint.hubDeviceId}
                                    </div>
                                </div>
                            </div>
                        </div>
                        <UserAddFieldComponent 
                            handleChange={this.onInputChange}
                            value={this.state.setpoint.group}
                            fieldName="GROUP"
                            APIName="group"
                        />
                        <div className="row flex-container list-divider">
                            <div className="col-4 text-medium-blue">
                                SELECT CUSTOMER
                            </div>
                            <div className="col">
                                <div className="row justify-content-end align-items-center">
                                    <div className="col align-items-center">
                                    <ConfigurationSelect
                                        name=" "
                                        value={this.state.setpoint.customerId}
                                        options={this.state.customerOptions}
                                        editValue={this.handleCustomerEdit}
                                    />
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="row flex-container list-divider">
                            <div className="col-4 text-medium-blue">
                                DISABLE DEVICE
                            </div>
                            <div className="col">
                                <div className="row justify-content-end align-items-center">
                                    <div className="col align-items-center">
                                    <ConfigurationToggle
                                        checked={this.state.setpoint.isDisabled}
                                        name="isDisabled"
                                        editValue={this.toggleDevice}
                                        onLabel="DISABLED"
                                        offLabel="ENABLED"
                                        disabled={false}
                                    />
                                    </div>
                                </div>
                            </div>
                        </div>
                        {this.state.setpoint.versionMajor >= 3 &&
                        <div className="row flex-container list-divider">
                            <div className="col-4 text-medium-blue">
                                SELECT LICENSES
                            </div>
                            <div className="col">
                                {this.state.setpoint.licenses.length > 0 ?
                                 this.state.setpoint.licenses.map(licenses => 
                                    <div className="row justify-content-end align-items-center" key={licenses.id}>
                                        <div className="col-md-5 align-items-center text-medium-blue">
                                            {licenses.name}
                                        </div>
                                        <div className="col align-items-center">
                                            <ConfigurationToggle
                                                checked={!licenses.isDisabled}
                                                name={licenses.id.toString()}
                                                editValue={this.toggleLicense}
                                                onLabel="ENABLED"
                                                offLabel="DISABLED"
                                                disabled={false}
                                            />
                                        </div>
                                    </div>
                                ) : 'No additional licenses' }
                            </div>
                        </div>}

                        <div className="row mt-2 form-group d-flex align-items-center justify-content-center">
                            <div className="col d-flex justify-content-center text-error">
                                {this.state.message}
                            </div>
                        </div>
                        <div className="row form-group mt-2">
                            <div className="col">
                                <button
                                    className="btn btn-block button-standard confirm-button"
                                    tabIndex={2}
                                    onClick={this.confirmButtonPressed}
                                >
                                    CONFIRM
                                </button>
                            </div>
                            <div className="col">
                                <button
                                    className="btn btn-block button-standard cancel-button"
                                    tabIndex={3}
                                    onClick={this.cancelButtonPressed}
                                >
                                    CANCEL
                                </button>
                            </div>
                        </div>
                    </div>
                </Modal>
            </div>
        );
    }

    private onInputChange = (e: React.SyntheticEvent<HTMLInputElement>) => { 
        let newSetpoint = {...this.state.setpoint, [e.currentTarget.name]: e.currentTarget.value};
        this.setState({
            setpoint: newSetpoint
        });
    }

    private handleCustomerEdit = (name: string, value: string) => {
        let newSetpoint = this.state.setpoint;
        newSetpoint.customerId = parseInt(value, 10);
        newSetpoint.customerName = name;
        this.setState({setpoint: newSetpoint});
    }

    private confirmButtonPressed = () => {
        putDeviceManagement(this.state.setpoint)
        .then(result => {
            if (result.success) {
                this.setState({
                    setpoint: this.getDefaultDeviceInfo(),
                    message: ''
                });
                // Then close the modal
                this.props.closeModal();
            } else {
                this.setState({
                    message: 'Error Editing Device'
                });
            }
        })
        .catch(c => {
            this.setState({
                message: 'Failed to update the device'
            });
        });
    }

    private toggleLicense = (name: string, value: string) => {
        if (this.state.setpoint === undefined) {
            // No object passed in - exit
            return;
        }

        let setpoint = this.state.setpoint;
        let license = setpoint.licenses.find(l => l.id == parseInt(name, 10));
        if (license) {
            license.isDisabled = value === 'false';
            this.setState({
                setpoint: setpoint
            });
        }
    }

    private toggleDevice = (name: string, value: string) => {
        if (this.state.setpoint === undefined) {
            // No object passed in - exit
            return;
        }

        this.setState(prevState => ({
            setpoint: {
                ...prevState.setpoint,
                [name]: value === 'true'
            }
        }));
    }

    private getDefaultDeviceInfo = (): DeviceManagement => {
        return {
          name: 'loading...',
          deviceId: 0,
          hubDeviceId: '',
          group: '',
          version: '',
          versionMajor: 0,
          versionMinor: 0,
          versionRev: 0,
          customerId: 0,
          customerName: '',
          licenses: [],
          isDisabled: false,
      };
    }

    private cancelButtonPressed = (): void => {
        this.setState({
            message: ''
        });
        this.props.closeModal();
    }
}
