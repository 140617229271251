import { ApiResult, AccountInfo, PasswordResponse, CustomerInfo  } from '../models/Api';
import { httpRequest, Urls } from './Shared';

const USER_URL = '/Users';
const CURRENT_USER_URL = '/Users/current';
const CUSTOMERS_URL = '/Customers';

// Get Account Info for currently signed in user
export const getUser = (): Promise<ApiResult<AccountInfo>> => {
    return httpRequest(`${Urls.BASE_URL}${CURRENT_USER_URL}`,
                       'get'
                        );
};

export const getUsers = (): Promise<ApiResult<AccountInfo[]>> => {
    return httpRequest(`${Urls.BASE_URL}${USER_URL}`,
                       'get'
                        );
};

export const putUser = (account: AccountInfo): Promise<ApiResult<PasswordResponse>> => {
    return httpRequest(`${Urls.BASE_URL}${USER_URL}/${account.id.toString()}`,
                       'put',
                       {
                            'email': account.email,
                            'firstName': account.firstName,
                            'lastName': account.lastName,
                            'phoneNumber': account.phoneNumber,
                            'licenseNumber': account.licenseNumber,
                            'licenseEffectiveDate': account.licenseEffectiveDate,
                            'customerId': account.customerId,
                            'isDeactivated': account.isDeactivated,
                            'roles': account.roles,
                            'password': account.password
                       });
};

export const postUser = (account: AccountInfo): Promise<ApiResult<PasswordResponse>> => {
    return httpRequest(`${Urls.BASE_URL}${USER_URL}`,
                       'post',
                       {
                            'email': account.email,
                            'firstName': account.firstName,
                            'lastName': account.lastName,
                            'phoneNumber': account.phoneNumber,
                            'licenseNumber': account.licenseNumber,
                            'licenseEffectiveDate': account.licenseEffectiveDate,
                            'customerId': account.customerId,
                            'isDeactivated': account.isDeactivated,
                            'roles': account.roles,
                            'password': account.password
                       });
};

export const deleteUser = (account: AccountInfo): Promise<ApiResult<PasswordResponse>> => {
    return httpRequest(`${Urls.BASE_URL}${USER_URL}/${account.id.toString()}`,
                       'delete',
                       {
                            'email': account.email,
                            'firstName': account.firstName,
                            'lastName': account.lastName,
                            'phoneNumber': account.phoneNumber,
                            'licenseNumber': account.licenseNumber,
                            'licenseEffectiveDate': account.licenseEffectiveDate,
                            'customerId': account.customerId,
                            'isDeactivated': account.isDeactivated,
                            'roles': account.roles,
                            'password': account.password
                       });
};

export const getCustomers = (): Promise<CustomerInfo[]> => {
    return httpRequest(`${Urls.BASE_URL}${CUSTOMERS_URL}`,
                       'get'
                        )
                        .then(result => {
                            if (result.success) {
                              return <CustomerInfo[]> result.data;
                            } else {
                              return [];
                            }
                          })
                          .catch(e => { throw (e); });
};

export const addNewCustomerApi = (name: string): Promise<boolean> => {
    return httpRequest( `${Urls.BASE_URL}${CUSTOMERS_URL}`, 'post',
                        {
                          id: 0,
                          name: name
                        }
    ).then(res => {
      return res.success;
    }).catch(err => {
      alert(err); 
      throw(err);  
    });
  };
  
export const updateCustomerApi = (data: CustomerInfo): Promise<boolean> => {
    return httpRequest(`${Urls.BASE_URL}${CUSTOMERS_URL}/${data.id}`, 'put', data)
    .then(res => {
      return res.success;
    }).catch(err => {
      alert(err); 
      throw(err);  
    });
  };

export const deleteCustomerApi = (data: CustomerInfo): Promise<boolean> => {
    return httpRequest(`${Urls.BASE_URL}${CUSTOMERS_URL}/${data.id}`, 'delete', data)
    .then(res => {
      return res.success;
    }).catch(err => {
      alert(err); 
      throw(err);  
    });
  };