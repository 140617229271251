import * as React from 'react';
import { withRouter, RouteComponentProps, Route, Switch } from 'react-router-dom';
import Footer from '../components/Footer';
import Header from '../components/Header';
import '../styles/Admin.css';
import NavButton from '../components/NavButton';
import { UserAdminPage } from './Admin/UserAdminPage';
import { CustomersAdminPage } from './Admin/CustomersAdminPage';
import { DeviceAdminPage } from './Admin/DeviceAdminPage';

interface State {
}

export interface Props extends RouteComponentProps<{id: string}> {

}

class AdminPage extends React.Component<Props, State> {
    constructor(props: Props) {
        super(props);

        this.state = {
        };
    }

    render() {
        return (
            <div className="flex-root">
            <Header/>
            <div className="admin-header">
                <div className="col">
                    <div className="row">
                        <label className="content text-medium-blue label-large">
                            ADMIN
                        </label>
                    </div>
                </div>

                <div className="row">
                    <NavButton
                        text="USERS"
                        path="/admin/users"
                        className="ml-0"
                    />
                    <NavButton
                        text="CUSTOMERS"
                        path="/admin/customers"
                        className="ml-0"
                    />
                    <NavButton
                        text="DEVICES"
                        path="/admin/devices"
                        className="ml-0"
                    />
                </div>
            </div>
            
            <Switch>
                <Route 
                    path={'/admin'} 
                    exact={true} 
                    render={() => 
                        <UserAdminPage/>
                    } 
                />
                <Route 
                    path={'/admin/users'} 
                    render={() => 
                        <UserAdminPage/>
                    }  
                />
                <Route 
                    path={'/admin/customers'} 
                    render={() => 
                        <CustomersAdminPage/>
                    }  
                />
                <Route 
                    path={'/admin/devices'} 
                    render={() => 
                        <DeviceAdminPage/>
                    }  
                />
            </Switch>
            <Footer/>
            
        </div>
        );
        
    }
}

export default withRouter(AdminPage);