import * as React from 'react';
import * as Victory from 'victory';
import '../styles/DataGraph.css';
import { HistoricalData } from 'src/models/Api';

// Create constants for graph axis scaling
const PUMP_ACTUAL_INDEX = 0;
const PUMP_TARGET_INDEX = 0;
const WATER_FLOW_INDEX = 1;
const TANK_LEVEL_INDEX = 2;
const PRESSURE_INDEX = 3;

const AXIS_OFFSET_BASELINE = 40;
const AXIS_OFFSET = 30;

const tickValuesArray = [0, 0.2, 0.4, 0.6, 0.8, 1, 1.2];
const tickScaleFactor = 5;

interface State {
    maxValues: number[];
    numAxes: number;
}

export interface Props {
    data: HistoricalData[];
    showInjectionPumpTarget: boolean;
    showInjectionPumpActual: boolean;
    showWaterFlow: boolean;
    showTankLevel: boolean;
    showPressure: boolean;
    injectionPumpUnits: string;
    waterFlowUnits: string;
    tankLevelUnits: string;
    pressureUnits: string;
    startDate: Date;
    endDate: Date;
}

let days = [
    'Sunday',
    'Monday',
    'Tuesday',
    'Wednesday',
    'Thursday',
    'Friday',
    'Saturday'
];

export class DataGraph extends React.Component<Props, State> {
    constructor(props: Props) {
        super(props);
        
        this.state = {
            maxValues: this.computeMaxima(props.data),
            numAxes: this.computeNumAxes(props)
        };

        this.getTickDateString = this.getTickDateString.bind(this);
        this.getLabelDateString = this.getLabelDateString.bind(this);
        this.updateGraphData = this.updateGraphData.bind(this);
        this.computeMaxima = this.computeMaxima.bind(this);
        this.computeNumAxes = this.computeNumAxes.bind(this);
    }

    componentWillReceiveProps(nextProps: Props) {
        this.setState({
            maxValues: this.computeMaxima(nextProps.data),
            numAxes: this.computeNumAxes(nextProps)
        });
    }

    render() {
        
        return (
            <div className="data-graph d-flex">
                {this.props.data.length <= 0 ? (
                    <div className="no-data d-flex justify-content-center w-100">
                        <div className="label-large align-self-center text-center">
                            No data to display
                        </div>
                    </div>
                ) : (
                    <Victory.VictoryChart
                        padding={{top: 25, bottom: 35,
                        left: AXIS_OFFSET_BASELINE + 
                            (((this.props.showInjectionPumpActual || this.props.showInjectionPumpTarget) 
                            && this.props.showWaterFlow) 
                            ? AXIS_OFFSET : 0),
                        right: AXIS_OFFSET_BASELINE + 
                            ((this.props.showTankLevel 
                            && this.props.showPressure) 
                            ? AXIS_OFFSET : 0)
                        }}
                        // domain={{y: [Math.min(0, Math.min(...this.state.data.map(d => d.y))),
                        //              Math.max(0.01, Math.max(...this.state.data.map(d => d.y)))]}}
                        domainPadding={{y: 10}}
                        scale={{x: 'time', y: 'linear'}}
                        width={400}
                        height={200}
                        containerComponent={
                            <Victory.VictoryVoronoiContainer
                                radius={10}
                                labels={
                                    (d: any) => d.drawLabel ? 
                                                    d.y.toFixed(3) + ' ' + d.units + 
                                                    '\n' + 
                                                    this.getLabelDateString(d.x)
                                                    : 
                                                    ""
                                }
                                labelComponent={
                                    <Victory.VictoryTooltip
                                        cornerRadius={2}
                                        dy={5}
                                        style={{
                                            fill: '#ffffff',
                                            fontSize: 6,
                                            padding: 2
                                        }}
                                        flyoutStyle={{
                                            fill: '#2b607a',
                                            stroke: '#2b607a'
                                        }}
                                    />
                                }
                            />
                        }
                    >
                        {this.props.showInjectionPumpTarget &&
                            <Victory.VictoryGroup>
                                <Victory.VictoryLine
                                    data={this.props.data.map( 
                                        d => ({x: new Date(d.timestamp + 'Z'), 
                                        y: d.injectionPumpTarget}))
                                    }
                                    style={{data: {stroke: '#00c270', strokeWidth: .75}}}
                                    y={(d) => d.y / this.state.maxValues[PUMP_ACTUAL_INDEX]}
                                    interpolation="stepAfter"
                                />
                                <Victory.VictoryScatter
                                    // Draw data points to visualize where data was present vs. interpolated
                                    data={this.props.data.map( 
                                        d => ({x: new Date(d.timestamp + 'Z'), 
                                        y: d.injectionPumpTarget,
                                        drawLabel: true,
                                        units: this.props.injectionPumpUnits}))
                                    }
                                    y={(d) => d.y / this.state.maxValues[PUMP_ACTUAL_INDEX]}
                                    style={{data: {fill: '#00c270'}}}
                                    size={1}
                                />
                                <Victory.VictoryScatter 
                                    // Set drawLabel to true for the scatter series so that the data labels show up
                                    data={this.props.data.map( d =>
                                        ({
                                            x: new Date(d.timestamp + 'Z'),
                                            y: d.injectionPumpTarget,
                                            drawLabel: true,
                                            units: this.props.injectionPumpUnits
                                        })
                                    )}
                                    style={{
                                        data: {fill: '#ffffff', stroke: '#00c270', strokeWidth: 1}
                                    }}
                                    size={(active: any) => active ? 2.5 : 0}
                                    y={(d) => d.y / this.state.maxValues[PUMP_ACTUAL_INDEX]}
                                />
                            </Victory.VictoryGroup>
                        }

                        {this.props.showInjectionPumpActual &&
                            <Victory.VictoryGroup>
                                <Victory.VictoryLine
                                    data={this.props.data.map( 
                                        d => ({x: new Date(d.timestamp + 'Z'), 
                                        y: d.injectionPumpActual}))
                                    }
                                    style={{data: {stroke: '#42b2e2', strokeWidth: .75}}}
                                    y={(d) => d.y / this.state.maxValues[PUMP_TARGET_INDEX]}
                                    interpolation="stepAfter"
                                />
                                <Victory.VictoryScatter
                                    // Draw data points to visualize where data was present vs. interpolated
                                    data={this.props.data.map( 
                                        d => ({x: new Date(d.timestamp + 'Z'), 
                                        y: d.injectionPumpActual,
                                        drawLabel: true,
                                        units: this.props.injectionPumpUnits}))
                                    }
                                    y={(d) => d.y / this.state.maxValues[PUMP_TARGET_INDEX]}
                                    style={{data: {fill: '#42b2e2'}}}
                                    size={1}
                                />
                                <Victory.VictoryScatter 
                                    // Set drawLabel to true for the scatter series so that the data labels show up
                                    data={this.props.data.map( d =>
                                        ({
                                            x: new Date(d.timestamp + 'Z'),
                                            y: d.injectionPumpActual,
                                            drawLabel: true,
                                            units: this.props.injectionPumpUnits
                                        })
                                    )}
                                    style={{
                                        data: {fill: '#ffffff', stroke: '#42b2e2', strokeWidth: 1}
                                    }}
                                    size={(active: any) => active ? 2.5 : 0}
                                    y={(d) => d.y / this.state.maxValues[PUMP_TARGET_INDEX]}
                                />
                            </Victory.VictoryGroup>
                        }
                        
                        {this.props.showWaterFlow &&
                            <Victory.VictoryGroup>
                                <Victory.VictoryLine
                                    data={this.props.data.map( 
                                        d => ({x: new Date(d.timestamp + 'Z'), y: d.waterFlow}))
                                    }
                                    style={{data: {stroke: '#f15a24', strokeWidth: .75}}}
                                    y={(d) => d.y / this.state.maxValues[WATER_FLOW_INDEX]}
                                    interpolation="stepAfter"
                                />
                                <Victory.VictoryScatter
                                    data={this.props.data.map( 
                                        d => ({x: new Date(d.timestamp + 'Z'), 
                                        y: d.waterFlow,
                                        drawLabel: true,
                                        units: this.props.waterFlowUnits}))
                                    }
                                    y={(d) => d.y / this.state.maxValues[WATER_FLOW_INDEX]}
                                    style={{data: {fill: '#f15a24'}}}
                                    size={1}
                                />
                                <Victory.VictoryScatter 
                                    // Set drawLabel to true for the scatter series so that the data labels show up
                                    data={this.props.data.map( d =>
                                        ({
                                            x: new Date(d.timestamp + 'Z'),
                                            y: d.waterFlow,
                                            drawLabel: true,
                                            units: this.props.waterFlowUnits
                                        })
                                    )}
                                    style={{
                                        data: {fill: '#ffffff', stroke: '#f15a24', strokeWidth: 1}
                                    }}
                                    size={(active: any) => active ? 2.5 : 0}
                                    y={(d) => d.y / this.state.maxValues[WATER_FLOW_INDEX]}
                                />
                            </Victory.VictoryGroup>
                        }

                        {this.props.showTankLevel &&
                            <Victory.VictoryGroup>
                                <Victory.VictoryLine
                                    data={this.props.data.map( 
                                        d => ({x: new Date(d.timestamp + 'Z'), y: d.tankLevel}))
                                    }
                                    style={{data: {stroke: '#e671ff', strokeWidth: .75}}}
                                    y={(d) => d.y / this.state.maxValues[TANK_LEVEL_INDEX]}
                                    interpolation="stepAfter"
                                />
                                <Victory.VictoryScatter
                                    // Draw data points to visualize where data was present vs. interpolated
                                    data={this.props.data.map( 
                                        d => ({x: new Date(d.timestamp + 'Z'), 
                                        y: d.tankLevel,
                                        drawLabel: true,
                                        units: this.props.tankLevelUnits}))
                                    }
                                    y={(d) => d.y / this.state.maxValues[TANK_LEVEL_INDEX]}
                                    style={{data: {fill: '#e671ff'}}}
                                    size={1}
                                />
                                <Victory.VictoryScatter 
                                    // Set drawLabel to true for the scatter series so that the data labels show up
                                    data={this.props.data.map( d =>
                                        ({
                                            x: new Date(d.timestamp + 'Z'),
                                            y: d.tankLevel,
                                            drawLabel: true,
                                            units: this.props.tankLevelUnits
                                        })
                                    )}
                                    style={{
                                        data: {fill: '#ffffff', stroke: '#e671ff', strokeWidth: 1}
                                    }}
                                    size={(active: any) => active ? 2.5 : 0}
                                    y={(d) => d.y / this.state.maxValues[TANK_LEVEL_INDEX]}
                                />
                            </Victory.VictoryGroup>
                        }

                        {this.props.showPressure &&
                            <Victory.VictoryGroup>
                                <Victory.VictoryLine
                                    data={this.props.data.map( 
                                        d => ({x: new Date(d.timestamp + 'Z'), y: d.pressure}))
                                    }
                                    style={{data: {stroke: '#9758c7', strokeWidth: .75}}}
                                    y={(d) => d.y / this.state.maxValues[PRESSURE_INDEX]}
                                    interpolation="stepAfter"
                                />
                                <Victory.VictoryScatter
                                    // Draw data points to visualize where data was present vs. interpolated
                                    data={this.props.data.map( 
                                        d => ({x: new Date(d.timestamp + 'Z'), 
                                        y: d.pressure,
                                        drawLabel: true,
                                        units: this.props.pressureUnits}))
                                    }
                                    y={(d) => d.y / this.state.maxValues[PRESSURE_INDEX]}
                                    style={{data: {fill: '#9758c7'}}}
                                    size={1}
                                />
                                <Victory.VictoryScatter 
                                    // Set drawLabel to true for the scatter series so that the data labels show up
                                    data={this.props.data.map( d =>
                                        ({
                                            x: new Date(d.timestamp + 'Z'),
                                            y: d.pressure,
                                            drawLabel: true,
                                            units: this.props.pressureUnits
                                        })
                                    )}
                                    style={{
                                        data: {fill: '#ffffff', stroke: '#9758c7', strokeWidth: 1}
                                    }}
                                    size={(active: any) => active ? 2.5 : 0}
                                    y={(d) => d.y / this.state.maxValues[PRESSURE_INDEX]}
                                />
                            </Victory.VictoryGroup>
                        }

                        <Victory.VictoryAxis
                            label={'Date/Time'}
                            style={{
                                axis: {stroke: '#a0a0a0'},
                                axisLabel: {fill: '#a0a0a0', fontSize: 8},
                                ticks: {stroke: '#a0a0a0', size: 3} as any,
                                tickLabels: {fill: '#a0a0a0', fontSize: 6}
                            }}
                            scale={'time'}
                            domain={[this.props.startDate, this.props.endDate]}
                            tickFormat={(t, index, ticks) => this.getTickDateString(t, ticks)}
                            tickLabelComponent={<Victory.VictoryLabel dy={-8}/>}
                            axisLabelComponent={<Victory.VictoryLabel dy={-4}/>}
                        />

                        {(this.props.showInjectionPumpActual || 
                          this.props.showInjectionPumpTarget) &&
                        <Victory.VictoryAxis
                            dependentAxis={true}
                            label={'Injection Pump (' + this.props.injectionPumpUnits + ')'}
                            style={{
                                axis: {stroke: (this.props.showInjectionPumpActual ? '#42b2e2' : '#00c270')},
                                axisLabel: {
                                    fill: this.props.showInjectionPumpActual || this.props.showInjectionPumpTarget ? 
                                        (this.props.showInjectionPumpActual ? '#42b2e2' : '#00c270') : '#a0a0a0',
                                    fontSize: 8
                                },
                                ticks: {stroke: (this.props.showInjectionPumpActual ? '#42b2e2' : '#00c270'), size: 3} as any,
                                tickLabels: {fill: 
                                    (this.props.showInjectionPumpActual ? '#42b2e2' : '#00c270'), 
                                    fontSize: 6}
                            }}
                            scale={'linear'}
                            tickLabelComponent={<Victory.VictoryLabel dx={8}/>}
                            axisLabelComponent={<Victory.VictoryLabel dy={7}/>}
                            tickFormat={(t) => (t * this.state.maxValues[PUMP_TARGET_INDEX])}
                            tickValues={tickValuesArray}
                            orientation={'left'}
                            offsetX={AXIS_OFFSET_BASELINE}
                        />}
                        {(this.props.showWaterFlow) &&
                        <Victory.VictoryAxis
                            dependentAxis={true}
                            label={'Irrigation Water Flow (' + this.props.waterFlowUnits + ')'}
                            style={{
                                axis: {stroke: '#f15a24'},
                                axisLabel: {fill: this.state.numAxes > 1 ? '#f15a24' : '#a0a0a0', fontSize: 8},
                                ticks: {stroke: '#f15a24', size: 3}as any,
                                tickLabels: {fill: '#f15a24', fontSize: 6}
                            }}
                            scale={'linear'}
                            tickLabelComponent={<Victory.VictoryLabel dx={8}/>}
                            axisLabelComponent={<Victory.VictoryLabel dy={7}/>}
                            tickFormat={(t) => (t * this.state.maxValues[WATER_FLOW_INDEX])}
                            tickValues={tickValuesArray}
                            orientation={'left'}
                            offsetX={AXIS_OFFSET_BASELINE + 
                                (AXIS_OFFSET * 
                                ((this.props.showInjectionPumpActual || this.props.showInjectionPumpTarget) ? 1 : 0))
                            }
                        />}
                        {(this.props.showTankLevel) &&
                        <Victory.VictoryAxis
                            dependentAxis={true}
                            label={'Tank Level (' + this.props.tankLevelUnits + ')'}
                            style={{
                                axis: {stroke: '#e671ff'},
                                axisLabel: {fill: this.state.numAxes > 1 ? '#e671ff' : '#a0a0a0', fontSize: 8},
                                ticks: {stroke: '#e671ff', size: 3}as any,
                                tickLabels: {fill: '#e671ff', fontSize: 6}
                            }}
                            scale={'linear'}
                            tickLabelComponent={
                                <Victory.VictoryLabel 
                                    dx={((this.props.showInjectionPumpActual || this.props.showInjectionPumpTarget) ||
                                        this.props.showWaterFlow) ? -8 : 8}
                                />
                            }
                            axisLabelComponent={
                                <Victory.VictoryLabel 
                                    dy={((this.props.showInjectionPumpActual || this.props.showInjectionPumpTarget) ||
                                        this.props.showWaterFlow) ? -7 : 7}
                                />
                            }
                            tickFormat={(t) => (t * this.state.maxValues[TANK_LEVEL_INDEX])}
                            tickValues={tickValuesArray}
                            orientation={
                                ((this.props.showInjectionPumpActual || this.props.showInjectionPumpTarget) ||
                                this.props.showWaterFlow) ? 'right' : 'left'
                            }
                            offsetX={AXIS_OFFSET_BASELINE}
                        />}
                        {(this.props.showPressure) &&
                        <Victory.VictoryAxis
                            dependentAxis={true}
                            label={'Pressure (' + this.props.pressureUnits + ')'}
                            style={{
                                axis: {stroke: '#9758c7'},
                                axisLabel: {fill: this.state.numAxes > 1 ? '#9758c7' : '#a0a0a0', fontSize: 8},
                                ticks: {stroke: '#9758c7', size: 3} as any,
                                tickLabels: {fill: '#9758c7', fontSize: 6}
                            }}
                            scale={'linear'}
                            tickLabelComponent={<Victory.VictoryLabel dx={this.state.numAxes > 1 ? -8 : 8}/>}
                            axisLabelComponent={<Victory.VictoryLabel dy={this.state.numAxes > 1 ? -7 : 7}/>}
                            tickFormat={(t) => (t * this.state.maxValues[PRESSURE_INDEX])}
                            tickValues={tickValuesArray}
                            orientation={this.state.numAxes > 1 ? 'right' : 'left'}
                            offsetX={AXIS_OFFSET_BASELINE + 
                                (((this.props.showInjectionPumpActual || this.props.showInjectionPumpTarget ||
                                this.props.showWaterFlow) && this.props.showTankLevel) ? AXIS_OFFSET : 0)
                            }
                        />}
                    </Victory.VictoryChart>
                )}
            </div>
        );
    }

    private getTickDateString(timestamp: any, ticks: any[]) {
        // Find the difference between two tick marks
        var tickDifference = ticks[1] - ticks[0];

        var date = new Date(timestamp);
        var hours = date.getHours();
        var minutes = date.getMinutes();
        var day = date.getDate();

        // Return one of two date formats. Longer term data on left, short term on right
        // mm/dd/yyyy       hh:mm AM/PM
        //    day               day
        return ( tickDifference > 50000000 ?   // 50000000 milliseconds, about 14 hours
            (date.getMonth() + 1) +
            '/' + 
            (day < 10 ? '0' + day : day) + 
            '/' + 
            date.getFullYear() + 
            '\n' + 
            days[date.getDay()]
            :
            (hours > 12 ? hours - 12 : (hours === 0 ? hours + 12 : hours)) + 
            ':' + 
            (minutes < 10 ? '0' + minutes : minutes) + 
            ' ' + 
            (hours > 11 ? 'PM' : 'AM') +
            '\n' +
            days[date.getDay()]
        );
    }

    private getLabelDateString(timestamp: any) {
        // Convert millisecond timestamp to friendly date string
        var date = new Date(timestamp);
        var hours = date.getHours();
        var minutes = date.getMinutes();
        var seconds = date.getSeconds();
        var day = date.getDate();

        return (
            (hours > 12 ? hours - 12 : (hours === 0 ? hours + 12 : hours)) + 
            ':' + 
            (minutes < 10 ? '0' + minutes : minutes) + 
            ':' + 
            (seconds < 10 ? '0' + seconds : seconds) + 
            ' ' + 
            (hours > 11 ? 'PM' : 'AM') +
            ' ' +
            (date.getMonth() + 1) +
            '/' + 
            (day < 10 ? '0' + day : day) + 
            '/' + 
            date.getFullYear()
        );
    }

    private updateGraphData() {
        // Set the state appropriately
        // this.setState({
        //     data: data.map((d, index) => { return { ...d, drawLabel: false }; })
        // });
        this.setState({
            maxValues: this.computeMaxima(this.props.data)
        });
    }

    private computeMaxima(data: HistoricalData[]): number[] {
        let maxima: number[] = [];

        let targetMax = this.props.showInjectionPumpTarget ? 
                        Math.max(...data.map(d => Math.abs(d.injectionPumpTarget)), 1) : 0;
        let actualMax = this.props.showInjectionPumpActual ? 
                        Math.max(...data.map(d => Math.abs(d.injectionPumpActual)), 1) : 0;

        maxima.push(Math.max(  
            tickScaleFactor * Math.round(Math.max(targetMax, actualMax) / tickScaleFactor),
            1));
        maxima.push(Math.max( 
            tickScaleFactor * Math.round(Math.max(...data.map( d => Math.abs(d.waterFlow))) / tickScaleFactor),
            1));
        maxima.push(Math.max( 
            tickScaleFactor * Math.round(Math.max(...data.map( d => Math.abs(d.tankLevel))) / tickScaleFactor),
            1));
        maxima.push(Math.max( 
            tickScaleFactor * Math.round(Math.max(...data.map( d => Math.abs(d.pressure))) / tickScaleFactor),
            1));
        
        return maxima;
    }

    private computeNumAxes(props: Props): number {
        let numAxes = 0;

        if (props.showInjectionPumpActual || props.showInjectionPumpTarget) {
            numAxes++;
        }

        if (props.showWaterFlow) {
            numAxes++;
        }

        if (props.showTankLevel) {
            numAxes++;
        }

        if (props.showPressure) {
            numAxes++;
        }

        return numAxes;
    }
}