import * as React from 'react';
import { Total, DeviceStatus } from '../models/Api';
import { resetTotal } from '../api/ResetTotal';
import '../styles/DeviceOverview.css';
import { postEvent } from 'src/api/Events';
// import { addCommand } from '../api/Commands';

interface State {
    
}

export interface Props {
    deviceId: number;
    deviceStatus: DeviceStatus;
    isDisabled: boolean | null;
}

export class OverviewTotals extends React.Component<Props, State> {

    constructor(props: Props) {
        super(props);

        this.FormatTotalRow = this.FormatTotalRow.bind(this);
    }

    render() {
        return (
            <div className="component-standard w-100">
            
                {/* Header */}
                <div className="row">
                    <div className="col-10 label-large text-dark-blue">
                        TOTALS
                    </div>
                </div>

                <div className="row content flex-container list-divider">
                    <div className="col text-medium-blue">
                        CHEMICAL
                    </div>
                </div>
                {this.FormatTotalRow(this.props.deviceStatus.chemicalTotal1, 'ChemTotal1', 1)}
                {this.FormatTotalRow(this.props.deviceStatus.chemicalTotal2, 'ChemTotal2', 1)}

                <div className="row content flex-container list-divider">
                    <div className="col text-medium-blue">
                        IRRIGATION WATER
                    </div>
                </div>
                {this.FormatTotalRow(this.props.deviceStatus.waterTotal1, 'WaterTotal1', 0)}
                {this.FormatTotalRow(this.props.deviceStatus.waterTotal2, 'WaterTotal2', 0)}

                <div className="row content flex-container list-divider">
                    <div className="col text-medium-blue">
                        PUMP HOURS
                    </div>
                </div>
                {this.FormatTotalRow(this.props.deviceStatus.runtimeTotal1, 'RuntimeTotal1', 1)}
                {this.FormatTotalRow(this.props.deviceStatus.runtimeTotal2, 'RuntimeTotal2', 1)}
            </div>
        );
    }

    private FormatTotalRow(total: Total | null, totalName: string, numDecimals: number): JSX.Element {
        // Check for null values
        if (total === null) {
            return <span className="label-small text-medium-gray">-</span>;
        }

        return (
            <div className="row content flex-container pb-0">
                <div className="col-lg-4 col-md-0 col-sm-0"/>
                <div className="col-lg-4 col-md-6 col-sm-6">
                    <span className="text-bold">
                        {total.status.value.toLocaleString(undefined, {maximumFractionDigits: numDecimals})}
                    </span> {total.status.units}
                </div>
                <div className="col-lg-4 col-md-6 col-sm-6">
                    <button
                        className="btn button-standard float-right"
                        onClick={() => this.resetTotal(totalName)}
                        disabled={(this.props.isDisabled === null ? true : this.props.isDisabled)}
                    >
                        Reset
                    </button>
                </div>
            </div>);
    }

    private resetTotal(totalName: string) {
        resetTotal(this.props.deviceId, totalName);

        postEvent(
            this.props.deviceId,
            {
                eventCode: 'Command',
                details: `${totalName} reset request sent`,    
                id: 0,
                timestamp: new Date( Date.now()),
                user: 'a',
            }
        ); 
    }
}
