import * as React from 'react';
import { DeviceSummary, SystemStatus } from '../models/Api';
import { DeviceSummaryComponent } from './DeviceSummaryComponent';
import { AddDeviceModal } from './AddDeviceModal';
import '../styles/DevicesList.css';

export interface Props {
    devices: DeviceSummary[];
}

interface State {
    addDeviceModalOpen: boolean;
}

// This is the list of devices the customer has access too.
// Displays on the Home page
export class DevicesList extends React.Component<Props, State> {
    constructor(props: Props) {
        super(props);

        this.state = {
            addDeviceModalOpen: false
        };

        this.closeModal = this.closeModal.bind(this);
    }

    render() {
        const headerClass: string = 'label-medium font-weight-bold text-medium-blue';
        return (
            <div>
                <div className="d-flex component-standard ">
                    <div className="col">
                        <div className="row d-xl-flex d-none">

                            <div className={'col-xl-2 ' + headerClass}>
                                <div className="row w-100">
                                    <div className="col">
                                        STATUS
                                    </div>
                                </div>
                            </div>

                            <div className={'col-xl-3 ' + headerClass}>
                                <div className="row w-100">
                                    <div className="col">
                                        NAME
                                    </div>
                                </div>
                            </div>

                            <div className={'col-xl ' + headerClass}>
                                <div className="row w-100">
                                    <div className="col">
                                        TARGET
                                    </div>
                                </div>
                            </div>

                            <div className={'col-xl ' + headerClass}>
                                <div className="row w-100">
                                    <div className="col">
                                        ACTUAL
                                    </div>
                                </div>
                            </div>

                            <div className={'col-xl ' + headerClass}>
                                <div className="row w-100">
                                    <div className="col">
                                        CHEM TOTALS
                                    </div>
                                </div>
                            </div>
                        </div>
                        {this.props.devices.map(d =>
                            <DeviceSummaryComponent
                                id={d.id}
                                key={d.id}
                                status={d.systemStatus === null ? SystemStatus.unknown : d.systemStatus}
                                heartbeat={d.heartBeat}
                                name={d.name}
                                group={d.group}
                                rateTimeTarget={d.rateTimeTarget}
                                rateAreaTarget={d.rateAreaTarget}
                                rateTimeStatus={d.rateTimeStatus}
                                rateAreaStatus={d.rateAreaStatus}
                                total1={d.total1}
                                total2={d.total2}
                                isDisconnected={d.isDisconnected}
                                mode={d.mode}
                            />
                        )}
                    </div>
                </div>
                <AddDeviceModal
                    isModalOpen={this.state.addDeviceModalOpen}
                    closeModal={this.closeModal}
                />
                <span 
                    className="label-medium font-weight-bold text-medium-gray pointer" 
                    onClick={() => this.openModal()}
                >
                    Add Device
                </span>
            </div>
        );
    }    

    private openModal() {
        this.setState({
            addDeviceModalOpen: true
        });
    }

    private closeModal() {
        this.setState({
            addDeviceModalOpen: false
        });
    }
}