import * as React from 'react';
import { UVRFEntry } from 'src/models/Api';
import ReactTable from 'react-table';
import 'react-table/react-table.css';
import 'src/styles/TableStyles.css';
import { columnHeaderStyle, dataStyle, columnSubHeaderStyle } from 'src/styles/TableFormats';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTrashAlt, faPlus } from '@fortawesome/free-solid-svg-icons';
import { isNumber } from 'util';
import { InlineInputEdit } from '../InlineInputEdit';

export interface Props {
    units: string;
    currentAngle: number;
    editable: boolean;
    table: UVRFEntry[];
    updateTable: (table: UVRFEntry[]) => void;
    maxCyclePivot: number;
    editConfigValue: (name: string, value: string) => void;
}

export interface State {
    table: UVRFEntry[];
    pivotRow: number;
}

export class UVRFTable extends React.Component<Props, State> {
    constructor(props: Props) {
        super(props);
        this.state = {
            table: props.table,
            pivotRow: -1
        };
    }

    componentWillUpdate(nextProps: Props) {
        if (nextProps.table !== this.props.table) {
            var pivotRow = this.state.table.findIndex((value: UVRFEntry) => {
                return value.startDegree < this.props.currentAngle && value.stopDegree >= this.props.currentAngle;
            });
            // Shouldn't be setting state here!!! This could cause an infinite loop
            this.setState({
                table: nextProps.table,
                pivotRow: isNumber(pivotRow) ? pivotRow : -1
            });
        }
    }

    renderEditable = (cellInfo: any) => {
        return (
            <div
                className="col-auto justify-content-end"
                style={{ backgroundColor: '#fafafa' }}
                contentEditable={true}
                suppressContentEditableWarning={true}
                onBlur={e => {
                    const data = [...this.state.table];
                    data[cellInfo.index][cellInfo.column.id] = e.target.innerHTML;
                    this.props.updateTable(data);
                }}
                dangerouslySetInnerHTML={{
                    __html: this.state.table[cellInfo.index][cellInfo.column.id]
                }}
                tabIndex={1}
                itemType={'text'}
                onKeyDown={k => {
                    if (k.key === 'Enter') {
                        // Don't add new lines into the field
                        k.preventDefault();
                    }
                }}
            />
        );
    }

    render() {
        return (
            <div>
                {this.props.editable &&
                    <div className="row">
                        <div className="row flex-container list-divider">
                            <div className="col-xl-4 col-12 text-medium-blue">
                                MAX PIVOT PERCENT
                            </div>
                            <div className="col-xl-6 col-12">
                                <InlineInputEdit
                                        inputClassname="textbox-style"
                                        name="MaxCyclePivot"
                                        value={this.props.maxCyclePivot}
                                        editValue={this.props.editConfigValue}
                                />
                            </div>
                        </div>
                    <div className="d-flex justify-content-end">
                        <button
                            className="iconButton"
                            onClick={() => {
                                // Add an empty row
                                var table: UVRFEntry[] = [...this.state.table];
                                if (table.length <= 0) {
                                    this.props.updateTable(emptyTableEntry());
                                } else if (table.length < 20) {
                                    // New slice start degree should be 0.1 degrees past previous stop degree
                                    // Even though .stopDegree is a typescript number, it's a javascript string,
                                    // so it must be cast so that "0.1" doesn't get appended to the end of the string
                                    const prevStop: number = Number(table[table.length - 1].stopDegree);
                                    table.push({
                                        index: table.length + 1,
                                        startDegree: prevStop + 0.1,
                                        stopDegree: 359.9,
                                        applicationRate: 0
                                    });
                                    this.props.updateTable(table);
                                } else {
                                    alert('UVRF Entries are currently limited to 20');
                                }
                            }}
                        >
                            <span className={'text-medium-blue m-2'}>
                                ADD ROW
                            </span>
                            <FontAwesomeIcon icon={faPlus} />
                        </button>
                    </div>
                    </div>
                    }
                <ReactTable
                    data={this.state.table}
                    sortable={false}
                    defaultSorted={[{ id: 'index', desc: false }]}
                    resizable={false}
                    defaultPageSize={10}
                    showPageSizeOptions={false}
                    
                    columns={[
                        {
                            Header: '#',
                            id: 'ix header',
                            width: 35,
                            headerStyle: columnHeaderStyle,
                            columns:
                                [{
                                    Header: '',
                                    id: 'index',
                                    accessor: (i: UVRFEntry) => { return i.index; },
                                    width: 35,
                                    headerStyle: columnSubHeaderStyle,
                                    style: dataStyle
                                }]
                        }, {
                            Header: 'START',
                            width: 100,
                            headerStyle: columnHeaderStyle,
                            columns:
                                [{
                                    Header: '(DEGREES)',
                                    accessor: 'startDegree',
                                    headerStyle: columnSubHeaderStyle,
                                    width: 100,
                                }]
                        }, {
                            Header: 'STOP',
                            width: 100,
                            headerStyle: columnHeaderStyle,
                            columns:
                                [{
                                    accessor: 'stopDegree',
                                    Header: `(DEGREES)`,
                                    headerStyle: columnSubHeaderStyle,
                                    width: 100,
                                    style: dataStyle,
                                    Cell: this.props.editable && this.renderEditable
                                }]
                        }, {
                            Header: `APPLICATION RATE`,
                            minWidth: 150,
                            headerStyle: columnHeaderStyle,
                            columns: [
                                {
                                    Header: `(${this.props.units})`,
                                    accessor: 'applicationRate',
                                    headerStyle: columnSubHeaderStyle,
                                    
                                    Cell: this.props.editable && this.renderEditable,
                                    style: dataStyle,
                                }
                            ]
                        }, {
                            Header: '',
                            headerStyle: columnHeaderStyle,
                            columns: [{
                                show: this.props.editable,
                                width: 35,
                                headerStyle: columnHeaderStyle,
                                Cell: cell => (
                                    <div>
                                        <button
                                            className="iconButton"
                                            onClick={() => {
                                                // Delete data from state
                                                var table = [...this.state.table];
                                                var index = table.indexOf(cell.original);
                                                if (index !== -1) {
                                                    table.splice(index, 1);
                                                }
                                                this.props.updateTable(table);
                                            }}
                                        >
                                            <FontAwesomeIcon icon={faTrashAlt} />
                                        </button>
                                    </div>
                                )
                            }]
                        }
                    ]}
                />
            </div>
        );
    }
}

export const emptyTableEntry = (): UVRFEntry[] => {
    return [{
        index: 0,
        startDegree: 0,
        stopDegree: 0,
        applicationRate: 0
    }];
};