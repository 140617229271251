import * as React from 'react';
import { ConfigurationToggle } from './ConfigurationToggle';
import { DeviceConfiguration } from '../models/Api';

interface State {
}

export interface Props {
    deviceConfig: DeviceConfiguration;
    editConfigValue: (name: string, value: string) => void;
}

export class DeviceSettingsWeather extends React.Component<Props, State> {

    constructor(props: Props) {
        super(props);
        this.state = {
        };
    }
    
    render() {
        return (
            <div className="component-standard w-100">

                {/* Header */}
                <div className="row">
                    <div className="col-12 label-large text-dark-blue">
                        WEATHER
                    </div>
                </div>
                <br/>
                {/* Table */}
                <div className="component" >
                    <div className="row flex-container list-divider">
                        {/* Weather */}
                        <div className="col-xl-2 col-12  text-medium-blue">
                            WEATHER
                        </div>
                        <div className="col-xl-2 col-12 ">
                            <ConfigurationToggle
                                checked={this.props.deviceConfig.weather}
                                name="Weather"
                                editValue={this.props.editConfigValue}
                                onLabel="On"
                                offLabel="Off"
                            />
                        </div>                
                    </div>
                </div>
            </div>
        );
    }
}