import * as React from 'react';
import { AlarmIndicator } from './AlarmIndicator';
import { Link } from 'react-router-dom';
import { Alarm } from '../models/Api';
import * as moment from 'moment';

interface State {
    hourFilter: number;
}

export interface Props {
    alarms: Alarm[];
}

export class AlarmDisplay extends React.Component<Props, State> {

    constructor(props: Props) {
        super(props);
        this.state = {
            hourFilter: 24,
        };
        this.UpdateDropdown = this.UpdateDropdown.bind(this);
    }

    render() {
        /*time evaluated in milliseconds, convert to hour interval*/
        const interval = this.state.hourFilter * 60 * 60 * 1000; 

        return (
            <div className="subpage-standard">
                <div className="component-standard">

                    {/* Header */}
                    <div className="row">
                        <div className="col-7 label-large font-weight-bold text-medium-blue">
                            ALARMS
                        </div>
                        <div className="col-5 d-flex justify-content-end">
                            <select 
                                className="mr-3 dropdown-style"
                                value={this.state.hourFilter}
                                onChange={this.UpdateDropdown}
                            >
                                <option value="1">Last hour</option>
                                <option value="8">Last 8 hours</option>
                                <option value="24">Last 24 hours</option>
                                <option value="168">Last 7 days</option>
                                <option value="720">Last 30 Days</option>
                                <option value="0">All</option>
                            </select>
                        </div>
                    </div>
                    <div className="row d-none d-md-flex mb-3 mb-md-1">
                        <div className="col-2 text-medium-blue">
                            DEVICE
                        </div>
                        <div className="col-2 text-medium-blue">
                            DATE
                        </div>
                        <div className="col-2 text-medium-blue">
                            TIME
                        </div>
                        <div className="col-2 text-medium-blue">
                            SEVERITY
                        </div>
                        <div className="col text-medium-blue">
                            MESSAGE
                        </div>                        
                    </div>

                    {/* Alarms */}
                    { (this.props.alarms.length === 0) ? 
                    <div className="row content table-element list-divider">
                        <div className="col">
                            No events found in this time range
                        </div>
                    </div>
                    : 
                    this.props.alarms.filter(
                        e => (Date.now() - Date.parse(e.raisedTime.toString()) < interval) || interval === 0
                        ).map(alarm => (
                        <div className="row content table-element list-divider" key={alarm.id}>
                            <div className="col-md-2 col-12 ">
                                <Link to={{ pathname: '/device/' + alarm.deviceId }} >{alarm.deviceName}</Link>
                            </div>
                            <div className="col-6 col-md-2">
                                {moment(alarm.raisedTime).format('MMM DD, YYYY')}
                            </div>
                            <div className="col-6 col-md-2">
                                {moment(alarm.raisedTime).format('HH:mm:ss')}
                            </div>
                            <div className="col-md-2 col-12">
                                <AlarmIndicator
                                    severity={alarm.severity}
                                />
                                {alarm.severity}
                            </div>
                            <div className="col-md-2 col-12">
                                {alarm.type}
                            </div>                            
                        </div>
                    ))}
                </div>
            </div>                    
        );
    }

    UpdateDropdown(e: React.SyntheticEvent<HTMLSelectElement>) {
        this.setState({hourFilter: parseInt(e.currentTarget.value, 10)});
    }

}