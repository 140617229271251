import * as React from 'react';
import * as reactselect from 'react-select';
import '../styles/Modal.css';
import '../styles/EditAlarmSetpointModal.css';
import * as Modal from 'react-modal';
import { AlarmSetpoint, AccountInfo, AlarmRecipient } from '../models/Api';

interface State {
    usersToNotify: AlarmRecipient[];
}

export interface Props {
    setpoint: AlarmSetpoint;
    isModalOpen: boolean;
    editSetpoint: (setpoint: AlarmSetpoint) => void;
    closeModal: () => void;
    users: AccountInfo[];
}

export class EditSimpleAlarmSetpointModal extends React.Component<Props, State> {
    constructor(props: Props) {
        super(props);
        
        this.state = {
            usersToNotify: props.setpoint.users
        };

        this.confirmButtonPressed = this.confirmButtonPressed.bind(this);
        this.onInputChange = this.onInputChange.bind(this);       
        this.handleSelectChange = this.handleSelectChange.bind(this); 
    }

    componentWillReceiveProps(nextProps: Props) {
        if (!nextProps.isModalOpen) {
            this.setState({
                usersToNotify: nextProps.setpoint.users
            });
        }
    }

    render() {
        return ( 
            <div className="Modal">
                <Modal 
                    ariaHideApp={false}
                    isOpen={this.props.isModalOpen}
                    shouldCloseOnEsc={true}
                    shouldCloseOnOverlayClick={true}
                    onRequestClose={this.props.closeModal}
                    className={{
                        base: 'modal-content',
                        afterOpen: 'modal-content_after-open',
                        beforeClose: 'modal-content_before-close'
                    }}
                    overlayClassName={{
                        base: 'modal-overlay',
                        afterOpen: 'modal-overlay_after-open',
                        beforeClose: 'modal-overlay_before-close'
                    }}
                >
                    <div className="container edit-alarm-setpoint-modal">
                        <label className="label-large col-form-label">
                            EDIT ALARM
                        </label>
                        <div className="row form-group"/>
                        <div className="row form-group d-flex align-items-center">
                            <div className="col-sm-5 label-medium text-medium-blue">
                                USERS
                            </div>
                            <div className="col">
                                <reactselect.default
                                    options={
                                        this.props.users.map( u => (
                                            {
                                                value: u.id,
                                                label: u.firstName + ' ' + u.lastName
                                            })
                                        )
                                    }
                                    closeMenuOnSelect={false}
                                    hideSelectedOptions={true}
                                    isMulti={true}
                                    placeholder="Select users to notify"
                                    value={
                                        this.state.usersToNotify.map( u => ({
                                            value: u.id,
                                            label: u.name
                                        }))
                                    }
                                    onChange={this.handleSelectChange}
                                />
                                
                            </div>
                        </div>
                        <div className="row form-group">
                            <div className="col">
                                <button
                                    className="btn btn-block button-standard confirm-button"
                                    tabIndex={2}
                                    onClick={this.confirmButtonPressed}
                                >
                                    Confirm
                                </button>
                            </div>
                            <div className="col">
                                <button
                                    className="btn btn-block button-standard cancel-button"
                                    tabIndex={3}
                                    onClick={this.props.closeModal}
                                >
                                    Cancel
                                </button>
                            </div>
                        </div>
                    </div>
                </Modal>
            </div>
        );
    }

    private onInputChange(e: React.SyntheticEvent<HTMLInputElement> | React.SyntheticEvent<HTMLSelectElement>) {
        this.setState({
            ...this.state,
            [e.currentTarget.name]: e.currentTarget.value
        });
    }

    private handleSelectChange(selectedValues: {value: string, label: string}[]) {
        this.setState({
            usersToNotify: selectedValues.map( v => ({
                id: v.value,
                name: v.label
            }))
        });
    }

    private confirmButtonPressed() {
        // Get current setpoint object
        let setpoint = this.props.setpoint;

        // Overwrite setpoint value and totalizer ID
        setpoint.users = this.state.usersToNotify;
        
        // Edit the setpoint
        this.props.editSetpoint(setpoint);

        // Then close the modal
        this.props.closeModal();
    }
}
