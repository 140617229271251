import * as React from 'react';
import ReactTable from 'react-table';
import 'react-table/react-table.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPencilAlt } from '@fortawesome/free-solid-svg-icons';
import {matchSorter} from 'match-sorter';
import * as userApi from 'src/api/Users';
import { CustomerInfo } from 'src/models/Api';
import { AddCustomerModal } from 'src/components/modal-add-customer';
import { EditCustomerModal } from 'src/components/modal-edit-customer';
import { ConfirmDeleteModal } from 'src/components/modal-confirmDelete';
import 'src/styles/TableStyles.css';
import { columnHeaderStyle, dataStyle } from 'src/styles/TableFormats';

interface State {
  selected: boolean;
  customers: CustomerInfo[];
  editData: CustomerInfo;
  addModalOpen: boolean;
  editModalOpen: boolean;
  deleteModalOpen: boolean;
}

export interface Props {
}

export class CustomersAdminPage extends React.Component<Props, State> {
  private numRequests: number = 0;
  constructor(props: Props) {
    super(props);

    this.state = {
      selected: false,
      customers: [],
      editData: {id: 0, name: ''},
      addModalOpen: false,
      editModalOpen: false,
      deleteModalOpen: false,
    };
    this.deleteCustomer = this.deleteCustomer.bind(this);
  }

  componentDidMount() {
    this.getCustomersApiCall();
  }

  handleEdit(customer: CustomerInfo) {
    // this.editData = customer;
    this.setState({
      editModalOpen: true,
      editData: customer
    });
  }  

  handleDelete(customer: CustomerInfo) {
    this.setState({
      deleteModalOpen: true,
      editData: customer
    });
  }

  render() {
    return (
      <div className="subpage-standard">
        <div className="row component-standard">
          <div className="container-fluid">
            <div className="row">
              <div className="col-6 label-large">CUSTOMERS</div>
              <div className="col d-flex justify-content-end align-items-center">
                <button
                    className="btn btn-outline-secondary m-2 justify-content-right button-standard"
                    onClick={() => this.setState({addModalOpen: true})}
                >
                    + ADD CUSTOMER
                </button>
                <AddCustomerModal
                  isModalOpen={this.state.addModalOpen}
                  closeModal={() => this.setState({addModalOpen: false})}
                />
            </div>
          </div>
          <br/>
          <div className="ReactTable">
            <ReactTable
              data={this.state.customers}
              filterable={true}
              defaultFilterMethod={(filter, row) => (matchSorter([row[filter.id]], filter.value).length !== 0)}
              showPagination={this.state.customers.length > 20}
              minRows={10}
              // COLUMNS ////////////////////////////////
              columns={[
                {
                  Header: 'NAME',
                  accessor: 'name',
                  headerStyle: columnHeaderStyle,
                  style: dataStyle
                },              
                {
                  Header: '',
                  headerStyle: columnHeaderStyle,
                  width: 90,
                  sortable: false,
                  filterable: false,
                  Cell: row => (
                    <div>
                      <button
                        className="iconButton"
                        onClick={() => this.handleEdit(row.original)}
                      >
                        <FontAwesomeIcon icon={faPencilAlt} />
                      </button>
                      {/* <button
                        className="iconButton"
                        onClick={() => this.handleDelete(row.original)}
                      >
                        <FontAwesomeIcon icon={faTrashAlt} />
                      </button> */}
                    </div>
                  )
                }
              ]}
              // COLUMNS END //////////////////
              defaultSorted={[{id: 'name', desc: false }]}
              className="-highlight"
            />
          <EditCustomerModal
            isModalOpen={this.state.editModalOpen}
            currentInfo={this.state.editData}
            closeModal={this.closeModal}
          />
          <ConfirmDeleteModal
            isModalOpen={this.state.deleteModalOpen}
            closeModal={this.closeModal}
            confirmInfo={this.state.editData.name}
            deleteInfo={this.deleteCustomer}
          />
        </div>        
      </div>
      </div>
      </div>
    );
  }
  
  private getCustomersApiCall = () => {
    if (this.numRequests === 0) {  
      userApi.getCustomers()
      .then(c => {
        this.setState({ customers: c });
      });    
    }
  }

  private async deleteCustomer(): Promise<any> {
    // Increment number of requests so that we don't update local state until this request resolves
    this.numRequests = this.numRequests + 1;

    // Actually call the edit API method
    await userApi.deleteCustomerApi(this.state.editData);

    // Decrement number of requests since this one has resolved
    this.numRequests = this.numRequests - 1;

    // Call for update to alarm list if there are no more pending requests
    this.getCustomersApiCall();
  }

  private closeModal = () => {    
    this.setState({
      ...this.state,
      addModalOpen: false,
      editModalOpen: false,
      deleteModalOpen: false
    });
    this.getCustomersApiCall();
  }
}
