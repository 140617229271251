import * as React from 'react';
import * as moment from 'moment';
import * as eventApi from '../../api/Events';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import { DeviceEventLog } from '../../components/DeviceEventLog';
import { DeviceEvent } from '../../models/Api';

interface State {
    timespan: number;
    events: DeviceEvent[];
}

export interface Props extends RouteComponentProps<{}> {
    deviceId: number;
}

class DeviceLogSubPage extends React.Component<Props, State> {

    constructor(props: Props) {
        super(props);

        this.state = {
            timespan: 24,
            events: [{
                id: -1,
                timestamp: new Date(),
                eventCode: '',
                details: '',
                user: '',
            }]
        };  
    }

    componentDidMount() {
        this.updateEventData(this.state.timespan);
        window.scrollTo(0, 0);
    }

    render() {
        return (
            <div className="subpage-standard">
                <DeviceEventLog
                    deviceId={this.props.deviceId}
                    events={this.state.events}
                    updateEventData={this.updateEventData}
                />
            </div>
        );
    }

    updateEventData = (timespan: number) => {
        if (timespan > 0) {
            // Calculate start and end date based on timespan
            let endDate = new Date(Date.now());
            let startDate = moment().subtract(timespan, 'hours').toDate();

            eventApi.getEvents(this.props.deviceId, startDate, endDate).then((result) => {
                if (result.success && result.data) {
                    // Events found - set state appropriately
                    this.setState({
                        events: result.data!.reverse()
                    });
                }
            });
        } else {
            // Timespan less than or equal to zero - get all events
            eventApi.getAllEvents(this.props.deviceId).then((result) => {
                if (result.success && result.data) {
                    // Events found - set state appropriately
                    this.setState({
                        events: result.data!
                    });
                }
            });
        }
    }
}

export default withRouter(DeviceLogSubPage);