import * as React from 'react';
import * as Modal from 'react-modal';
import '../styles/EditAlarmSetpointModal.css';
import { UVRFEntry, Status } from 'src/models/Api';
import { getUVRFConfiguration, updateUVRFConfiguration } from 'src/api/DeviceConfiguration';
import { UVRFChart } from './UVRF Components/UVRFChart';
import { UVRFTable, emptyTableEntry } from './UVRF Components/UVRFTable';
import { postEvent } from 'src/api/Events';

export interface Props {
    title: string;
    units: string;
    currentAngle: number;
    currentTitleLabel: string;
    deviceId: number;
    isModalOpen: boolean;
    closeModal: () => void;
    latitude: number;
    longitude: number;
    pivotLength: Status;
    pivotSpeed: number;
    maxCyclePivot: number;
    editConfigValue: (name: string, value: string) => void;
}

export interface State {
    table: UVRFEntry[];
}

export class EditUVRFControlModal extends React.Component<Props, State> {
    
    private uvrfValidationMsg: string = '';

    constructor(props: Props) {
        super(props);
        this.state = {
            table: emptyTableEntry()
        };
    }

    componentDidUpdate( prevProps: Props ) {
        if (prevProps.isModalOpen !== this.props.isModalOpen) {
            this.getUVRFConfig(this.props.deviceId);
        }
    }

    render() {
        return (
            <div className="Modal">
                <Modal
                    ariaHideApp={false}
                    isOpen={this.props.isModalOpen}
                    shouldCloseOnEsc={true}
                    shouldCloseOnOverlayClick={true}
                    onRequestClose={this.props.closeModal}
                    className={{
                        base: 'modal-content',
                        afterOpen: 'modal-content_after-open',
                        beforeClose: 'modal-content_before-close'
                    }}
                    overlayClassName={{
                        base: 'modal-overlay',
                        afterOpen: 'modal-overlay_after-open',
                        beforeClose: 'modal-overlay_before-close'
                    }}
                >
                    <div className="container edit-uvrf-modal">
                        <label className="label-large col-form-label">
                            {this.props.title}
                        </label>
                        
                        <div className="row">
                            <div className="col-md-6 ReactTable">
                                <UVRFTable
                                    units={this.props.units}
                                    currentAngle={this.props.currentAngle}
                                    table={this.state.table}
                                    editable={true}
                                    updateTable={this.updateTable}
                                    maxCyclePivot={this.props.maxCyclePivot}
                                    editConfigValue={this.props.editConfigValue}
                                />
                                
                            </div>
                            <div className="col-md-6 center">
                                <div className="text-medium-blue center">
                                    {`CURRENT PIVOT ANGLE: ${this.props.currentAngle}°`}
                                </div>
                                <div className="text-medium-blue center">
                                    {`CURRENT PIVOT SPEED: ${this.props.pivotSpeed}%`}
                                </div>
                                <UVRFChart
                                    units={this.props.units}
                                    currentAngle={this.props.currentAngle}
                                    table={this.state.table}
                                    latitude={this.props.latitude}
                                    longitude={this.props.longitude}
                                    pivotLength={this.props.pivotLength}
                                />
                            </div>
                        </div>

                        <div className="row form-group">
                            <div className="col">
                                <button
                                    className="btn btn-block button-standard confirm-button"
                                    tabIndex={2}
                                    onClick={() => {
                                        const valid = this.validateUVRFConfig();
                                        if (valid) {
                                            this.uvrfValidationMsg = '';
                                            this.postUVRFConfig();
                                        } else {
                                            alert(this.uvrfValidationMsg);
                                        }
                                    }}
                                >
                                    Confirm
                                </button>
                            </div>
                            <div className="col">
                                <button
                                    className="btn btn-block button-standard cancel-button"
                                    tabIndex={3}
                                    onClick={() => {
                                        // this.setState({ table: 0 });
                                        this.props.closeModal();
                                    }}
                                >
                                    Cancel
                                </button>
                            </div>
                        </div>
                    </div>
                </Modal>
            </div>
        );
    }

    private getUVRFConfig = (deviceId: number) => {
        getUVRFConfiguration(deviceId).then((result) => {
            if (result.success) {
                if (result.data!.uvrfTable !== null && result.data!.uvrfTable.length > 0) {
                    this.setState({ 
                        table: result.data!.uvrfTable
                    });
                }
            }
        });
    }

    // Checks that the 'table' in the current state is valid:
    // no overlapping sections, etc
    private validateUVRFConfig = (): boolean => {
        const uvrf: UVRFEntry[] = this.state.table.sort(( a: UVRFEntry, b: UVRFEntry) => a.index - b.index);
        let valid: boolean = true;
        
        for (let i = 0; i < uvrf.length - 1; i++) {
            const u = uvrf[i];
            // Make sure next start degree is larger than current stop degree
            valid = Number(u.stopDegree) === Number(uvrf[i + 1].startDegree) - 0.1;
            if (!valid) {
                this.uvrfValidationMsg = `Stop Degree for index ${i + 1} is not valid.\n`;
                this.uvrfValidationMsg += 'The stop degree must be at less than the next start degree';
                break;
            }

            valid = Number(u.stopDegree) >= Number(u.startDegree) + 0.1;
            if (!valid) {
                this.uvrfValidationMsg = 'Stop Degree must be 0.1 or larger than Start Degree\n';
                this.uvrfValidationMsg += `Index ${u.index} is not valid`;
                break;
            }
        }

        return valid;
    }

    private postUVRFConfig = () => {
        updateUVRFConfiguration({
            deviceId: this.props.deviceId, 
            uvrfTable: this.state.table
        }).then(() => postEvent(
            this.props.deviceId,
            {
                eventCode: 'Command',
                details: `UVRF Table Updated`,
                id: 0,
                timestamp: new Date( Date.now()),
                user: 'a',
            })).then(() => this.props.closeModal());
    }

    private updateTable = (table: UVRFEntry[]) => {
        let updatedTable = table;
        updatedTable.sort(( a: UVRFEntry, b: UVRFEntry) => a.index - b.index);

        // JS doesn't respect the typescript types, which is why all these are explicitly cast to numbers

        // If user changes a StopDegree, set the next StartDegree as previous stop degree plus 0.1
        // If a row was deleted, renumber the index
        for (let i = 0; i < updatedTable.length - 1; i++) {
            // limit stop value to a single decimal place
            updatedTable[i].stopDegree = Number(Number(updatedTable[i].stopDegree).toFixed(1));
            updatedTable[i].applicationRate = Number(Number(updatedTable[i].applicationRate).toFixed(1));
            // All this hacky code is because JS sucks.
            // i.e. if the user enters 33.2 as the StopDegree, JS will set the next StartDegree to 33.300000000000004
            let nextStart: number = 0;
            nextStart = Number(updatedTable[i].stopDegree) + 0.1;
            updatedTable[i + 1].startDegree = Number(nextStart.toFixed(1));
            // Set the index incase a slice was deleted from the middle
            updatedTable[i].index = i + 1;
        }
        this.setState({ table: updatedTable});
    }
}