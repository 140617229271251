import * as React from 'react';
import { DataGraph } from './DataGraph';
import { DeviceHistory } from '../models/Api';
import * as historyApi from '../api/DeviceHistory';
import Datetime from 'react-datetime';
import 'react-datetime/css/react-datetime.css';
import * as moment from 'moment';
import { InlineInputEdit } from './InlineInputEdit';
import { isNullOrUndefined } from 'util';

export interface State {
    history: DeviceHistory;
    showInjectionPumpTarget: boolean;
    showInjectionPumpActual: boolean;
    showWaterFlow: boolean;
    showTankLevel: boolean;
    showPressure: boolean;
    dataTimespan: number;
    dataStartDate: Date;
    dataEndDate: Date;
    exportFileName: string;
    downloadDisabled: boolean;
}

export interface Props {
    deviceId: number;
    // deviceConfig: DeviceConfiguration;
}

export class HistoricalDataComponent extends React.Component<Props, State> {
    historyUpdateTimer: NodeJS.Timer;
    downloadPromise: Promise<boolean | void>;
    constructor(props: Props) {
        super(props);
        
        let emptyHistory: DeviceHistory = {
            start: new Date(),
            end: new Date(),
            aggregation: '',
            data: [],
            injectionPumpUnits: '',
            waterFlowUnits: '',
            tankLevelUnits: '',
            pressureUnits: ''
        };

        this.state = {
            downloadDisabled: false,
            history: emptyHistory,
            showInjectionPumpTarget: true,
            showInjectionPumpActual: true,
            showWaterFlow: false,
            showTankLevel: false,
            showPressure: false,
            dataTimespan: 168,
            dataEndDate: new Date( Date.now() ),
            dataStartDate: moment(new Date( Date.now() )).subtract(168, 'hours').toDate(),
            exportFileName: 'ReflexData-' + moment(new Date( Date.now())).format('YYYY-MM-DD-HHMM')
        };

        this.updateDropdown = this.updateDropdown.bind(this);
        this.toggleGraph = this.toggleGraph.bind(this);
        this.getHistory = this.getHistory.bind(this);
        this.updateStartDate = this.updateStartDate.bind(this);
        this.updateEndDate = this.updateEndDate.bind(this);
        this.updateDaterange = this.updateDaterange.bind(this);
        this.exportHistory = this.exportHistory.bind(this);
    }

    componentDidMount() {
        this.getHistory(this.state.dataStartDate, this.state.dataEndDate);
        window.scrollTo(0, 0);
    }

    componentDidUpdate(prevProps: Props) {
        if (prevProps.deviceId !== this.props.deviceId) {
            this.getHistory(this.state.dataStartDate, this.state.dataEndDate);
        }
    }

    render() {
        return (
            <div className="d-flex component-standard w-100">
                <div className="col">
                    <div className="row">
                        <div className="col-sm-9 label-large text-dark-blue">
                            HISTORICAL DATA
                        </div>
                        <div className="col-sm d-flex justify-content-sm-end justify-content-start">
                            <select
                                className="dropdown-style"
                                value={this.state.dataTimespan}
                                onChange={this.updateDropdown}
                            >
                                <option value="1">Last hour</option>
                                <option value="8">Last 8 hours</option>
                                <option value="24">Last 24 hours</option>
                                <option value="168">Last 7 days</option>
                                <option hidden={true} value="0">Custom</option>
                            </select>
                        </div>
                        <div className="row">
                            <div
                                className="col-sm d-flex justify-content-sm-end
                                            justify-content-start align-items-center"
                            >
                                <div className="ml-2 text-medium-blue">
                                    START:
                                </div>

                                    <Datetime
                                        className="dateEntry-style"
                                        initialValue={this.state.dataStartDate}
                                        value={this.state.dataStartDate}
                                        onChange={this.updateStartDate}
                                    />

                            </div>
                            <div
                                className="col-sm d-flex justify-content-sm-end
                                            justify-content-start align-items-center"
                            >
                                <div className="ml-2 text-medium-blue">
                                    END:
                                </div>
                                    <Datetime
                                        className="dateEntry-style"
                                        initialValue={this.state.dataEndDate}
                                        value={this.state.dataEndDate}
                                        onChange={this.updateEndDate}
                                    />
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <DataGraph
                            data={this.state.history.data}
                            showInjectionPumpTarget={this.state.showInjectionPumpTarget}
                            showInjectionPumpActual={this.state.showInjectionPumpActual}
                            showWaterFlow={this.state.showWaterFlow}
                            showTankLevel={this.state.showTankLevel}
                            showPressure={this.state.showPressure}
                            injectionPumpUnits={this.state.history.injectionPumpUnits}
                            waterFlowUnits={this.state.history.waterFlowUnits}
                            tankLevelUnits={this.state.history.tankLevelUnits}
                            pressureUnits={this.state.history.pressureUnits}
                            startDate={this.state.dataStartDate}
                            endDate={this.state.dataEndDate}
                        />
                    </div>
                    <div className="row d-flex justify-content-center list-divider m-2">
                        <button
                            className="btn button-standard mr-2"
                            onClick={() => this.toggleGraph('showInjectionPumpTarget')}
                            style={this.state.showInjectionPumpTarget ?
                                {background: '#00c270'} : {background: '#86a1ad'}
                            }
                        >
                            Injection Pump - Target
                        </button>

                        <button
                            className="btn button-standard mr-2"
                            onClick={() => this.toggleGraph('showInjectionPumpActual')}
                            style={this.state.showInjectionPumpActual ?
                                {background: '#42b2e2'} : {background: '#86a1ad'}
                            }
                        >
                            Injection Pump - Actual
                        </button>

                        <button
                            className="btn button-standard mr-2"
                            onClick={() => this.toggleGraph('showWaterFlow')}
                            style={this.state.showWaterFlow ?
                                {background: '#f15a24'} : {background: '#86a1ad'}
                            }
                        >
                            Irrigation Flow
                        </button>

                        <button
                            className="btn button-standard mr-2"
                            onClick={() => this.toggleGraph('showTankLevel')}
                            style={this.state.showTankLevel ?
                                {background: '#e671ff'} : {background: '#86a1ad'}
                            }
                        >
                            Tank Level
                        </button>

                        <button
                            className="btn button-standard "
                            onClick={() => this.toggleGraph('showPressure')}
                            style={this.state.showPressure ?
                                {background: '#9758c7'} : {background: '#86a1ad'}
                            }
                        >
                            Injection Pressure
                        </button>
                    </div>
                    <div className="row content flex-container">
                        <div className="col-sm-9 label-large text-dark-blue">
                            DOWNLOAD GRAPH DATA TO CSV FILE
                        </div> 
                    </div>
                    <div className="row content flex-container">
                        <div className="col-md-2 text-medium-blue mr-2">
                            FILENAME:
                        </div>
                        <div className="col-md">
                            <div className="row align-items-center">
                                <InlineInputEdit
                                    name="FileNameToDownload"
                                    value={this.state.exportFileName}
                                    editValue={(fileName) => this.setState({exportFileName: fileName})}
                                    inputClassname="textbox-style-wide"
                                />
                            </div>
                        </div>
                        <div className="col-md-2 justify-content-sm-end justify-content-start">
                            <button
                                disabled={this.state.downloadDisabled}
                                className="btn button-standard "
                                onClick={() => this.exportHistory(
                                    this.state.dataStartDate, 
                                    this.state.dataEndDate, 
                                    this.state.exportFileName)}
                            >
                                Download
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        );
    }

    private toggleGraph(property: string) {
        this.setState({
            ...this.state,
            [property]: !this.state[property]
        });
    }

    private updateDropdown(e: any) {
        // Update dropdown state
        this.setState({dataTimespan: e.target.value});
        this.setState({dataEndDate: new Date(Date.now())});
        this.setState({dataStartDate:
            moment(new Date(Date.now())).subtract(e.target.value, 'hours').toDate()});
        // Update event list
        this.getHistory(
            moment(new Date(Date.now())).subtract(e.target.value, 'hours').toDate(),
            new Date(Date.now())
            );
    }

    private updateStartDate(e: moment.Moment) {
        this.setState({
                dataStartDate: moment(e).toDate(),
                dataTimespan: 0
            });
        this.updateDaterange(moment(e).toDate(), this.state.dataEndDate);
    }

    private updateEndDate(e: moment.Moment) {
        this.setState({
            dataEndDate: moment(e).toDate(),
            dataTimespan: 0
        });
        this.updateDaterange(this.state.dataStartDate, moment(e).toDate());
    }

    private updateDaterange(start: Date, end: Date) {
        if (!isNullOrUndefined(this.historyUpdateTimer)) {
            // Delay timer was already running.
            clearTimeout(this.historyUpdateTimer);
        }

        // Start (or restart) the delay timer
        this.historyUpdateTimer = setTimeout(this.getHistory, 3000);

        // this.getHistory(start, end);
    }

    private getHistory(startDateIn: Date, endDateIn: Date) {
        if (isNullOrUndefined(startDateIn)) {
            startDateIn = this.state.dataStartDate;
        }

        if (isNullOrUndefined(endDateIn)) {
            endDateIn = this.state.dataEndDate;
        }

        let startDate = startDateIn;
        startDate.setSeconds(startDate.getSeconds() - 1);   // Need to do this to ensure data falls in correct range
        
        historyApi.getHistoryRange(this.props.deviceId, startDate, endDateIn)
        .then((result) => {
            if (result.success) {
                // Device found - set state appropriately
                this.setState({
                    ...this.state,
                    history: result.data!
                });
            }
        });
    }

    private exportHistory(startDateIn: Date, endDateIn: Date, filename: String) {
        this.setState({downloadDisabled: true});
        let startDate = startDateIn;
        startDate.setSeconds(startDate.getSeconds() - 1);   // Need to do this to ensure data falls in correct range
        
        this.downloadPromise = historyApi.exportHistoryReport(this.props.deviceId, startDate, endDateIn, filename);
        this.downloadPromise.then(() => {
            this.setState({downloadDisabled: false});
        })
        .catch(() => {
            this.setState({downloadDisabled: false});
        });
    }
}