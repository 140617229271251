import { ApiResult, DeviceEvent } from '../models/Api';
import { httpRequest, Urls, downloadFile } from './Shared';

const DEVICES_URL = '/Devices';
const EVENTS_URL = '/Events';

export const getEvents = (deviceId: number, start: Date, end: Date): Promise<ApiResult<DeviceEvent[]>> => {
    return httpRequest(
        `${Urls.BASE_URL}${DEVICES_URL}/${deviceId}${EVENTS_URL}/${start.toISOString()}/${end.toISOString()}`,
        'get'
    );
};

export const getAllEvents = (deviceId: number): Promise<ApiResult<DeviceEvent[]>> => {
    return httpRequest(`${Urls.BASE_URL}${DEVICES_URL}/${deviceId}${EVENTS_URL}`,
                       'get'
                        );
};

export const postEvent = (deviceId: number, event: DeviceEvent): Promise<ApiResult<DeviceEvent>> => {
    return httpRequest( `${Urls.BASE_URL}${EVENTS_URL}`,
                        'post',
                        {
                            'DeviceId': deviceId,
                            'EventCode': event.eventCode,
                            'Details': event.details
                        });
};

export const downloadEventLog = (deviceId: number,
                                 startTime: Date,
                                 endTime: Date,
                                 Cmds: boolean,
                                 Alarms: boolean,
                                 Notifications: boolean,
                                 Weather: boolean) => {

    let url = `${Urls.BASE_URL}${EVENTS_URL}/Download/${deviceId}`;
    url += `?start=${startTime.toISOString()}`;
    url += `&end=${endTime.toISOString()}`;
    url += `&cmds=${Cmds}`;
    url += `&alarms=${Alarms}`;
    url += `&notifications=${Notifications}`;
    url += `&weather=${Weather}`;
    downloadFile(url, `${deviceId}-EventLog.csv`);
};