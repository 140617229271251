import * as React from 'react';
import * as Modal from 'react-modal';
import '../styles/EditAlarmSetpointModal.css';
import { InlineInputEdit } from './InlineInputEdit';

export interface Props {
    title: string;
    value: number;
    units: string;
    currentTitleLabel: string;
    editTitleLabel: string;
    isModalOpen: boolean;
    closeModal: () => void;
    confirmValueChange: (newValue: number) => void;
}

export interface State {
    value: number;
}

export class EditDeviceControlModal extends React.Component<Props, State> {
    constructor(props: Props) {
        super(props);
        this.state = {
            value: props.value
        };
    }

    handleInputChange(name: string, value: string) {
        if (isNaN(Number(value))) {
            return;
        } else {
            this.setState({
                value: parseFloat(value)
            });
        }
    }

    render() {

        return (
            <div className="Modal">
                <Modal
                    ariaHideApp={false}
                    isOpen={this.props.isModalOpen}
                    shouldCloseOnEsc={true}
                    shouldCloseOnOverlayClick={true}
                    onRequestClose={this.props.closeModal}
                    className={{
                        base: 'modal-content',
                        afterOpen: 'modal-content_after-open',
                        beforeClose: 'modal-content_before-close'
                    }}
                    overlayClassName={{
                        base: 'modal-overlay',
                        afterOpen: 'modal-overlay_after-open',
                        beforeClose: 'modal-overlay_before-close'
                    }}
                >
                    <div className="container edit-alarm-setpoint-modal">
                        <label className="label-large col-form-label">
                            {this.props.title}
                        </label>
                        <div className="row form-group" />
                        <div className="row form-group d-flex align-items-center">
                            <div className="col-sm-5 text-medium-blue">
                                {this.props.currentTitleLabel}
                            </div>
                           <div className="col justify-content-end">
                            <div className="row justify-content-end">
                            
                                {/* <span>{this.props.setpointPreValueString}</span> */}
                                <span className="text-bold justify-content-end">{this.props.value.toFixed(1)}</span>

                                {/* <span>{this.props.setpointPostValueString}</span> */}
                            </div>
                            </div>
                            <div className="col">
                                <span className="text-medium-blue">{this.props.units}</span>
                            </div>
                        </div>
                        <div className="row form-group d-flex align-items-center">
                            <div className="col-sm-5 text-medium-blue">
                                {this.props.editTitleLabel}
                            </div>
                            <div className="col justify-content-end">
                            
                            <div className="row justify-content-end">
                                <InlineInputEdit
                                    value={this.state.value.toFixed(1)}
                                    name="setpointvalue"
                                    editValue={(name, value) => this.handleInputChange(name, value)}
                                    inputClassname="textbox-style-right w-100"
                                />
                                </div>
                            </div>
                            <div className="col text-medium-blue">
                                {this.props.units}
                            </div>

                        </div>

                        <div className="row form-group">
                            <div className="col">
                                <button
                                    className="btn btn-block button-standard confirm-button"
                                    tabIndex={2}
                                    onClick={() => {
                                        this.setState({ value: 0 });
                                        this.props.confirmValueChange(this.state.value);
                                    }}
                                >
                                    Confirm
                                </button>
                            </div>
                            <div className="col">
                                <button
                                    className="btn btn-block button-standard cancel-button"
                                    tabIndex={3}
                                    onClick={() => {
                                        this.setState({ value: 0 });
                                        this.props.closeModal();
                                    }}
                                >
                                    Cancel
                                </button>
                            </div>
                        </div>
                    </div>
                </Modal>
            </div>
        );
    }
}