import * as React from 'react';
import * as moment from 'moment';
import { DeviceEvent } from '../models/Api';
import * as eventApi from '../api/Events';

interface State {
    hourFilter: number;
    showCommand: boolean;
    showAlarm: boolean;
    showNotification: boolean;
    showWeather: boolean;
    showConnections: boolean;
}

export interface Props {
    deviceId: number;
    events: DeviceEvent[];
    updateEventData: (timespan: number) => void;
}

export class DeviceEventLog extends React.Component<Props, State> {
    constructor(props: Props) {
        super(props);
        this.state = {
            hourFilter: 24, /* Default value of 24 hours */
            showCommand: true,
            showAlarm: true,
            showNotification: true,
            showWeather: true,
            showConnections: true,
        };
    }

    UpdateDropdown = (e: any) => {
        // Update dropdown state
        this.setState({hourFilter: e.target.value});

        // Update event list
        this.props.updateEventData(e.target.value);
    }

    toggleConnectionsView = () => {
        this.setState({
            showConnections: !this.state.showConnections,
        });
    }

    toggleCommandView = () => {
        this.setState({
            showCommand: !this.state.showCommand,
        });
    }

    toggleAlarmView = () => {
        this.setState({
            showAlarm: !this.state.showAlarm,
        });
    }

    toggleNotificationView = () => {
        this.setState({
            showNotification: !this.state.showNotification,
        });
    }

    toggleWeatherView = () => {
        this.setState({
            showWeather: !this.state.showWeather,
        });
    }

    DownloadEventLog = () => {
        console.log('Downloading');
        const start = moment(new Date( Date.now() )).subtract(this.state.hourFilter, 'hours').toDate();
        const end = new Date(Date.now());
        eventApi.downloadEventLog(  this.props.deviceId,
                                    start,
                                    end,
                                    this.state.showCommand,
                                    this.state.showAlarm,
                                    this.state.showNotification,
                                    this.state.showWeather);
    }

    render() {
        return (
            <div className="component-standard">
                <div>
                    <div className="row">
                        <div className="col-lg-3 col-12 label-large text-dark-blue">
                            EVENT LOG
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-lg-3 col-sm-2 text-dark-blue">
                            <button
                                className="btn button-standard mr-2"
                                onClick={this.DownloadEventLog}
                            >
                                DOWNLOAD
                            </button>
                        </div>
                        <div className="col-lg-9 col-12 container-fluid justify-content-end">
                            <select 
                                className="mr-3 dropdown-style float-right"
                                value={this.state.hourFilter}
                                onChange={this.UpdateDropdown}
                            >
                                <option value="1">Last hour</option>
                                <option value="8">Last 8 hours</option>
                                <option value="24">Last 24 hours</option>
                                <option value="168">Last 7 days</option>
                                {/* <option value="720">Last 30 Days</option> */}
                                <option value="0">All</option>
                            </select>
                            <button 
                                className="btn button-standard mr-2 float-right"
                                onClick={this.toggleConnectionsView}
                                style={this.state.showConnections ?
                                    {background: '#7AB800'}
                                    : {background: '#86a1ad'}}
                            >
                                CONNECTIONS
                            </button>
                            <button 
                                className="btn button-standard mr-2 float-right"
                                onClick={this.toggleCommandView}
                                style={this.state.showCommand ? {background: '#7AB800'} : {background: '#86a1ad'}}
                            >
                                COMMAND
                            </button>
                            <button 
                                className="btn button-standard mr-2 float-right"
                                onClick={this.toggleAlarmView}
                                style={this.state.showAlarm ? {background: '#7AB800'} : {background: '#86a1ad'}}
                            >
                                ALARM
                            </button>
                            <button 
                                className="btn button-standard mr-2 float-right"
                                onClick={this.toggleNotificationView}
                                style={this.state.showNotification ? 
                                    {background: '#7AB800'} : {background: '#86a1ad'}}
                            >
                                NOTIFICATIONS
                            </button>
                            <button 
                                className="btn button-standard mr-2 float-right"
                                onClick={this.toggleWeatherView}
                                style={this.state.showWeather ? {background: '#7AB800'} : {background: '#86a1ad'}}
                            >
                                WEATHER
                            </button> 
                            
                        </div>
                    </div>
                </div>

                { (this.props.events.length === 0) ? 
                    <div>
                        <br/>
                        <span>No events found in this time range</span>
                        <br/>
                    </div>
                    : (this.props.events[0].id === -1) ? 
                    <div>
                        <br/>
                        <span>LOADING EVENTS ...</span>
                        <br/>
                    </div>
                    : 
                    <div>
                        <div className="row d-none d-md-flex sub-header">
                            <div className="col">
                                Date
                            </div>
                            <div className="col">
                                Time
                            </div>
                            <div className="col">
                                Event
                            </div>
                            <div className="col">
                                Details
                            </div>
                            <div className="col">
                                User
                            </div>
                        </div>
                        <div className="event-log">
                            {this.props.events.filter(e =>
                                    this.state.showWeather && e.eventCode === 'Weather' ||
                                    this.state.showCommand && e.eventCode === 'Command' ||
                                    this.state.showAlarm && (e.eventCode === 'Alarm' || e.eventCode === 'Warning') ||
                                    this.state.showNotification && e.eventCode === 'Notification' ||
                                    this.state.showConnections &&
                                        (e.eventCode === 'DeviceConnected' || e.eventCode === 'DeviceDisconnected')
                                    ).map(e =>
                                    <div className="row table-element list-divider" key={e.id}>
                                        <div className="col-6 col-md"> 
                                            {moment(new Date(e.timestamp + 'Z')).format('MMM DD, YYYY')}
                                        </div>
                                        <div className="col-6 col-md"> 
                                            {moment(new Date(e.timestamp + 'Z')).format('h:mm:ss A')}
                                        </div>
                                        <div className="col-12 col-md"> 
                                            {e.eventCode}
                                        </div>
                                        <div className="col-12 col-md"> 
                                            {e.details}
                                        </div>
                                        <div className="col-12 col-md"> 
                                            {e.user}
                                        </div>
                                    </div>
                                    )}
                        </div>
                    </div>
                }
            </div>
        );
    }
}
