import * as React from 'react';
import * as Modal from 'react-modal';
import '../styles/Modal.css';
import { AccountInfo, CustomerInfo, DropdownList } from 'src/models/Api';
import { ConfigurationSelect } from './ConfigurationSelect';
import { postUser, getCustomers } from 'src/api/Users';
import { isAdminRole, USER_ROLE } from 'src/identity';
import { UserAddFieldComponent } from './UserAddFieldComponent';
import { getDefaultUserInfo } from 'src/utils/userUtils';

interface State {
    setpoint: AccountInfo;    
    customerOptions: DropdownList[];
    roleOptions: DropdownList[];
    message: string;
}

export interface Props {
    // addInfo: (responseInfo: AccountInfo) => void;
    isModalOpen: boolean;
    closeModal: () => void;
}

export class AddUserModal extends React.Component<Props, State> {
    constructor(props: Props) {
        super(props);
        
        this.state = {
            setpoint: getDefaultUserInfo(),
            customerOptions: [],
            roleOptions: [{value: 1, name: USER_ROLE.CUSTOMER},
                          {value: 2, name: USER_ROLE.ADMINISTRATOR}],
            message: ''
        };

        this.confirmButtonPressed = this.confirmButtonPressed.bind(this);
        this.onInputChange = this.onInputChange.bind(this);
        this.handleCustomerEdit = this.handleCustomerEdit.bind(this);
    }    

    componentDidMount() {
        this.getDropDownOptions();
    }

    getDropDownOptions = () => {
        // Variables to promises
        // Wait till all promises are fullfiled to set the state once
        let cPromise: Promise<CustomerInfo[]>;
        cPromise = getCustomers();

        Promise.resolve(cPromise).then(value =>
        this.setDropDownOptions(value)
        );
    }
    
      // Given an array of arrays, set the values as the drop down options:
    setDropDownOptions = ( value: CustomerInfo[] ) => {    
        let cOptions = value.map(c => {
            return { name: c.name, value: c.id };
        });

        // Add an empty selection to the beginning of the options
        cOptions.unshift({ name: '', value: 0 });

        this.setState({
            customerOptions: cOptions,
        });
    }

    render() {
        return ( 
            <div className="Modal">
                <Modal 
                    ariaHideApp={false}
                    isOpen={this.props.isModalOpen}
                    shouldCloseOnEsc={true}
                    shouldCloseOnOverlayClick={true}
                    onRequestClose={this.props.closeModal}
                    className={{
                        base: 'modal-content',
                        afterOpen: 'modal-content_after-open',
                        beforeClose: 'modal-content_before-close'
                    }}
                    overlayClassName={{
                        base: 'modal-overlay',
                        afterOpen: 'modal-overlay_after-open',
                        beforeClose: 'modal-overlay_before-close'
                    }}
                >
                    <div className="container add-user-modal">
                        <label className="label-large col-form-label">
                            ADD USER
                        </label>
                        <div className="row form-group"/>                                                
                        <UserAddFieldComponent 
                            handleChange={this.onInputChange}
                            value={this.state.setpoint.firstName}
                            fieldName="FIRST NAME"
                            APIName="firstName"
                        />
                        <UserAddFieldComponent 
                            handleChange={this.onInputChange}
                            value={this.state.setpoint.lastName}
                            fieldName="LAST NAME"
                            APIName="lastName"
                        />
                        <UserAddFieldComponent 
                            handleChange={this.onInputChange}
                            value={this.state.setpoint.email}
                            fieldName="EMAIL"
                            APIName="email"
                        />
                        <UserAddFieldComponent 
                            handleChange={this.onInputChange}
                            value={this.state.setpoint.phoneNumber}
                            fieldName="PHONE NUMBER"
                            APIName="phoneNumber"
                            type="tel"
                            placeholder="888 888 8888"
                        />
                        <UserAddFieldComponent 
                            handleChange={this.onInputChange}
                            value={this.state.setpoint.licenseNumber}
                            fieldName="APPLICATOR'S LICENSE NO."
                            APIName="licenseNumber"
                            type="number"
                        />
                        <UserAddFieldComponent 
                            handleChange={this.onInputChange}
                            value={this.state.setpoint.licenseEffectiveDate}
                            fieldName="LICENSE EFF DATE"
                            APIName="licenseEffectiveDate"
                            type="date"
                        />                                                                      
                        {isAdminRole() &&
                        <div className="row flex-container list-divider">
                            <div className="col-4 text-medium-blue">
                                CUSTOMER
                            </div>
                            <div className="col">   
                                <div className="row justify-content-end align-items-center">
                                    <div className="col align-items-center">                                     
                                    <ConfigurationSelect
                                        name=" "
                                        value={this.state.setpoint.customerId}
                                        options={this.state.customerOptions}
                                        editValue={this.handleCustomerEdit}
                                    />
                                    </div>
                                </div>
                            </div>
                        </div>
                        } 
                        {isAdminRole() &&
                        <div className="row flex-container list-divider">
                            <div className="col-4 text-medium-blue">
                                ROLE
                            </div>
                            <div className="col">   
                                <div className="row justify-content-end align-items-center">
                                    <div className="col align-items-center">
                                        <ConfigurationSelect
                                            name=""
                                            value={ this.state.setpoint.roles[0] ? 
                                                this.state.roleOptions[this.state.roleOptions.findIndex(r => 
                                                    r.name === this.state.setpoint.roles[0])].value
                                                    : 0}
                                            options={this.state.roleOptions}
                                            editValue={this.handleRoleChange}
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>    
                        }
                        <div className="row mt-2 form-group d-flex align-items-center justify-content-center">
                            <div className="col d-flex justify-content-center text-error">
                                {this.state.message}
                            </div>
                        </div>                    
                        <div className="row form-group mt-2">
                            <div className="col">
                                <button
                                    className="btn btn-block button-standard confirm-button"
                                    tabIndex={2}
                                    onClick={this.confirmButtonPressed}
                                >
                                    CONFIRM
                                </button>
                            </div>
                            <div className="col">
                                <button
                                    className="btn btn-block button-standard cancel-button"
                                    tabIndex={3}
                                    onClick={this.cancelButtonPressed}
                                >
                                    CANCEL
                                </button>
                            </div>
                        </div>
                    </div>
                </Modal>
            </div>
        );
    }

    private onInputChange = (e: React.SyntheticEvent<HTMLInputElement>) => { 
        let newSetpoint = {...this.state.setpoint, [e.currentTarget.name]: e.currentTarget.value};
        this.setState({
            setpoint: newSetpoint
        });
    }
       
    private handleCustomerEdit = (name: string, value: string) => {
        let newSetpoint = this.state.setpoint;
        newSetpoint.customerId = parseInt(value, 10);
        newSetpoint.customerName = name;
        this.setState({setpoint: newSetpoint});
    }

    private handleRoleChange = (name: string, value: string) => {
        let newSetpoint = this.state.setpoint;
        newSetpoint.roles[0] = this.state.roleOptions[parseInt(value, 10) ? parseInt(value, 10) - 1 : 0].name;
        this.setState({
            // ...this.state,                        
            setpoint: newSetpoint
        });
    }

    private confirmButtonPressed = () => {
        // Edit the setpoint
        // this.props.addInfo(this.state.setpoint);
        postUser(this.state.setpoint).then(result => {
            if (result.success) {
                this.setState({
                    setpoint: getDefaultUserInfo(),
                    message: ''
                });
        
                // Then close the modal
                this.props.closeModal();
            } else {
                this.setState({
                    message: 'Error Adding User, is the email already in use?'
                });
            }
        });        
    }

    private cancelButtonPressed = (): void => {
        this.setState({
            message: ''
        });
        this.props.closeModal();
    }
}
