import * as React from 'react';
import '../styles/NavButton.css';
import { NavLink, match } from 'react-router-dom';
import { Location } from 'history';

export enum NavButtonSize {
    small,
    large,
    pill
}

export interface Props {
    text: string;
    icon?: any;
    iconSelected?: any;
    path: string;
    alternatePaths?: string[];
    size?: NavButtonSize;
    className?: string;
    showSelected?: boolean;
}

function NavButton({text, 
                    icon, 
                    iconSelected, 
                    path, 
                    size = NavButtonSize.small, 
                    alternatePaths, 
                    className, 
                    showSelected = true }: Props) {
    const checkAlternatePath = (pathMatch: match<{}> | null, location: Location): boolean => {
        if (pathMatch ||
            (alternatePaths !== undefined && alternatePaths.some(p => p === location.pathname))) {
            return true;
        } 

        return false;
    };
    
    let sizeClassName = 'small';

    switch (size) {
        case NavButtonSize.large:
            sizeClassName = 'large';
            break;
        case NavButtonSize.pill:
            sizeClassName = 'pill';
            break;
        case NavButtonSize.small:
        default:
            sizeClassName = 'small';
    }

    return (
        <div className={`nav-button ${sizeClassName} ${className ? className : ''}`}>
            <NavLink
                className="button-text text-uppercase d-block text-center"
                to={path}
                activeClassName="selected"
                exact={false}
                isActive={checkAlternatePath}
            >
                {icon && (<img src={icon} className="button-icon" />)}
                {iconSelected && (<img src={iconSelected}  className="selected-button-icon" />)}{text}
            </NavLink >
            {showSelected && <div className="selected-border">&nbsp;</div>}
        </div>
    );
}

export default NavButton;