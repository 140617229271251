import * as React from 'react';
import { DevicesMap } from './DevicesMap';
import { DeviceStatus, DeviceConfiguration, DeviceMapData } from '../models/Api';

export interface Props {
    deviceStatus: DeviceStatus;
    deviceConfig: DeviceConfiguration;
}

export class LocationComponent extends React.Component<Props> {
    constructor(props: Props) {
        super(props);
    }

    render() {
        let deviceSummary: DeviceMapData[] = [{
            id: this.props.deviceConfig.id,
            name: this.props.deviceConfig.friendlyName,
            irrigationSystemName: this.props.deviceConfig.irrigationSystemName,
            group: this.props.deviceConfig.group,
            systemStatus: this.props.deviceStatus.systemStatus,
            showPivotPoint: true,
            showLabel: true,
            deviceLatitude: this.props.deviceConfig.latitude,
            deviceLongitude: this.props.deviceConfig.longitude,
            pivotPointLatitude: this.props.deviceConfig.pivotPointLatitude,
            pivotPointLongitude: this.props.deviceConfig.pivotPointLongitude
        }];

        return (
            <div className="d-flex component-standard w-100">
                <div className="col">
                    <div className="row label-large">
                        LOCATION
                    </div>
                    <div className="row">
                        <DevicesMap devices={deviceSummary} />
                    </div>
                </div>
            </div>
        );
    }
}