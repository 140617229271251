import * as React from 'react';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import { DeviceAlarms } from '../../components/DeviceAlarms';
import { DeviceConfiguration, Version } from '../../models/Api';

interface State {
}

export interface Props extends RouteComponentProps<{id: string}> {
    deviceConfig: DeviceConfiguration;
    version: Version;
    editConfigValue: (name: string, value: string) => void;
}

class DeviceAlarmsSubPage extends React.Component<Props, State> {

    constructor(props: Props) {
        super(props);

        this.state = {
        };
    }

    render() {
       
        return (
            <div className="subpage-standard">
                <DeviceAlarms 
                    deviceId={Number(this.props.match.params.id)}
                    version={this.props.version}
                />
            </div>
        );
    }
}

export default withRouter(DeviceAlarmsSubPage);