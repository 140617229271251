import * as React from 'react';
import { isNullOrUndefined } from 'util';

export interface ConfigurationSelectType {
    name: string;
    value: number;
    hidden?: boolean;
}

export interface State {
    disabled: boolean;
    value: number;
}

export interface Props {
    name: string;
    value: number;
    options: ConfigurationSelectType[];
    editValue: (name: string, value: string) => void;
    disabled?: boolean;
    style?: string;
}

export class ConfigurationSelect extends React.Component<Props, State> {
    constructor(props: Props) {
        super(props);

        this.state = {
            value: props.value,
            disabled: false
        };

        this.onChange = this.onChange.bind(this);
    }

    componentWillReceiveProps(nextProps: Props) {
        this.setState({
            value: nextProps.value,
            disabled: false
        });
    }

    render() {
        // Hide the <select> until a valid value id passed in
        let hide: boolean = (this.props.value < 0 || isNullOrUndefined(this.props.value));

        return (
            <select 
                className={this.props.style || 'dropdown-style'}
                value={this.props.value}
                onChange={this.onChange} 
                disabled={this.state.disabled || this.props.disabled}
                hidden={hide}
            >
                {this.props.options.map(c => 
                    <option key={c.value} value={c.value} hidden={c.hidden}>
                        {c.name}
                    </option>)
                }
            </select>
        );
    }

    private onChange(e: React.SyntheticEvent<HTMLSelectElement>) {
        // First try to parse the value that was sent - if it fails, do nothing
        let parsedNumber = parseInt(e.currentTarget.value, 10);

      
            // Element in the dropdown has been selected
            // Temporarily set state to new value - this value will get overwritten when this component receives props
            // Also disable the component. When server receives change, props get updated and component is reenabled
            this.setState({
                // checked: e.currentTarget.checked,
                value: parsedNumber,
                disabled: true,
            });

            // Make call to API to set value
            this.props.editValue(this.props.name, parsedNumber.toString());
        
    }
}