import * as React from 'react';
import * as moment from 'moment';
import { StatusIndicator } from './StatusIndicator';
import { getStatusDescription, SystemStatus } from '../models/Api';

export interface Props {
    group: string;
    serialNumber: string;
    status: SystemStatus;
    heartbeat: Date;
    iotKey: string;
    isDisconnected: boolean | null;
}

export class DeviceDetailsStatusBar extends React.Component<Props> {
    constructor(props: Props) {
        super(props);
    }

    renderHeartBeat = () => {        
        // Time zone offset from API server
        let offset = moment().utcOffset();
        let heartbeat = moment(this.props.heartbeat).add(offset, 'm').toDate();
        
        let cName = '';
        this.props.isDisconnected ? cName = 'stale-data' : cName = 'good-data';

        return (
            <div className="col-md-2">
                <div className="row text-medium-blue label-medium">
                    HEARTBEAT
                </div>
                <div className={`row text-white text-medium `}>
                    <div className={`${cName}`}>
                        {moment(heartbeat).format('h:mm:ss a')}
                        <span className="ml-2">
                            {moment(heartbeat).format('M/DD/YY')}
                        </span>
                    </div>
                </div>
            </div>
        );
    }

    render() {
        return (
            <div className="content">
                <div className="d-flex">
                    <div className="col-md-2">
                        <div className="row text-medium-blue label-medium">
                            GROUP
                        </div>
                        <div className="row text-white text-medium">
                            {this.props.group}
                        </div>
                    </div>                    
                    <div className="col-md-2">
                        <div className="row text-medium-blue label-medium">
                            STATUS
                        </div>
                        <div className="row text-white text-medium">
                            <StatusIndicator
                                status={this.props.status}
                            />
                            {getStatusDescription(this.props.status)}
                        </div>
                    </div>
                    {this.renderHeartBeat()}                    
                </div>
            </div>
        );
    }
}