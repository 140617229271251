
export interface ApiResult<T> {
    success: boolean;
    error?: string;
    data?: T;
}

export interface LogInResponse {
    token: string;
    refreshToken: string;
    expires: Date;
}

export interface AccountConfirmation {
    email: string;
    password: string;
    token: string;
}

export interface Alarm {
    id: number;
    deviceId: number;
    deviceName: string;
    type: string;
    severity: string;
    active: boolean;
    raisedTime: Date;
}

export interface Command {
    deviceId: number;
    type: string;
}

export interface DeviceMapData {
    id: number;
    name: string;
    group: string;
    systemStatus: SystemStatus | null;
    irrigationSystemName: string;
    showPivotPoint: boolean;
    showLabel: boolean;
    deviceLatitude: number | null;
    deviceLongitude: number | null;
    pivotPointLatitude: number | null;
    pivotPointLongitude: number | null;
}

export interface DeviceSummary {
    id: number;
    name: string;
    group: string;
    customerId: number;
    heartBeat: Date;
    lastUpdated: Date;
    isDisabled: boolean;
    systemStatus: SystemStatus | null;
    rateTimeTarget: Status | null;
    rateAreaTarget: Status | null;
    rateTimeStatus: Status | null;
    rateAreaStatus: Status | null;
    total1: Total | null;
    total2: Total | null;
    latitude: number | null;
    longitude: number | null;
    oldHeartbeat: boolean | null;
    mode: Mode;

    isDisconnected: boolean | null;
    connectedTime: Date | null;
    disconnectedTime: Date | null;
    // Instead of oldHeartbeat we want to check connectedTime > disconnectedTime
}

export function GetEmptyDevice (id: number = 0) {
    return {
                id: id,
                iotKey: '',
                heartbeat: new Date(),
                version: '',
                hubDeviceId: '',
                name: '-',
                group: '',
                oldHeartbeat: false,
                lastUpdated: new Date(),
                versionMajor: 0,
                versionMinor: 0,
                versionRev: 0,
                hmiVersion: '',
                customerId: 0,
                isDisabled: false,
                connectedTime: null,
                disconnectedTime: null,
                isDisconnected: null
    };
}
export interface Device {
    id: number;
    iotKey: string;
    heartbeat: Date;
    lastUpdated: Date;
    version: string;
    versionMajor: number;
    versionMinor: number;
    versionRev: number;
    hmiVersion: string;
    hubDeviceId: string;
    name: string;
    group: string;
    customerId: number;
    isDisabled: boolean;
    oldHeartbeat: boolean | null;

    isDisconnected: boolean | null;
    connectedTime: Date | null;
    disconnectedTime: Date | null;
}

export interface Status {
    value: number;
    units: string;
}

export interface Total {
    status: Status;
    date: Date;
}

export interface Version {
    major: number;
    minor: number;
    revision: number;
}

export enum SystemStatus {
    // V1 and above
    off = 0,
    running = 1, // Running (no issue)
    fault = 2, // Stopped (with faults)
    disabled = 3, 
    // V3 and above
    idle_timer = 4, 
    idle_pivotinterlock = 5,
    idle_waterflow = 6,
    stopped_alarmed = 7, // Both 7 and 8 display equivalently as STOPPED - With Alarms
    stopped_alarmed2 = 8,
    running_alarmed = 9,
    unknown = 98, // Web-Only
    subscription_disabled = 99, // Web-Only
}

export const getStatusIcon = (deviceStatus: SystemStatus): any => {
    switch (deviceStatus) {
        case SystemStatus.off : 
            return require('../images/statuses/off.svg');
        case SystemStatus.running : 
            return require('../images/statuses/running.svg');
        case SystemStatus.fault : 
            return require('../images/statuses/fault.svg');
        case SystemStatus.disabled : 
            return require('../images/statuses/disabled.svg');
        case SystemStatus.idle_timer : 
            return require('../images/statuses/idle_timer.svg');
        case SystemStatus.idle_pivotinterlock : 
            return require('../images/statuses/idle_pivotinterlock.svg');
        case SystemStatus.idle_waterflow : 
            return require('../images/statuses/idle_waterflow.svg');
        case SystemStatus.stopped_alarmed : 
            return require('../images/statuses/stopped_alarmed.svg');
        case SystemStatus.stopped_alarmed2 : 
            return require('../images/statuses/stopped_alarmed.svg');
        case SystemStatus.running_alarmed : 
            return require('../images/statuses/running_alarmed.svg');
        case SystemStatus.unknown : 
            return require('../images/statuses/unknown.svg');
        case SystemStatus.subscription_disabled : 
            return require('../images/statuses/subscription_disabled.svg');
        default:
            return require('../images/statuses/unknown.svg');
    }
};

export const getStatusDescription = (deviceStatus: SystemStatus): any => {
    switch (deviceStatus) {
        case SystemStatus.off : 
            return 'OFF';
        case SystemStatus.running : 
            return 'RUNNING';
        case SystemStatus.fault : 
            return 'STOPPED - With Faults';
        case SystemStatus.disabled : 
            return 'DISABLED';
        case SystemStatus.idle_timer : 
            return 'IDLE - Timer';
        case SystemStatus.idle_pivotinterlock : 
            return 'IDLE - Water Flow';
        case SystemStatus.idle_waterflow : 
            return 'IDLE - Pivot Interlock';
        case SystemStatus.stopped_alarmed : 
            return 'STOPPED - With Alarms';
        case SystemStatus.stopped_alarmed2 : 
            return 'STOPPED - With Alarms';
        case SystemStatus.running_alarmed : 
            return 'RUNNING - With Alarms';
        case SystemStatus.unknown : 
            return 'UNKNOWN';
        case SystemStatus.subscription_disabled : 
            return 'SUBSCRIPTION DISABLED';
        default:
            return 'UNKNOWN';
    }
};

export enum Mode {
    empty_mode = 0,
    manual_time = 1001, // Control application rate vs time (Gallons per Hours)
    prop_center = 1002,
    prop_corner = 1003,
    prop_drip = 1004,
    prop_golf = 1005,
    uVRF = 1006,
    vri_vrf = 1007,
    speed_sense = 1008,
    prop_basic = 1009,
    manual_area = 1010 // Control application rate vs area (Gallons per Acre)
}

export enum AlarmLevel {
    alarm = 'Alarm',
    warning = 'Warning',
    notification = 'Notification'
}

export function GetEmptyDeviceStatus() {
    return {
                systemStatus: SystemStatus.unknown,
                rateTime: {
                    value: 0,
                    units: ''
                },
                rateArea: {
                    value: 0,
                    units: ''
                },
                dischargePressure: {
                    value: 0,
                    units: ''
                },
                tankLevel: {
                    value: 0, 
                    units: ''
                },
                irrigationFlow: {
                    value: 0,
                    units: ''
                },
                pumpKnobSetting: {
                    value: 0,
                    units: ''
                },
                pumpKnobMax: {
                    value: 0,
                    units: ''
                },
                chemicalTotal1: {
                    status: {
                        value: 0,
                        units: ''
                    },
                    date: new Date()
                },
                chemicalTotal2: {
                    status: {
                        value: 0,
                        units: ''
                    },
                    date: new Date()
                },
                waterTotal1: {
                    status: {
                        value: 0,
                        units: ''
                    },
                    date: new Date()
                },
                waterTotal2: {
                    status: {
                        value: 0,
                        units: ''
                    },
                    date: new Date()
                },
                runtimeTotal1: {
                    status: {
                        value: 0,
                        units: ''
                    },
                    date: new Date()
                },
                runtimeTotal2: {
                    status: {
                        value: 0,
                        units: ''
                    },
                    date: new Date()
                },
                currentField: '',
                pivotAngle: 0,
                pivotSpeed: 0,
            };
}
export interface DeviceStatus {
    systemStatus: SystemStatus;
    rateTime: Status;
    rateArea: Status;
    dischargePressure: Status;
    tankLevel: Status;
    irrigationFlow: Status;
    pumpKnobSetting: Status;
    pumpKnobMax: Status;
    chemicalTotal1: Total;
    chemicalTotal2: Total;
    waterTotal1: Total;
    waterTotal2: Total;
    runtimeTotal1: Total;
    runtimeTotal2: Total;
    currentField: String;
    pivotAngle: number;
    pivotSpeed: number; // Speed as %
}

export function GetEmptyDeviceConfig() {
    return {
                id: 0,
                friendlyName: '-',
                group: '',
                latitude: 0,
                longitude: 0,
            
                injectionPump: false,
                stopInjectionPump: false,
                mode: 0,
                rateTime: {value: 0, units: ''},
                calculatedRateTime: {value: 0, units: ''},
                rateArea: {value: 0, units: ''},
                irrigationCycleTime: 0,
                pivotKill: false,
            
                irrigationSystemType: 0,
                irrigationSystemName: '',
                pivotPointLatitude: 40.752,
                pivotPointLongitude: -73.9885,
                irrigationLrduLength: {value: 0, units: ''},
                maximumWateringLength: {value: 0, units: ''},
                maxFlowAtLrdu: {value: 0, units: ''},
                maxFlowAtMaxWateringLength: {value: 0, units: ''},
                minimumRevolutionTime: {value: 0, units: ''},
                userEnteredArea: {value: 0, units: ''},
                degreesTreated: {value: 0, units: ''},
                pumpStartWaterFlow: {value: 0, units: ''},
                pumpStopWaterFlow: {value: 0, units: ''},
                pumpStartDelay: 0,
                pumpStopDelay: 0,

                b5volt: false,
                flowCompensation: false,
                maxFlow: {value: 0, units: ''},
                setPumpAt: {value: 0, units: '%'},

                app7Day: {value: 0, units: ''},
                treatedAcres: {value: 0, units: ''},
                event7Day: 0,
                eventHours: 0,

                irrigatedArea: {value: 0, units: ''},

                waterFlowSensor: false,
                waterFlowSensorName: '',
                chemicalFlowSensor: false,
                chemicalFlowSensorName: '',
            
                pivotKillRelay: false,
                chemicalValveRelay: false,
            
                tankLevelDerived: false,
            
                weather: false,

                maxCyclePivot: 0
            }; 
}
export interface DeviceConfiguration {
    id: number;
    friendlyName: string;
    group: string;
    latitude: number;
    longitude: number;

    injectionPump: boolean;
    stopInjectionPump: boolean;
    mode: number;
    rateTime: Status;
    calculatedRateTime: Status;
    rateArea: Status;
    irrigationCycleTime: number;
    pivotKill: boolean;

    irrigationSystemType: number;
    irrigationSystemName: string;
    pivotPointLatitude: number;
    pivotPointLongitude: number;
    irrigationLrduLength: Status;
    maximumWateringLength: Status;
    maxFlowAtLrdu: Status;
    maxFlowAtMaxWateringLength: Status;
    minimumRevolutionTime: Status;
    userEnteredArea: Status;
    degreesTreated: Status;
    pumpStartWaterFlow: Status;
    pumpStopWaterFlow: Status;
    pumpStartDelay: number;
    pumpStopDelay: number;

    // VRI
    b5volt: boolean;
    flowCompensation: boolean;
    maxFlow: Status;
    setPumpAt: Status;

    // Golf
    app7Day: Status;
    treatedAcres: Status;
    event7Day: number;
    eventHours: number;

    // Drip
    irrigatedArea: Status;

    // UVRF
    maxCyclePivot: number; // Percent

    waterFlowSensor: boolean;
    waterFlowSensorName: string;
    chemicalFlowSensor: boolean;
    chemicalFlowSensorName: string;

    pivotKillRelay: boolean;
    chemicalValveRelay: boolean;

    tankLevelDerived: boolean;

    weather: boolean;
}

export interface HistoricalData {
    timestamp: Date;
    injectionPumpTarget: number;
    injectionPumpActual: number;
    waterFlow: number;
    tankLevel: number;
    pressure: number;
}

export interface DeviceHistory {
    start: Date;
    end: Date;
    aggregation: string;
    injectionPumpUnits: string;
    waterFlowUnits: string;
    tankLevelUnits: string;
    pressureUnits: string;
    data: HistoricalData[];
}

export interface DeviceRecording {
    // TODO: Update this based on actual device data structure defined by API
    date: Date;
    code: string;
    rate: number;
    level: number;
    applied: number;
    windSpeed: number;
    windDirection: string;
    temperature: number;
    user: string;
}

export interface DeviceEvent {
    id: number;
    timestamp: Date;
    eventCode: string;
    details: string;
    user: string;
}

export interface AlarmRecipient {
    id: string;
    name: string;
}

export interface AlarmSetpoint {
    deviceId: number;
    alarmSetpointType: number;
    units: string;
    users: AlarmRecipient[];
    active: boolean;
    killPump: boolean;
    killPivot: boolean;
    setpoint: number;
    totalizerId: number | null;
    delaySec: number | null;
}

export interface AlarmComponent {
    alarmDisplayName: string;
    alarmApiName: string;
    alarmToggle: boolean;
    setpointValue: number;
    setpointUnit: string;
    notifyList: string[];
    deviceId: number;
}

export interface AccountInfo {
    id: string;
    email: string;
    firstName: string;
    lastName: string;
    phoneNumber: string;
    licenseNumber: string;
    licenseEffectiveDate: string;
    customerId: number;
    customerName: string;
    isDeactivated: boolean;
    roles: string[];
    password: string;
}

export interface CustomerInfo {
    id: number;
    name: string;
}

export interface UnitSettings {
    id: number;
    userId: string;
    chemicalFlowUnits: number;
    irrigationFlowUnits: number;
    chemicalTotalUnits: number;
    irrigationTotalUnits: number;
    areaUnits: number;
    lengthUnits: number;
    applicationRateUnits: number;
    pressureUnits: number;
}

export interface UnitOption {
    valueDisplayName: string;
    valueApiName: string;
    unitList: string[];
    activeUnit: string; 
}

export interface ChangePassword {
    email: string;
    currentPassword: string;
    newPassword: string;
    confirmNewPassword: string;
}

export interface LoginAttempt {
    email: string;
    password: string;
}

export interface SetPassword {
    email: string;
    password: string;
    confirmPassword: string;
    code: string;
}

export interface PasswordResponse {
    message: string;
}

export interface DropdownList {
    value: number;
    name: string;
}

export interface DeviceManagement {
    name: string;
    hubDeviceId: string;
    deviceId: number;
    group: string;
    version: string; 
    versionMajor: number;
    versionMinor: number;
    versionRev: number;
    customerId: number;
    customerName: string;
    licenses: DeviceLicense[];
    isDisabled: boolean;
}

export interface DeviceLicense {
    id: number;
    name: string;
    isDisabled: boolean;
}

export interface UVRFConfig {
    deviceId: number;
    uvrfTable: UVRFEntry[];
}

export interface UVRFEntry {
    index: number;
    startDegree: number;
    stopDegree: number;
    applicationRate: number;
}