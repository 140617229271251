import * as React from 'react';
import { AlarmSetpoint, AccountInfo } from '../models/Api';
import { ConfigurationToggleAsync } from './ConfigurationToggleAsync';
import 'react-toggle/style.css';
import { EditSimpleAlarmSetpointModal } from 'src/components/EditSimpleAlarmSetpointModal';
// const settingsIcon = require('../images/settings.png');

export interface Props {
    alarmSetpoint?: AlarmSetpoint;
    setpointName: string;
    editAlarmSetpoint: (alarmSetpoint: AlarmSetpoint) => Promise<any>;
    disableDivider?: boolean;
    users: AccountInfo[];
    isDisabled: boolean;
}

interface State {
    editSetpointModalOpen: boolean;
}

export class DeviceSimpleAlarmComponent extends React.Component<Props, State> {
    constructor(props: Props) {
        super(props);

        this.state = {
            editSetpointModalOpen: false
        };
        
        this.toggleAlarm = this.toggleAlarm.bind(this);
        this.closeModal = this.closeModal.bind(this);
    }

    render() {
        if (this.props.alarmSetpoint === undefined) {
            return (<div/>);
        } else {
            let className = 'row d-flex align-items-center mb-xl-0 mb-4 list-divider';
            if (this.props.disableDivider) {
                className = 'row d-flex align-items-center mb-xl-0 mb-4';
            }
            return (
                <div className={className}>
                    <EditSimpleAlarmSetpointModal
                        setpoint={this.props.alarmSetpoint}
                        editSetpoint={this.props.editAlarmSetpoint}
                        isModalOpen={this.state.editSetpointModalOpen}
                        closeModal={this.closeModal}
                        users={this.props.users}
                    />
                    <div className="col-7 col-xl-2 text-medium-blue">
                        {this.props.setpointName}
                    </div>
                    <div className="col-5 col-xl-1">
                        <ConfigurationToggleAsync
                            checked={this.props.alarmSetpoint.active}
                            name="active"
                            editValue={this.toggleAlarm}
                            onLabel="On"
                            offLabel="Off"
                            disabled={this.props.isDisabled}
                        />
                    </div>
                    <div className="col-12 col-xl-3"/>

                    <div className="col-7 d-flex d-xl-none"/>
                    <div className="col-5 col-xl-1"/>

                    <div className="col-7 d-flex d-xl-none"/>
                    <div className="col-5 col-xl-1"/>

                    <div className="col-5 d-flex d-xl-none">
                        <div className="text-medium-blue">
                            NOTIFY
                        </div>
                    </div>
                    <div className="col-7 col-xl-3">
                        {this.props.alarmSetpoint.users.length <= 0 ? 'N/A' :
                            this.props.alarmSetpoint.users.map(u => u.name).join(', ')
                        }
                    </div>

                    <div className="col-7 d-flex d-xl-none">
                        <div className="text-medium-blue">
                            EDIT
                        </div>
                    </div>
                    <div className="col-5 col-xl-1 d-flex justify-content-end ">
                        <button 
                            className="button-status"
                            onClick={() => this.openModal()}
                        >
                            <div className="text-box row align-items-center">
                                <div>
                                    CHANGE ALARM SETTINGS
                                </div>
                            </div>
                        </button>
                    </div>                    
                </div>
            );
        }
    }

    private toggleAlarm(name: string, value: string): Promise<void> {
        if (this.props.alarmSetpoint === undefined) {
            // No object passed in - exit
            return Promise.resolve();
        }

        // Grab a copy of the input alarm setpoint
        let alarmSetpoint = this.props.alarmSetpoint;

        // Convert from string to boolean and set it in our setpoint 
        alarmSetpoint = {
            ...alarmSetpoint,
            [name]: value === 'true'
        };

        // Check to see if pivot kill is enabled but pivot kill is disabled
        if (name === 'killPivot' && value === 'true') {
            alarmSetpoint.killPump = true;
        }

        if (name === 'killPump' && value !== 'true') {
            alarmSetpoint.killPivot = false;
        }

        // Call method to edit this setpoint
        return this.props.editAlarmSetpoint(alarmSetpoint);
    }

    private openModal() {
        this.setState({
            editSetpointModalOpen: true
        });
    }

    private closeModal() {
        this.setState({
            editSetpointModalOpen: false
        });
    }
}