import * as React from 'react';
import { ConfirmDeleteModal } from 'src/components/modal-confirmDelete';
import * as devicesApi from 'src/api/Devices';
import ReactTable from 'react-table';
import 'react-table/react-table.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPencilAlt, faTrashAlt, faExternalLinkAlt } from '@fortawesome/free-solid-svg-icons';
import {matchSorter} from 'match-sorter';
import 'src/styles/TableStyles.css';
import { columnHeaderStyle, dataStyle } from 'src/styles/TableFormats';
import { CustomerInfo, DeviceManagement } from 'src/models/Api';
import { AddDeviceModal } from 'src/components/AddDeviceModal';
import { NavLink } from 'react-router-dom';
import { EditDeviceModal } from 'src/components/modal-edit-device';

interface State {
  devicesData: DeviceManagement[];
  customers: CustomerInfo[];

  editData: DeviceManagement;

  editModalOpen: boolean;
  addModalOpen: boolean;
  deleteModalOpen: boolean;
}

export interface Props {
}

export class DeviceAdminPage extends React.Component<Props, State> {
  private numRequests: number;

  constructor(props: Props) {
    super(props);
    this.numRequests = 0;
    this.state = {
      editData: this.getDefaultDeviceInfo(),
      devicesData: [this.getDefaultDeviceInfo()],
      addModalOpen: false,
      editModalOpen: false,
      deleteModalOpen: false,
      customers: [],
    };
    this.deleteDevice = this.deleteDevice.bind(this);
  }

  componentDidMount() {
    this.getDevMgmtApiCall();
  }

  render() {
    return (
      <div className="subpage-standard">
        <div className="row component-standard">
        
          <div className="container-fluid">
            <div className="row">
              <div className="col-6 label-large">
                DEVICES
              </div>
              <div className="col d-flex justify-content-end align-items-center">
                <button
                  className="btn btn-outline-secondary m-2 justify-content-right button-standard"
                  onClick={this.handleAdd}
                >
                  + ADD DEVICE
                </button>
                <AddDeviceModal
                  isModalOpen={this.state.addModalOpen}
                  closeModal={this.closeModal}
                />
              </div>
            </div>
            <br/>
            <div className="ReactTable">
              <ReactTable
                data={this.state.devicesData}
                className="-highlight"
                defaultSorted={[{id: 'customerName', desc: false }]}
                // showPagination={false}
                minRows={10}
                loading={this.state.devicesData[0].name === ''}
                filterable={true}
                defaultFilterMethod={(filter, row) => (matchSorter([row[filter.id]], filter.value).length !== 0)}
                // COLUMNS ////////////////////////////////
                columns={
                [
                  {
                    Header: 'CUSTOMER',
                    accessor: 'customerName',
                    headerStyle: columnHeaderStyle,
                    style: dataStyle,
                    resizable: true
                  }, {
                    Header: 'GROUP',
                    accessor: 'group',
                    headerStyle: columnHeaderStyle,
                    style: dataStyle,
                  }, {
                    Header: 'NAME',
                    accessor: 'name',
                    headerStyle: columnHeaderStyle,
                    style: dataStyle,
                  }, {
                    Header: 'SERIAL',
                    accessor: 'hubDeviceId',
                    headerStyle: columnHeaderStyle,
                    style: dataStyle,
                  }, {
                    Header: 'VERSION',
                    accessor: 'version',
                    headerStyle: columnHeaderStyle,
                    style: dataStyle,
                    width: 100,
                  }, {
                    Header: 'DISABLED',
                    accessor: 'isDisabled',
                    headerStyle: columnHeaderStyle,
                    style: dataStyle,
                    width: 100,
                    Cell: ({ value }) => {
                      if ( value === true ) {
                        return <div> DISABLED </div>;
                      } else {
                        return <div> ACTIVE </div>;
                      }
                    }
                  }, {
                    Header: 'MANAGE',
                    headerStyle: columnHeaderStyle,
                    width: 130,
                    sortable: false,
                    filterable: false,
                    Cell: cell => (
                      <div>
                        <NavLink 
                          className="iconButton"
                          to={'/device/' + cell.original.deviceId}
                        >
                          <FontAwesomeIcon icon={faExternalLinkAlt} />
                        </NavLink>
                        <button 
                          className="iconButton ml-1"
                          onClick={() => {
                            this.setState({
                              editModalOpen: true,
                              editData: cell.original,
                            });
                          }}
                        >
                          <FontAwesomeIcon icon={faPencilAlt} />
                        </button>
                        <button 
                          className="iconButton"
                          onClick={() => {
                            this.setState({
                              editData: cell.original,
                              deleteModalOpen: true,
                            });
                          }}
                        >
                          <FontAwesomeIcon icon={faTrashAlt} />
                        </button>
                      </div>
                    )
                  }
                ]}
                // COLUMNS END //////////////////
              />
              <React.Fragment>
                <EditDeviceModal
                  isModalOpen={this.state.editModalOpen}
                  closeModal={this.closeModal}
                  currentInfo={this.state.editData}
                />
                <ConfirmDeleteModal
                  isModalOpen={this.state.deleteModalOpen}
                  closeModal={this.closeModal}
                  confirmInfo={this.state.editData.name}
                  deleteInfo={this.deleteDevice}
                />
              </React.Fragment>
            </div>
          </div>
        </div>
      </div>
    );
  }

  private getDefaultDeviceInfo = (): DeviceManagement => {
    return {
      name: '',
      hubDeviceId: '',
      deviceId: 0,
      group: '',
      version: '',
      versionMajor: 0,
      versionMinor: 0,
      versionRev: 0,
      customerId: 0,
      customerName: 'loading...',
      licenses: [],
      isDisabled: false,
  };
}

private getDevMgmtApiCall = () => {
  if (this.numRequests === 0) {
    devicesApi.getDeviceManagement().then((result) => {
      if (result.success && result.data) {
        this.setState({
                    ...this.state,
                     devicesData: result.data!
                  });
      }
    });
  }
}

  private async deleteDevice(): Promise<any> {
    // Increment number of requests so that we don't update local state until this request resolves
    this.numRequests = this.numRequests + 1;
    console.log('Deleting device:');
    console.log(this.state.editData);
    // Actually call the delete API method
    await devicesApi.deleteDevice(this.state.editData.deviceId);

    // Decrement number of requests since this one has resolved
    this.numRequests = this.numRequests - 1;
    console.log('deletion complete');
    // Get any updates that occurred to display on UI
    this.getDevMgmtApiCall();
  }

  private handleAdd = () => {
    this.setState({
      addModalOpen: true
    });
  }

  private closeModal = () => {
    this.setState({
      ...this.state,
      addModalOpen: false,
      editModalOpen: false,
      deleteModalOpen: false
    });
    this.getDevMgmtApiCall();
  }

}
