import * as React from 'react';
import { updateTankLevel } from 'src/api/DeviceConfiguration';
import { postEvent } from 'src/api/Events';
import { DeviceStatus } from '../models/Api';
import '../styles/DeviceOverview.css';
import { EditDeviceControlModal } from './EditDeviceControlModal';
import { ModalProps } from './SystemControl';
import { UpdateTankLevel } from './UpdateTankLevel';

interface State {
    isModalOpen: boolean;
    modalProps: ModalProps;
}

export interface Props {
    deviceId: number;
    deviceStatus: DeviceStatus;
    isDisconnected: boolean | null;
    isDisabled: boolean | null;
    tankLevelIsDerived: boolean;
}

export class TankSettings extends React.Component<Props, State> {

    constructor(props: Props) {
        super(props);

        this.state = {
            isModalOpen: false,
            modalProps: {
                title: '',
                value: 0,
                currentTitleLabel: '',
                editTitleLabel: '',
                units: '',
                propName: '',
            },
        };
    }

    changeDisabled = () => {
        return (this.props.isDisabled || this.props.isDisconnected !== false || !this.props.tankLevelIsDerived);
    }

    render() {
        let status = { ...this.props.deviceStatus };
        let cName = this.changeDisabled() ? ' details-stale-data' : '';
        
        // The tank level display can change depending on if the device has a tank level sensor.
        // This configures how to display it, and is rendered below.
        let tankLevelLabel = this.props.tankLevelIsDerived ? 'TANK LEVEL (DERIVED)' : 'TANK LEVEL'; 

        return (

            <div className={'component-standard w-100' + cName}>

                <EditDeviceControlModal
                    isModalOpen={this.state.isModalOpen}
                    title={this.state.modalProps.title}
                    units={this.state.modalProps.units}
                    currentTitleLabel={this.state.modalProps.currentTitleLabel}
                    editTitleLabel={this.state.modalProps.editTitleLabel}
                    value={this.state.modalProps.value}
                    closeModal={this.closeEditModal}
                    confirmValueChange={(v) => this.handleValueChange(v)}
                />

                {/* Header */}
                <div className="row">
                    <div className="col-10 label-large text-dark-blue">
                        TANK SETTINGS
                    </div>
                </div>

                {/* Tank Level */}
                <UpdateTankLevel
                    fieldName={tankLevelLabel}
                    apiPropName="TankLevel"
                    value={status.tankLevel.value}
                    units={status.tankLevel.units}
                    tankLevelIsDerived={this.props.tankLevelIsDerived}
                    openEditModal={(modalProps) => this.openEditModal(modalProps)}
                    decimalPlaces={1}
                    isDisabled={this.props.isDisabled 
                                || this.props.isDisconnected !== false
                                || !this.props.tankLevelIsDerived}
                />
            </div>

        );
    }

    private openEditModal = (props: ModalProps) => {
        this.setState({
            isModalOpen: true,
            modalProps: props
        });
    }

    private closeEditModal = () => {
        this.setState({ isModalOpen: false });
    }

    private handleValueChange = (newValue: number) => {
        updateTankLevel(this.props.deviceId, newValue);
        postEvent(
            this.props.deviceId,
            {
                eventCode: 'Command',
                details: `TankLevelAdd set to ${newValue}`,
                id: 0,
                timestamp: new Date( Date.now()),
                user: 'a',
            });

        this.setState({
            isModalOpen: false,
            modalProps: {
                title: '',
                value: 0,
                currentTitleLabel: '',
                editTitleLabel: '',
                units: '',
                propName: '',
            }
        });
    }

}
