import * as React from 'react';
import { DeviceConfiguration, Device } from '../models/Api';
import { InlineInputEdit } from './InlineInputEdit';

interface State {
}

export interface Props {
    deviceConfig: DeviceConfiguration;
    deviceInfo: Device;
    editConfigValue: (name: string, value: string) => void;
}

export class DeviceSettingsGeneral extends React.Component<Props, State> {

    constructor(props: Props) {
        super(props);
    }

    render() {
        return (
            <div className="component-standard w-100">
                {/* Header */}
                <div className="row">
                    <div className="col-12 label-large text-dark-blue">
                        GENERAL
                    </div>
                </div>
                <div className="row flex-container list-divider">
                    <div className="col-xl-2 col-12 text-medium-blue">
                        NAME
                    </div>
                    <div className="col-xl-8 col-12">
                        <InlineInputEdit
                            name="FriendlyName"
                            value={this.props.deviceConfig.friendlyName}
                            editValue={this.props.editConfigValue}
                            inputClassname="textbox-style"
                        />
                    </div>
                </div>
                <div className="row flex-container list-divider">
                    <div className="col-xl-2 col-12 text-medium-blue">
                        REFLEX CONNECT LOCATION
                    </div>
                    <div className="col-xl-3 col-12">
                        <span className="mr-4 align-middle">
                            Latitude&nbsp;
                        </span>
                        <InlineInputEdit
                            name="Latitude"
                            value={this.props.deviceConfig.latitude.toFixed(6)}
                            editValue={this.props.editConfigValue}
                            inputClassname="textbox-style"
                        />
                    </div>
                    <div className="col-xl-3 col-12">
                        <span className="mr-3 align-middle">
                            Longitude
                        </span>
                        <InlineInputEdit
                            name="Longitude"
                            value={this.props.deviceConfig.longitude.toFixed(6)}
                            editValue={this.props.editConfigValue}
                            inputClassname="textbox-style"
                        />
                    </div>                        
                </div> 
                <div className="row flex-container list-divider">
                    <div className="col-xl-2 col-12 text-medium-blue">
                        GROUP
                    </div>
                    <div className="col-xl-2 col-12">
                        <InlineInputEdit
                            name="Group"
                            value={this.props.deviceConfig.group}
                            editValue={this.props.editConfigValue}
                            inputClassname="textbox-style"
                        />
                    </div>
                </div>  
                <div className="row mt-4">
                    <div className="col-12 label-large text-dark-blue">
                        ABOUT
                    </div>
                </div>
                <div className="row flex-container list-divider">
                    <div className="col-xl-2 col-12 text-medium-blue">
                        SERIAL NUMBER
                    </div>
                    <div className="col-xl-8 col-12">
                        {this.props.deviceInfo.hubDeviceId}
                    </div>
                </div>  
                <div className="row flex-container list-divider">
                    <div className="col-xl-2 col-12 text-medium-blue">
                        CONNECT PASSWORD
                    </div>
                    <div className="col-xl-8 col-12 key-display">
                        {this.props.deviceInfo.iotKey}
                    </div>
                </div>
                {this.props.deviceInfo.versionMajor > 0 && 
                <div>
                    <div className="row flex-container list-divider">
                        <div className="col-xl-2 col-12 text-medium-blue">
                            PLC VERSION
                        </div>
                        <div className="col-xl-8 col-12">
                            {this.props.deviceInfo.version}
                        </div>
                    </div>  
                    <div className="row flex-container list-divider">
                        <div className="col-xl-2 col-12 text-medium-blue">
                            HMI VERSION
                        </div>
                        <div className="col-xl-8 col-12">
                            {this.props.deviceInfo.hmiVersion}
                        </div>
                    </div>
                </div>}
                {/* <div className="row flex-container list-divider">
                    <div className="col-xl-2 col-12 text-medium-blue">
                        WEBSITE VERSION
                    </div>
                    <div className="col-xl-8 col-12">
                        1.00.00
                    </div>
                </div>                   */}
            </div>
        );
    }
}