import * as React from 'react';
import { withRouter, RouteComponentProps } from 'react-router-dom';
import * as alarmApi from '../api/Alarms';
import { Alarm } from '../models/Api';
import Footer from '../components/Footer';
import Header from '../components/Header';
import '../styles/DevicesPage.css';
import { AlarmDisplay } from '../components/AlarmDisplay';

interface State {
    alarms: Alarm[];
}

export interface Props extends RouteComponentProps<{id: string}> {

}

class AlarmsPage extends React.Component<Props, State> {
    private alarmReqTimerId: number | null = null;
    constructor(props: Props) {
        super(props);

        this.state = {
            alarms: [],
        };

        this.getAlarms = this.getAlarms.bind(this);
    }

    componentDidMount() {
        this.getAlarms();
        window.scrollTo(0, 0);
    }

    componentWillUnmount() {
        if (this.alarmReqTimerId !== null) {
            window.clearInterval(this.alarmReqTimerId);
        }
    }

    render() {
        let alarms = this.state.alarms;

        return (
            <div className="flex-root">
                <Header/>
                    <div className="devices-page-header">
                        <label className="content text-medium-blue label-very-large">
                            ALARMS
                        </label>
                    </div>
                <AlarmDisplay
                    alarms={alarms}
                />
                <Footer/>
            </div>
        );
    }

    private getAlarms() {
        if (this.alarmReqTimerId !== null) {
            window.clearInterval(this.alarmReqTimerId);
            this.alarmReqTimerId = null;
        }

        alarmApi.getAlarms().then((result) => {
            if (this.alarmReqTimerId === null) {
                this.alarmReqTimerId = window.setInterval(this.getAlarms, 15000);
            }            
            
            if (result.success) {
                // Device found - set state appropriately
                this.setState({
                    ...this.state,
                    alarms: result.data!
                });
            } else {
                // Device not found
                
                // this.setState({
                //     isFound: false
                // });
            }
        });
    }
}

export default withRouter(AlarmsPage);