import { ApiResult, DeviceSummary, Device, DeviceManagement } from '../models/Api';
import { httpRequest, Urls } from './Shared';

const DEVICES_URL = '/Devices';
const MANAGEMENT_URL = '/management';

export const getDevices = (): Promise<ApiResult<DeviceSummary[]>> => {
    return httpRequest(`${Urls.BASE_URL}${DEVICES_URL}`,
                       'get'
                        );
};

export const getDevice = (deviceId: number): Promise<ApiResult<Device>> => {
    return httpRequest(`${Urls.BASE_URL}${DEVICES_URL}/${deviceId}`,
                       'get'
                        );
};

export const addDevice = (name: string, group: string, customerId: number): Promise<ApiResult<Device>> => {
    return httpRequest(`${Urls.BASE_URL}${DEVICES_URL}`,
                       'post',
                       {
                           'name': name,
                           'group': group,
                           'customerId': customerId
                       }
                       );
};

export const deleteDevice = (deviceId: number): Promise<ApiResult<Device>> => {
    return httpRequest(`${Urls.BASE_URL}${DEVICES_URL}/${deviceId}`,
                       'delete'
                        );
};

export const getDeviceManagement = (): Promise<ApiResult<DeviceManagement[]>> => {
    return httpRequest(`${Urls.BASE_URL}${DEVICES_URL}${MANAGEMENT_URL}`,
                       'get'
                        );
};

export const putDeviceManagement = (deviceMgmt: DeviceManagement): Promise<ApiResult<DeviceManagement>> => {
    return httpRequest(`${Urls.BASE_URL}${DEVICES_URL}${MANAGEMENT_URL}`,
                       'post', 
                       deviceMgmt
                        );
};