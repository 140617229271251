import * as React from 'react';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import { SystemControl } from 'src/components/SystemControl';
import { Device, DeviceConfiguration, DeviceStatus, SystemStatus, Version, Mode, UVRFEntry } from '../../models/Api';
import { LocationComponent } from '../../components/LocationComponent';
import { DetailedStatus } from 'src/components/DetailedStatus';
import { OverviewTotals } from '../../components/OverviewTotals';
import { ActiveAlarms } from 'src/components/ActiveAlarms';
import { HistoricalDataComponent } from 'src/components/HistoricalDataComponent';
import { UVRFChart } from 'src/components/UVRF Components/UVRFChart';
import { UVRFTable, emptyTableEntry } from 'src/components/UVRF Components/UVRFTable';
import { getUVRFConfiguration } from 'src/api/DeviceConfiguration';
import { TankSettings } from 'src/components/TankSettings';

interface State {
    isOffline: boolean;
    UVRFTable: UVRFEntry[];
}

function detectmob() {
    if (window.innerWidth <= 900) {
        return true;
    } else {
        return false;
    }
}

export interface Props extends RouteComponentProps<{ id: string }> {
    device: Device;
    deviceConfig: DeviceConfiguration;
    deviceStatus: DeviceStatus;
    version: Version;
    editConfigValue: (name: string, value: string) => void;
}

class DeviceOverviewSubPage extends React.Component<Props, State> {

    constructor(props: Props) {
        super(props);

        this.state = {
            isOffline: false,
            UVRFTable: emptyTableEntry(),
        };
    }

    componentDidMount() {
        if (this.props.deviceConfig.mode === Mode.uVRF) {
            this.getUVRFConfig(this.props.device.id);
        }
    }
    componentDidUpdate( prevProps: Props ) {
        if (prevProps.deviceConfig.mode === Mode.uVRF 
            && (prevProps.deviceStatus.pivotAngle !== this.props.deviceStatus.pivotAngle 
            || this.state.UVRFTable.length <= 1)) {
            this.getUVRFConfig(this.props.device.id);
        }
    }
        
    render() {
        // Set map markers to grey if no heartbeat
        var locationDeviceStatus = this.props.deviceStatus;
        if (this.props.device.isDisconnected) {
            locationDeviceStatus.systemStatus = SystemStatus.unknown;
        }

        // re-organize if viewing on mobile
        const isMobile = detectmob();
        if (isMobile) {
            return (
                <div className="subpage-standard">
                    {this.props.device.isDisabled && 
                    <div className="row mb-4 mr-lg-2">
                        <div className="component-standard-max300 w-100">
                            {/* Header */}
                            <div className="row">
                                <div className="col label-large text-dark-blue">
                                    DEVICE SUBSCRIPTION DISABLED
                                </div>
                            </div>
                            <div className="row mb-3 mb-sm-0">
                                <div className="text-medium-blue">
                                    System is disabled. Please contact your Agri-Inject 
                                    administrator to have the system enabled.
                                </div>
                            </div>
                        </div>
                    </div>
                    }

                    <div className="row mb-4">
                        <ActiveAlarms deviceId={Number(this.props.match.params.id)} />
                    </div>
                    <div className="row mb-4 mr-lg-2">
                        <DetailedStatus
                            deviceId={Number(this.props.match.params.id)}
                            deviceStatus={this.props.deviceStatus}
                            deviceConfiguration={this.props.deviceConfig}
                            heartbeat={this.props.device.heartbeat}
                            isDisconnected={this.props.device.isDisconnected}
                            tankLevelIsDerived={this.props.deviceConfig.tankLevelDerived}
                        />
                    </div>
                    <div className="row mb-4">
                        <SystemControl
                            version={this.props.version}
                            deviceConfig={this.props.deviceConfig}
                            deviceStatus={this.props.deviceStatus}
                            editConfigValue={this.props.editConfigValue}
                            isDisconnected={this.props.device.isDisconnected}
                            isDisabled={this.props.device.isDisabled}
                            updateUVRF={() => this.getUVRFConfig(this.props.device.id)}
                        />
                    </div>
                    <div className="row mb-4 mr-lg-2">
                        <HistoricalDataComponent deviceId={Number(this.props.match.params.id)} />
                    </div>
                    {this.props.device.versionMajor > 2 &&
                    <div className="row mb-4 mr-lg-2">
                        <TankSettings
                            deviceId={Number(this.props.match.params.id)}
                            deviceStatus={this.props.deviceStatus}
                            isDisconnected={this.props.device.isDisconnected}
                            isDisabled={this.props.device.isDisconnected}
                            tankLevelIsDerived={this.props.deviceConfig.tankLevelDerived}
                        />
                    </div>}
                    <div className="row mb-4 mr-lg-2">
                        <OverviewTotals
                            deviceId={Number(this.props.match.params.id)}
                            deviceStatus={this.props.deviceStatus}
                            isDisabled={this.props.device.isDisconnected}
                        />
                    </div>
                    {this.props.deviceConfig.mode !== Mode.uVRF && 
                    <div className="row mb-4">
                        <LocationComponent
                            deviceStatus={locationDeviceStatus}
                            deviceConfig={this.props.deviceConfig}
                        />
                    </div>}
                    {this.props.deviceConfig.mode === Mode.uVRF &&
                    <div className="row mb-4">
                        <div className="d-flex component-standard w-100">
                            <div className="col">
                                <UVRFChart
                                    units={this.props.deviceConfig.rateArea.units}
                                    table={this.state.UVRFTable}
                                    currentAngle={this.props.deviceStatus.pivotAngle}
                                    latitude={this.props.deviceConfig.pivotPointLatitude}
                                    longitude={this.props.deviceConfig.pivotPointLongitude}
                                    pivotLength={this.props.deviceConfig.maximumWateringLength}
                                /> 
                            </div>
                        </div>
                    </div>}
                    {this.props.deviceConfig.mode === Mode.uVRF &&
                    <div className="row mb-4">
                        <div className="d-flex component-standard w-100">
                            <div className="col">
                                <UVRFTable
                                    units={this.props.deviceConfig.rateArea.units}
                                    table={this.state.UVRFTable}
                                    currentAngle={this.props.deviceStatus.pivotAngle}
                                    editable={false}
                                    updateTable={() => { return null; }}
                                    editConfigValue={() => { return null; }}
                                    maxCyclePivot={this.props.deviceConfig.maxCyclePivot}
                                />
                            </div>
                        </div>
                    </div>}
                </div>
            );
        } else {
            return (
                <div className="subpage-standard">
                    <div className="row">
                        <div className="col-lg-6">
                            {this.props.device.isDisabled && 
                            <div className="row mb-4 mr-lg-2">
                                <div className="component-standard-max300 w-100">
                                    {/* Header */}
                                    <div className="row">
                                        <div className="col label-large text-dark-blue">
                                            DEVICE SUBSCRIPTION DISABLED
                                        </div>
                                    </div>
                                    <div className="row mb-3 mb-sm-0">
                                        <div className="col text-medium-blue">
                                            System is disabled. Please contact your Agri-Inject 
                                            administrator to have the system enabled.
                                        </div>
                                    </div>
                                </div>
                            </div>
                            }

                            <ActiveAlarms deviceId={Number(this.props.match.params.id)}/>

                            <div className="row mb-4 mr-lg-2">
                                <DetailedStatus
                                    deviceId={Number(this.props.match.params.id)}
                                    deviceStatus={this.props.deviceStatus}
                                    deviceConfiguration={this.props.deviceConfig}
                                    heartbeat={this.props.device.heartbeat}
                                    isDisconnected={this.props.device.isDisconnected}
                                    tankLevelIsDerived={this.props.deviceConfig.tankLevelDerived}
                                />
                            </div>
                            <div className="row mb-4 mr-lg-2">
                                <HistoricalDataComponent deviceId={Number(this.props.match.params.id)} />
                            </div>
                            {this.props.device.versionMajor > 2 &&
                            <div className="row mb-4 mr-lg-2">
                                <TankSettings
                                    deviceId={Number(this.props.match.params.id)}
                                    deviceStatus={this.props.deviceStatus}
                                    isDisconnected={this.props.device.isDisconnected}
                                    isDisabled={this.props.device.isDisconnected}
                                    tankLevelIsDerived={this.props.deviceConfig.tankLevelDerived}
                                />
                            </div>}
                            <div className="row mb-4 mr-lg-2">
                                <OverviewTotals
                                    deviceId={Number(this.props.match.params.id)}
                                    deviceStatus={this.props.deviceStatus}
                                    isDisabled={this.props.device.isDisconnected}
                                />
                            </div>
                        </div>
                        <div className="col-lg-6">
                            <div className="row mb-4">
                                <SystemControl
                                    version={this.props.version}
                                    deviceConfig={this.props.deviceConfig}
                                    deviceStatus={this.props.deviceStatus}
                                    editConfigValue={this.props.editConfigValue}
                                    isDisconnected={this.props.device.isDisconnected}
                                    isDisabled={this.props.device.isDisabled}
                                    updateUVRF={() => this.getUVRFConfig(this.props.device.id)}
                                />
                            </div>
                            {this.props.deviceConfig.mode !== Mode.uVRF && 
                            <div className="row mb-4">
                                <LocationComponent
                                    deviceStatus={locationDeviceStatus}
                                    deviceConfig={this.props.deviceConfig}
                                />
                            </div>}
                            {this.props.deviceConfig.mode === Mode.uVRF &&
                            <div className="row mb-4">
                                <div className="d-flex component-standard w-100">
                                    <div className="col">
                                    <UVRFChart
                                        units={this.props.deviceConfig.rateArea.units}
                                        table={this.state.UVRFTable}
                                        currentAngle={this.props.deviceStatus.pivotAngle}
                                        latitude={this.props.deviceConfig.pivotPointLatitude}
                                        longitude={this.props.deviceConfig.pivotPointLongitude}
                                        pivotLength={this.props.deviceConfig.maximumWateringLength}
                                    />
                                    </div>
                                </div>
                            </div>}
                            {this.props.deviceConfig.mode === Mode.uVRF &&
                            <div className="row mb-4">
                                <div className="d-flex component-standard w-100">
                                    <div className="col">
                                        <UVRFTable
                                            units={this.props.deviceConfig.rateArea.units}
                                            table={this.state.UVRFTable}
                                            currentAngle={this.props.deviceStatus.pivotAngle}
                                            editable={false}  
                                            updateTable={() => { return null; }}
                                            editConfigValue={() => { return null; }}
                                            maxCyclePivot={this.props.deviceConfig.maxCyclePivot}
                                        />
                                    </div>
                                </div>
                            </div>}
                        </div>
                    </div>
                </div>
            );
        }
    }

    private getUVRFConfig = (deviceId: number) => {
        getUVRFConfiguration(deviceId).then((result) => {
            if (result.success) {
                if (result.data!.uvrfTable !== null && result.data!.uvrfTable.length > 0) {
                    this.setState({ 
                        UVRFTable: result.data!.uvrfTable
                    });
                } 
            }
        });
    }
}

export default withRouter(DeviceOverviewSubPage);