import * as React from 'react';
import { UnitSettings } from '../models/Api';
import { UnitSettingDropdownRow } from './UnitSettingDropdownRow';

const chemFlowUnits: string[] = [
    'Gallons Per Hour',
    'Liters Per Hour',
];

const irrigationFlowUnits: string[] = [
    'Gallons Per Minute',
    'Liters Per Minute',
    'Cubic Meters Per Minute'
];

const chemicalTotalUnits: string[] = [
    'Gallons',
    'Liters'
];

const irrigationTotalUnits: string[] = [
    'Gallons',
    'Liters',
    'Cubic Meters'
];

const areaUnits: string[] = [
    'Acres',
    'Hectares'
];

const lengthUnits: string[] = [
    'Feet',
    'Meters'
];

const applicationRateUnits: string[] = [
    'Gallons Per Acre',
    'Liters per Hectare',
];

const pressureUnits: string[] = [
    'PSI',
    'Bar',
    'kPa'
];

interface State {
    unitSettings: UnitSettings;
}

export interface Props {
    unitSettings: UnitSettings;
    editUnitSettings: (unitSettings: UnitSettings) => void;
}

export class UserSettingsUnits extends React.Component<Props, State> {

    constructor(props: Props) {
        super(props);
        this.state = {
            unitSettings: this.props.unitSettings
        };

        this.editUnitSetting = this.editUnitSetting.bind(this);
    }

    componentWillReceiveProps(nextProps: Props) {
        this.setState({
            unitSettings: nextProps.unitSettings
        });
    }

    render() {
        return (
            <div className="container-fluid">
                {/* UNITS */}
                <div className="row">
                    <div className="col-6 label-large">
                        UNITS
                    </div> 
                </div>
                <br/>
                <UnitSettingDropdownRow
                    displayName="CHEMICAL FLOW"
                    propertyName="chemicalFlowUnits"
                    value={this.state.unitSettings.chemicalFlowUnits}
                    editUnitSetting={this.editUnitSetting}
                    unitOptions={chemFlowUnits}
                />
                <UnitSettingDropdownRow
                    displayName="IRRIGATION FLOW"
                    propertyName="irrigationFlowUnits"
                    value={this.state.unitSettings.irrigationFlowUnits}
                    editUnitSetting={this.editUnitSetting}
                    unitOptions={irrigationFlowUnits}
                />
                <UnitSettingDropdownRow
                    displayName="CHEMICAL TOTAL"
                    propertyName="chemicalTotalUnits"
                    value={this.state.unitSettings.chemicalTotalUnits}
                    editUnitSetting={this.editUnitSetting}
                    unitOptions={chemicalTotalUnits}
                />
                <UnitSettingDropdownRow
                    displayName="IRRIGATION TOTAL"
                    propertyName="irrigationTotalUnits"
                    value={this.state.unitSettings.irrigationTotalUnits}
                    editUnitSetting={this.editUnitSetting}
                    unitOptions={irrigationTotalUnits}
                />
                <UnitSettingDropdownRow
                    displayName="AREA"
                    propertyName="areaUnits"
                    value={this.state.unitSettings.areaUnits}
                    editUnitSetting={this.editUnitSetting}
                    unitOptions={areaUnits}
                />
                <UnitSettingDropdownRow
                    displayName="LENGTH"
                    propertyName="lengthUnits"
                    value={this.state.unitSettings.lengthUnits}
                    editUnitSetting={this.editUnitSetting}
                    unitOptions={lengthUnits}
                />
                <UnitSettingDropdownRow
                    displayName="APPLICATION RATE"
                    propertyName="applicationRateUnits"
                    value={this.state.unitSettings.applicationRateUnits}
                    editUnitSetting={this.editUnitSetting}
                    unitOptions={applicationRateUnits}
                />
                <UnitSettingDropdownRow
                    displayName="PRESSURE"
                    propertyName="pressureUnits"
                    value={this.state.unitSettings.pressureUnits}
                    editUnitSetting={this.editUnitSetting}
                    unitOptions={pressureUnits}
                />
            </div>
        );
    }

    private editUnitSetting(name: string, value: string) {
        let unitSettings = this.state.unitSettings;

        unitSettings = {
            ...unitSettings,
            [name]: value
        };
        
        this.setState({
            unitSettings: unitSettings
        });

        // Make call to API to update unit settings
        this.props.editUnitSettings(unitSettings);
    }

}