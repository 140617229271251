import * as React from 'react';
import * as moment from 'moment';
import { NavLink } from 'react-router-dom';
import '../styles/DeviceSummary.css';
import { StatusIndicator } from 'src/components/StatusIndicator';
import { Status, Total, SystemStatus, Mode, getStatusDescription } from '../models/Api';
import { isNullOrUndefined } from 'util';

export interface Props {
    id: number;
    status: SystemStatus;
    heartbeat: Date;
    name: string;
    group: string;
    rateTimeTarget: Status | null;
    rateAreaTarget: Status | null;
    rateTimeStatus: Status | null;
    rateAreaStatus: Status | null;
    total1: Total | null;
    total2: Total | null;
    isDisconnected: boolean | null;
    mode: Mode;
}

export class DeviceSummaryComponent extends React.Component<Props> {
    constructor(props: Props) {
        super(props);
    }

    render() {
        const labelClass: string = 'd-xl-none d-flex align-self-center col-5 ' +
            'label-medium font-weight-bold text-medium-blue';

        let offlineOverlay = '';
        this.props.isDisconnected || isNullOrUndefined(this.props.isDisconnected)
            ? offlineOverlay = 'summary-stale-data'
            : offlineOverlay = '';

        return (
            <div className="device-summary">
                <NavLink to={'/device/' + this.props.id}>
                    <div className={`d-flex row hover-row ${offlineOverlay} `}>

                        <div className="d-flex col-xl-2 col-12">
                            <div className="row w-100">
                                <div className={labelClass}>
                                    STATUS
                                </div>
                                <div className="col label-medium text-medium-gray d-flex align-items-center">
                                    <StatusIndicator
                                        status={this.props.status}
                                    />
                                    {getStatusDescription(this.props.status)}
                                </div>
                            </div>
                        </div>

                        <div className="d-flex col-xl-3 col-12">
                            <div className="row w-100">
                                <div className={labelClass}>
                                    NAME
                                </div>
                                <div className="col-7 col-xl align-items-center">
                                    <span className="label-medium text-dark-gray">{this.props.name}</span>
                                    <br />
                                    <span className="label-small text-medium-gray">{this.props.group}</span>
                                </div>
                            </div>
                        </div>

                        <div className="d-flex col-xl col-12">
                            <div className="row w-100">
                                <div className={labelClass}>
                                    TARGET
                                </div>
                                <div className="col-7 col-xl d-flex align-items-center">
                                    {this.FormatStatus(this.props.mode === Mode.manual_time
                                        ? this.props.rateTimeTarget : this.props.rateAreaTarget)}
                                    <br />
                                </div>
                            </div>
                        </div>

                        <div className="d-flex col-xl col-12">
                            <div className="row w-100">
                                <div className={labelClass}>
                                    ACTUAL
                                </div>
                                <div className="col-7 col-xl d-flex align-items-center">
                                    {this.FormatStatus(this.props.mode === Mode.manual_time
                                        ? this.props.rateTimeStatus : this.props.rateAreaStatus)}
                                    <br />
                                </div>
                            </div>
                        </div>

                        <div className="d-flex col-xl col-12">
                            <div className="row w-100">
                                <div className={labelClass}>
                                    CHEM TOTALS
                                </div>
                                <div className="col-7 col-xl align-items-center">
                                    {this.FormatTotal(this.props.total1)}
                                    <br />
                                    {this.FormatTotal(this.props.total2)}
                                </div>
                            </div>
                        </div>
                    </div>
                </NavLink>
            </div>
        );
    }

    private FormatStatus(status: Status | null): JSX.Element {
        // Check for null values
        if (status === null) {
            return <span className="label-small text-medium-gray">-</span>;
        }
        
        return (
            <span className={`label-small`}>
                <span className="text-dark-gray font-bold">{status.value}</span>
                <span className="text-medium-gray font-regular">&nbsp;{status.units}</span>
            </span>);
    }

    private FormatTotal(total: Total | null): JSX.Element {
        // Check for null values
        if (total === null) {
            return <span className="label-small text-medium-gray">-</span>;
        }

        return (
            <span className="label-small">
                <span className="text-dark-gray font-bold">
                {total.status.value.toLocaleString(undefined, {maximumFractionDigits: 0})}
                </span>
                <span className="text-medium-gray font-regular">
                    &nbsp;{total.status.units}&nbsp;since&nbsp;{moment(total.date).format('M/DD/YY')}
                </span>
            </span>);
    }
}