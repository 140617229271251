import * as React from 'react';
import * as Modal from 'react-modal';
import '../styles/Modal.css';

interface State {
}

export interface Props {
    confirmInfo: string;
    deleteInfo: () => void;

    isModalOpen: boolean;
    closeModal: () => void;
}

export class ConfirmDeleteModal extends React.Component<Props, State> {
    constructor(props: Props) {
        super(props);
    }

    render() {
        return (
            <div className="Modal">
                <Modal
                    ariaHideApp={false}
                    isOpen={this.props.isModalOpen}
                    shouldCloseOnEsc={true}
                    shouldCloseOnOverlayClick={true}
                    onRequestClose={this.props.closeModal}
                    className={{
                        base: 'modal-content edit-modal',
                        afterOpen: 'modal-content_after-open',
                        beforeClose: 'modal-content_before-close'
                    }}
                    overlayClassName={{
                        base: 'modal-overlay',
                        afterOpen: 'modal-overlay_after-open',
                        beforeClose: 'modal-overlay_before-close'
                    }}
                >
                    <div className="container edit-modal">
                        <label className="label-large col-form-label">
                            Are you sure you want to delete this entry?
                        </label>
                        <div className="row form-group modal-text" />
                        <div className="modal-text ml-4">
                            {this.props.confirmInfo}
                        </div>
                        <div className="row form-group mt-4">
                            <div className="col">
                                <button
                                    className="btn btn-block button-standard confirm-button"
                                    tabIndex={2}
                                    onClick={this.confirmButtonPressed}
                                >
                                    Confirm
                                </button>
                            </div>
                            <div className="col">
                                <button
                                    className="btn btn-block button-standard cancel-button"
                                    tabIndex={3}
                                    onClick={this.props.closeModal}
                                >
                                    Cancel
                                </button>
                            </div>
                        </div>
                    </div>
                </Modal>
            </div>
        );
    }

    private confirmButtonPressed = () => {
        // Edit the setpoint
        this.props.deleteInfo();
        // Then close the modal
        this.props.closeModal();
    }
}
