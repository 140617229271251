import * as React from 'react';
import { NavLink } from 'react-router-dom';
import '../styles/MapMarker.css';
import { SystemStatus } from '../models/Api';

export interface Props {
    name: string;
    id: number;
    group: string | undefined;
    status: SystemStatus;
    lat: number;
    lng: number;
    showPin: boolean;
}

export class MapMarker extends React.Component<Props> {
    constructor(props: Props) {
        super(props);
    }

    render() {
        return (
            <NavLink className="map-marker d-flex" to={'/device/' + this.props.id}>
                <div 
                    className={
                        'marker-info' + ((this.props.group === undefined || this.props.group === '') ? ' no-group' : '')
                    }
                >
                    <div className="col">
                        <div className="row">
                            <div>
                                <span className="marker-label">Name:&nbsp;</span>
                                <span className="marker-value">{this.props.name}</span>
                            </div>
                        </div>
                        {this.props.group !== undefined && this.props.group !== '' &&
                        <div className="row">
                            <span className="marker-label">Group:&nbsp;</span>
                            <span className="marker-value">{this.props.group}</span>
                        </div>
                        }
                    </div>
                </div>
                <div 
                    className={
                        (this.props.showPin ? 'marker-pin' : 'marker-circle') + 
                        ' ' +
                        (SystemStatus[this.props.status])
                    }
                />
            </NavLink>
        );
    }
}