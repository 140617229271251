import * as React from 'react';
import * as reactselect from 'react-select';
import '../styles/Modal.css';
import '../styles/EditAlarmSetpointModal.css';
import * as Modal from 'react-modal';
import { AlarmSetpoint, AccountInfo, AlarmRecipient } from '../models/Api';

interface State {
    setpointValue: number;
    totalizerId: number | null;
    usersToNotify: AlarmRecipient[];
    delaySec: number;
}

export interface Props {
    setpoint: AlarmSetpoint;
    setpointPreValueString: string;
    setpointValueString: string;
    setpointPostValueString: string;
    includeTotalizer?: boolean;
    showTimeout?: boolean;
    disableSetpoint?: boolean;
    isModalOpen: boolean;
    editSetpoint: (setpoint: AlarmSetpoint) => void;
    closeModal: () => void;
    users: AccountInfo[];
}

export class EditAlarmSetpointModal extends React.Component<Props, State> {
    constructor(props: Props) {
        super(props);
        let localDelaySec = 0;
        if (props.setpoint.delaySec !== null) {
            localDelaySec = props.setpoint.delaySec;
        }
        this.state = {
            setpointValue: props.setpoint.setpoint,
            totalizerId: props.setpoint.totalizerId,
            usersToNotify: props.setpoint.users,
            delaySec: localDelaySec
        };

        this.confirmButtonPressed = this.confirmButtonPressed.bind(this);
        this.onInputChange = this.onInputChange.bind(this);       
        this.handleSelectChange = this.handleSelectChange.bind(this); 
    }

    componentWillReceiveProps(nextProps: Props) {
        if (!nextProps.isModalOpen) {
            this.setState({
                setpointValue: nextProps.setpoint.setpoint,
                totalizerId: nextProps.setpoint.totalizerId
            });
        }
    }

    render() {
        return ( 
            <div className="Modal">
                <Modal 
                    ariaHideApp={false}
                    isOpen={this.props.isModalOpen}
                    shouldCloseOnEsc={true}
                    shouldCloseOnOverlayClick={true}
                    onRequestClose={this.props.closeModal}
                    className={{
                        base: 'modal-content',
                        afterOpen: 'modal-content_after-open',
                        beforeClose: 'modal-content_before-close'
                    }}
                    overlayClassName={{
                        base: 'modal-overlay',
                        afterOpen: 'modal-overlay_after-open',
                        beforeClose: 'modal-overlay_before-close'
                    }}
                >
                    <div className="container edit-alarm-setpoint-modal">
                        <label className="label-large col-form-label">
                            CHANGE ALARM SETTINGS
                        </label>
                        <div className="row form-group"/>
                        <div className="row form-group d-flex align-items-center">
                            <div className="col-sm-5 text-medium-blue">
                                CURRENT SETPOINT
                            </div>
                            <div className="col justify-content-end">
                                <span>{this.props.setpointPreValueString}</span>
                                <span className="text-bold">{this.props.setpointValueString}</span>
                                <span>{this.props.setpointPostValueString}</span>
                            </div>
                        </div>
                        {!this.props.disableSetpoint &&
                        <div className="row form-group d-flex align-items-center">
                            <div className="col-sm-5 text-medium-blue">
                                NEW SETPOINT
                            </div>
                            <div className="col">
                                <div className="row justify-content-end align-items-center">
                                <div className="col">
                                <input
                                    className="textbox-style-right w-100"
                                    placeholder="Enter setpoint"
                                    name="setpointValue"
                                    value={this.state.setpointValue}
                                    onChange={this.onInputChange}
                                    tabIndex={1}
                                />
                                </div>
                                <div className="col text-medium-blue no-padding-left">
                                    {this.props.setpoint.units}
                                </div>
                                </div>
                            </div>
                        </div>}
                        {this.props.includeTotalizer && this.props.setpoint.totalizerId !== null && 
                        <div className="row form-group d-flex align-items-center">
                            <div className="col-sm-5 text-medium-blue">
                                TOTALIZER
                            </div>
                            <div className="col">
                                <select
                                    value={this.state.totalizerId!}
                                    onChange={this.onInputChange} 
                                    name="totalizerId"
                                    className="dropdown-style w-100"
                                >
                                    <option value="1">Totalizer 1</option>
                                    <option value="2">Totalizer 2</option>
                                    <option value="3">Totalizer 3</option>
                                </select>
                            </div>
                        </div>}
                        {/* {this.props.showTimeout && this.props.setpoint.delaySec !== null &&
                        <div className="row form-group d-flex align-items-center">
                            <div className="col-sm-5 text-medium-blue">
                                Alarm Delay
                            </div>
                            <div className="col">
                                <input
                                    className="textbox-style-right w-100"
                                    placeholder="Enter Alarm Delay"
                                    name="delaySec"
                                    value={this.state.delaySec}
                                    onChange={this.onInputChange}
                                    tabIndex={1}
                                />
                            </div>
                            <div className="col text-medium-blue no-padding-left">
                                     s 
                            </div>
                        </div>} */}
                        <div className="row form-group d-flex align-items-center">
                            <div className="col-sm-5 text-medium-blue">
                                USERS
                            </div>
                            <div className="col">
                                <reactselect.default
                                    options={
                                        this.props.users.map( u => (
                                            {
                                                value: u.id,
                                                label: u.firstName + ' ' + u.lastName
                                            })
                                        )
                                    }
                                    closeMenuOnSelect={false}
                                    // removeSelected={true}
                                    // multi={true}
                                    hideSelectedOptions={true}
                                    isMulti={true}
                                    placeholder="Select users to notify"
                                    value={
                                        this.state.usersToNotify.map( u => ({
                                            value: u.id,
                                            label: u.name
                                        }))
                                    }
                                    onChange={this.handleSelectChange}
                                />
                                
                            </div>
                        </div>
                        <div className="row form-group">
                            <div className="col">
                                <button
                                    className="btn btn-block button-standard confirm-button"
                                    tabIndex={2}
                                    onClick={this.confirmButtonPressed}
                                >
                                    Confirm
                                </button>
                            </div>
                            <div className="col">
                                <button
                                    className="btn btn-block button-standard cancel-button"
                                    tabIndex={3}
                                    onClick={this.props.closeModal}
                                >
                                    Cancel
                                </button>
                            </div>
                        </div>
                    </div>
                </Modal>
            </div>
        );
    }

    private onInputChange(e: React.SyntheticEvent<HTMLInputElement> | React.SyntheticEvent<HTMLSelectElement>) {
        this.setState({
            ...this.state,
            [e.currentTarget.name]: e.currentTarget.value
        });
    }

    private handleSelectChange(selectedValues: {value: string, label: string}[]) {
        this.setState({
            usersToNotify: selectedValues.map( v => ({
                id: v.value,
                name: v.label
            }))
        });
    }

    private confirmButtonPressed() {
        // Get current setpoint object
        let setpoint = this.props.setpoint;

        // Overwrite setpoint value and totalizer ID
        setpoint.setpoint = this.state.setpointValue;
        setpoint.totalizerId = this.state.totalizerId;
        setpoint.users = this.state.usersToNotify;
        setpoint.delaySec = this.state.delaySec;
        
        // Edit the setpoint
        this.props.editSetpoint(setpoint);

        // Then close the modal
        this.props.closeModal();
    }
}
