import { ApiResult, AlarmSetpoint  } from '../models/Api';
import { httpRequest, Urls } from './Shared';

const DEVICE_URL = '/Devices';
const ALARMSETPOINT_URL = '/AlarmSetpoints';

export const getAlarmSetpoints = (deviceId: number): Promise<ApiResult<AlarmSetpoint[]>> => {
    return httpRequest(`${Urls.BASE_URL}${DEVICE_URL}/${deviceId}${ALARMSETPOINT_URL}`,
                       'get'
                        );
};

export const editAlarmSetpoint = (deviceId: number, setpoint: AlarmSetpoint):  
    Promise<ApiResult<AlarmSetpoint[]>> => {
    return httpRequest( `${Urls.BASE_URL}${DEVICE_URL}/${deviceId}${ALARMSETPOINT_URL}/${setpoint.alarmSetpointType}`,
                        'put',
                        { 
                            'active': setpoint.active,
                            'killPump': setpoint.killPump,
                            'killPivot': setpoint.killPivot,
                            'setpoint': setpoint.setpoint,
                            'totalizerId': setpoint.totalizerId,
                            'users': setpoint.users,
                            'delaySec': setpoint.delaySec
                        });
};