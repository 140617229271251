import * as React from 'react';
import { AccountInfo } from 'src/models/Api';
import { AddUserModal } from 'src/components/modal-add-user';
import { EditUserModal } from 'src/components/modal-edit-user';
import { ConfirmDeleteModal } from 'src/components/modal-confirmDelete';
import * as userApi from 'src/api/Users';
import ReactTable from 'react-table';
import 'react-table/react-table.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPencilAlt, faTrashAlt } from '@fortawesome/free-solid-svg-icons';
import {matchSorter} from 'match-sorter';
import 'src/styles/TableStyles.css';
import { columnHeaderStyle, dataStyle } from 'src/styles/TableFormats';

interface State {
  usersData: AccountInfo[];

  editData: AccountInfo;
  editModalOpen: boolean;
  addModalOpen: boolean;
  deleteModalOpen: boolean;

  selected: boolean;
}

export interface Props {
}

export class UserAdminPage extends React.Component<Props, State> {
  private numRequests: number;

  constructor(props: Props) {
    super(props);
    this.numRequests = 0;
    this.state = {
      editData: this.getDefaultAccountInfo(),
      usersData: [this.getDefaultAccountInfo()],
      addModalOpen: false,
      editModalOpen: false,
      deleteModalOpen: false,
      selected: false,
    };
    this.deleteUser = this.deleteUser.bind(this);
  }

  componentDidMount() {
    this.getUsersApiCall();
  }

  render() {
    return (
      <div className="subpage-standard">
        <div className="row component-standard">
        
          <div className="container-fluid">
            <div className="row">
              <div className="col-6 label-large">
                USERS
              </div>
              <div className="col d-flex justify-content-end align-items-center">
                <button
                  className="btn btn-outline-secondary m-2 justify-content-right button-standard"
                  onClick={this.handleAdd}
                >
                  + ADD USER
                </button>
                <AddUserModal
                  isModalOpen={this.state.addModalOpen}
                  closeModal={this.closeModal}                
                />
              </div>
            </div>
            <br/>
            <div className="ReactTable">
              <ReactTable
                data={this.state.usersData}
                filterable={true}
                defaultFilterMethod={(filter, row) => (matchSorter([row[filter.id]], filter.value).length !== 0)}
                // COLUMNS ////////////////////////////////
                columns={
                [
                  {
                    Header: 'NAME',
                    id: 'name',
                    accessor: ( u: AccountInfo ) => { return u.firstName + ' ' + u.lastName; },
                    headerStyle: columnHeaderStyle,
                    style: dataStyle,
                    minWidth: 200
                  }, {
                    Header: 'CUSTOMER',
                    headerStyle: columnHeaderStyle,
                    accessor: 'customerName',
                    style: dataStyle,
                    minWidth: 200
                  }, {
                    Header: 'EMAIL',
                    accessor: 'email',
                    headerStyle: columnHeaderStyle,
                    style: dataStyle,
                    minWidth: 250                
                  }, {
                    Header: 'PHONE NUMBER',
                    accessor: 'phoneNumber',
                    headerStyle: columnHeaderStyle,
                    style: dataStyle,
                    minWidth: 130                
                  }, {
                    Header: 'APPLICATOR\'S LICENSE NO.',
                    accessor: 'licenseNumber',
                    headerStyle: columnHeaderStyle,
                    style: dataStyle,
                    minWidth: 120
                  }, {
                    Header: 'LICENSE EFFECTIVE DATE',
                    accessor: 'licenseEffectiveDate',
                    headerStyle: columnHeaderStyle,
                    style: dataStyle,
                    minWidth: 120
                  }, {
                    Header: 'ROLE',
                    accessor: 'roles',
                    headerStyle: columnHeaderStyle,
                    style: dataStyle,
                    minWidth: 100,              
                  }, {
                    Header: '',
                    width: 112,
                    sortable: false,
                    filterable: false,
                    Cell: cell => (
                      <div>
                        <button 
                          className="iconButton"
                          onClick={() => {
                            this.setState({
                              editModalOpen: true,
                              editData: cell.original,
                            });
                          }}
                        >
                          <FontAwesomeIcon icon={faPencilAlt} />
                        </button>
                        <button 
                          className="iconButton"
                          onClick={() => {
                            this.setState({
                              editData: cell.original,
                              deleteModalOpen: true,
                            });
                          }}
                        >
                          <FontAwesomeIcon icon={faTrashAlt} />
                        </button>
                      </div>
                    )
                  },
                ]}
                // COLUMNS END //////////////////
                defaultSorted={[{id: 'customerName', desc: false }]}
                className="-highlight"
              />
              <React.Fragment>
                <EditUserModal
                  isModalOpen={this.state.editModalOpen}
                  closeModal={this.closeModal}
                  currentInfo={this.state.editData}
                />
                <ConfirmDeleteModal
                  isModalOpen={this.state.deleteModalOpen}
                  closeModal={this.closeModal}
                  confirmInfo={this.state.editData.firstName + ' ' + this.state.editData.lastName}
                  deleteInfo={this.deleteUser}
                />
              </React.Fragment>
            </div>
            Hold shift and click the table headers to multi-sort!
          </div>
        </div>
      </div>
    );
  }

  private getDefaultAccountInfo = (): AccountInfo => {
    return {
      firstName : '',
      lastName : '',
      id: '',
      email : '',
      phoneNumber: '',
      licenseNumber: '0',
      licenseEffectiveDate: '',
      customerId: 0,
      customerName: '',
      isDeactivated: false,
      roles: ['Customer'],
      password: ''
  };
}

  private getUsersApiCall() {
    if (this.numRequests === 0) {      
      userApi.getUsers().then((result) => {
        if (result.success && result.data) {
          this.setState({
            ...this.state,
            usersData: result.data
          });
        }
      })
      .catch(e => alert(e));
    }
  }

  private async deleteUser(): Promise<any> {
    // Increment number of requests so that we don't update local state until this request resolves
    this.numRequests = this.numRequests + 1;

    // Actually call the edit API method
    await userApi.deleteUser(this.state.editData);

    // Decrement number of requests since this one has resolved
    this.numRequests = this.numRequests - 1;

    // Call for update to alarm list if there are no more pending requests
    this.getUsersApiCall();
  }

  private handleAdd = () => {
    this.setState({
      addModalOpen: true
    });
  }

  private closeModal = () => {
    this.setState({
      ...this.state,
      addModalOpen: false,
      editModalOpen: false,
      deleteModalOpen: false
    });
    this.getUsersApiCall();
  }

}