import * as React from 'react';
import { VictoryPie } from 'victory';
import { UVRFEntry, Status } from 'src/models/Api';
import { UVRFMap } from './UVRFMap';

const labels = [
    { x: 'NORTH\n0°', y: 1 },
    { x: '45°', y: 1 },
    { x: '90°', y: 1 },
    { x: '135°', y: 1 },
    { x: '180°', y: 1 },
    { x: '225°', y: 1 },
    { x: '270°', y: 1 },
    { x: '315°', y: 1 },
];

const RESOLUTION = 360 / 40; // Resolution of graph data. Typ 360 degrees divided by 40 entries, per PLC entries.

// R, G, B arrays for lightest and darkest colors to display on the map
const COLOR_LIGHTEST: number[] = [202, 213, 234];
const COLOR_DARKEST: number[] =  [ 12,  50, 117];

export interface Props {
    units: string;
    currentAngle: number;
    table: UVRFEntry[];
    latitude: number;
    longitude: number;
    pivotLength: Status;
}

export interface State {
    mapRenderComplete: boolean;
}

export class UVRFChart extends React.Component<Props, State> {

    constructor(props: Props) {
        super(props);
        this.state = {
            mapRenderComplete: false
        };
    }

    render() {
        
        // Designate the pivot location
        let pivot = {
            datasets: [{
                data: Array.apply(1, Array(360)).fill(1, 0, 360),
                borderWidth: 0,
                backgroundColor: Array.apply('#FFFFFF00', Array(360)), // '#000000',
                borderColor: '#FFFFFF00',
                label: 'PIVOT LOCATION',
            }],
            labels: Array.apply('', Array(360 / RESOLUTION)).map((val: string, index: number) => {
                return `${index * RESOLUTION}°`;
            }),
        };

        pivot.datasets[0].backgroundColor[Math.round(this.props.currentAngle / RESOLUTION)] = '#000000';

        const colorDict: Map<number, string> = this.getAppRateColors();

        return (
            <div>
                <div className="content d-flex center">
                    <UVRFMap 
                        latitude={this.props.latitude}
                        longitude={this.props.longitude}
                        pivotLength={this.props.pivotLength}
                        mapRenderComplete={this.renderGraphs}
                    />
                    {/* Label Graph, only provides labels */}
                    {this.state.mapRenderComplete &&
                    <div className="flex-container background-map center">
                        <VictoryPie
                            startAngle={-22.5}
                            endAngle={337.5}
                            data={labels}
                            colorScale={
                                Array.apply('', Array(labels.length)).fill('#FFFFFF2F', 0, labels.length)
                            }
                            style={{labels: {fontWeight: 'bold'}}}
                        />
                    </div>}
                    {/* Actual graphs, made multiple graphs over layed using the start and end angle */}
                    {this.state.mapRenderComplete
                    && this.props.table.length > 1
                    && this.props.table.map((t) => {
                        let color: string = '#00000000';
                        if (t.applicationRate > 0) {
                            color = colorDict.get(t.applicationRate) || '';
                        }
                        return <div className="flex-container foreground-map center" key={t.index}>
                                <VictoryPie
                                    startAngle={t.startDegree}
                                    endAngle={t.stopDegree}
                                    data={[{ x: `#${t.index}`, y: 1}]}
                                    colorScale={[color]}
                                    labelRadius={90}
                                    style={{ labels: { fill: 'white' } }}
                                />
                            </div>;
                    })}
                    {/* Graph for the pivot location */}
                    {this.state.mapRenderComplete &&
                    <div className="flex-container foreground-map center" >
                        <VictoryPie
                            startAngle={this.props.currentAngle - 1}
                            endAngle={this.props.currentAngle + 1}
                            data={[{ x: `¤`, y: 1 }]}
                            colorScale={['000000']}
                            // labelPostion="startAngle"
                            labelRadius={155}
                            style={{ labels: { fontWeight: 'bold', fontSize: 20 } }}
                        />
                    </div>}
                </div>
                {/* Legend below graph */}
                <div className="d-flex" />
                <div className="d-flex mt-auto center bottom">
                    <div className="d-flex row">
                        {this.displayLegend(colorDict)}
                    </div>
                </div>
            </div>
        );
    }

    displayLegend = (colorsDict: Map<number, string>) => {
        let legendValues: any = [];

        // 5 legend values seems to look the best, so limit to 5
        if (colorsDict.size > 5) {
            const colors = Array.from(colorsDict.values());
            const appRates = Array.from(colorsDict.keys());
            // Always keep the smallest application rate that is greater than 0
            if (appRates[0] > 0) {
                legendValues.push(this.createLegendKey(colors[1], appRates[1]));
            } else {
                legendValues.push(this.createLegendKey(colors[0], appRates[0]));
            }
            const split: number = Math.round((colorsDict.size - 2) / 3);
            const firstIdx: number = Math.round(split / 3) + 1;
            legendValues.push(this.createLegendKey(colors[firstIdx], appRates[firstIdx]));
            const secondIdx: number = firstIdx + split;
            legendValues.push(this.createLegendKey(colors[secondIdx], appRates[secondIdx]));
            const thirdIdx: number = firstIdx + split + split;
            legendValues.push(this.createLegendKey(colors[thirdIdx], appRates[thirdIdx]));
            
            // Always keep the largest application rate
            legendValues.push(this.createLegendKey(colors[colorsDict.size - 1], appRates[colorsDict.size - 1]));
        } else {
            // eslint-disable-next-line
            colorsDict.forEach((color: string, appRate: number) => {
                legendValues.push(this.createLegendKey(color, appRate));
            });
        }

        return legendValues;
    }

    createLegendKey = (bgColor: string, appRate: number) => {
        if (appRate === 0) {
            return null;
        } else {
            return (
                <React.Fragment>
                    <div className="d-flex align-content-center ml-4">
                        <div
                            className="status-indicator"
                            style={{ background: bgColor }}
                        />
                    </div>
                    <div className="text-medium-blue">
                        {`${appRate}  ${this.props.units.substr(0, 3)}`}
                    </div>
                </React.Fragment>
            );
        }
    }

    // returns a Dictionary of ApplicationRate with Hex color strings
    // Will be one unique color for each unique application rate
    getAppRateColors = (): Map<number, string> => {
        let colorsDict: Map<number, string> = new Map<number, string>();
        if (this.props.table.length > 1) {
            // Order from lowest ApplicationRate to highest
            const appRateOrdered: UVRFEntry[] = this.props.table
            // Filter down to only unique Application Rates
            .filter((u: UVRFEntry, idx: number, table: UVRFEntry[]) => 
                // Needs to be == NOT === because Javascript is stupid and picks what type it wants to use
                // eslint-disable-next-line
                table.findIndex(t => t.applicationRate == u.applicationRate) === idx)
            // Need to sort by application rate to create the color array in the correct order
            .sort((a: UVRFEntry, b: UVRFEntry) => a.applicationRate - b.applicationRate);

            // calc the scale factor for the colors
            const scaleFactor: number = 1 / (appRateOrdered.length - 1);

            // Loop over each uvrf and interpolate a color between lightest and darkest
            appRateOrdered.forEach((uvrf: UVRFEntry, index: number) => {
                var hex = this.interpolateColor(COLOR_LIGHTEST, COLOR_DARKEST, index * scaleFactor);
                // UI needs color value in HEX, so convert
                colorsDict.set(uvrf.applicationRate, this.rgbToHexString(hex));
            });
        }
        return colorsDict;
    }

    // Interpolates two [r,g,b] colors and returns an [r,g,b] of the result
    // Taken from the awesome ROT.js roguelike dev library at
    // https://github.com/ondras/rot.js
    interpolateColor = (color1: number[], color2: number[], appRate: number) => {
        let result = color1.slice();
        for (let i = 0; i < 3; i++) {
            result[i] = Math.round(result[i] + appRate * (color2[i] - color1[i]));
        }
        return result;
    }

    // Converts and RGB array to a Hex string
    rgbToHexString = (rgb: number[]): string => {
        const r: number = rgb[0];
        const g: number = rgb[1];
        const b: number = rgb[2];
        return '#' + ((1 << 24) + (r << 16) + (g << 8) + b).toString(16).slice(1) + 'A0';
    }

    renderGraphs = () => {
        this.setState({
            mapRenderComplete: true
        });
    }
}