import * as React from 'react';
import '../styles/Modal.css';
import '../styles/EditAlarmSetpointModal.css';
import '../styles/EditPasswordModal.css';
import * as Modal from 'react-modal';

interface State {
    currentPassword: string;
    newPassword: string;
    confirmPassword: string;
}

export interface Props {
    isModalOpen: boolean;
    closeModal: () => void;
    changePassword: (currentPassword: string, newPassword: string, newPassword2: string) => void;
    message: string;
}

export class EditPasswordModal extends React.Component<Props, State> {
    constructor(props: Props) {
        super(props);
        
        this.state = {
            currentPassword: '',
            newPassword: '',
            confirmPassword: ''
        };

        this.confirmButtonPressed = this.confirmButtonPressed.bind(this);
        this.onInputChange = this.onInputChange.bind(this);     
        this.cancelButtonPressed = this.cancelButtonPressed.bind(this);   
    }

    render() {
        return ( 
            <div className="Modal">
                <Modal 
                    ariaHideApp={false}
                    isOpen={this.props.isModalOpen}
                    shouldCloseOnEsc={true}
                    shouldCloseOnOverlayClick={true}
                    onRequestClose={this.props.closeModal}
                    className={{
                        base: 'modal-content',
                        afterOpen: 'modal-content_after-open',
                        beforeClose: 'modal-content_before-close'
                    }}
                    overlayClassName={{
                        base: 'modal-overlay',
                        afterOpen: 'modal-overlay_after-open',
                        beforeClose: 'modal-overlay_before-close'
                    }}
                >
                    <div className="container edit-alarm-setpoint-modal">
                        <label className="label-large col-form-label">
                            CHANGE PASSWORD
                        </label>
                        <div className="row form-group"/>
                        <div className="row form-group d-flex align-items-center">
                            <div className="col-4 label-medium text-medium-blue">
                                CURRENT PASSWORD
                            </div>
                            <div className="col-8">
                                <input 
                                    className="passwordbox-style"
                                    type="password"
                                    value={this.state.currentPassword}
                                    onChange={this.onInputChange}
                                    name="currentPassword"
                                />
                            </div>
                        </div>
                        <div className="row form-group d-flex align-items-center">
                            <div className="col-4 label-medium text-medium-blue">
                                NEW PASSWORD
                            </div>
                            <div className="col-8">
                                <input 
                                    className="passwordbox-style"
                                    type="password"
                                    value={this.state.newPassword}
                                    onChange={this.onInputChange}
                                    name="newPassword"
                                />
                            </div>
                        </div>
                        <div className="row form-group d-flex align-items-center">
                            <div className="col-4 label-medium text-medium-blue">
                                CONFIRM PASSWORD
                            </div>
                            <div className="col-8">
                                <input 
                                    className="passwordbox-style"
                                    type="password"
                                    value={this.state.confirmPassword}
                                    onChange={this.onInputChange}
                                    name="confirmPassword"
                                />
                            </div>
                        </div>
                        <div className="row d-flex align-items-center">
                            <div className="col d-flex justify-content-center mb-2">
                                {this.props.message}
                            </div>
                        </div>
                        <div className="row form-group">
                            <div className="col">
                                <button
                                    className="btn btn-block button-standard confirm-button"
                                    tabIndex={2}
                                    onClick={this.confirmButtonPressed}
                                >
                                    Confirm
                                </button>
                            </div>
                            <div className="col">
                                <button
                                    className="btn btn-block button-standard cancel-button"
                                    tabIndex={3}
                                    onClick={this.cancelButtonPressed}
                                >
                                    Cancel
                                </button>
                            </div>
                        </div>
                    </div>
                </Modal>
            </div>
        );
    }

    private onInputChange(e: React.SyntheticEvent<HTMLInputElement> | React.SyntheticEvent<HTMLSelectElement>) {
        this.setState({
            ...this.state,
            [e.currentTarget.name]: e.currentTarget.value
        });
    }

    private confirmButtonPressed() {
        // Call change password method that was passed in
        this.props.changePassword(this.state.currentPassword, this.state.newPassword, this.state.confirmPassword);
    }

    private cancelButtonPressed() {
        this.setState({
            currentPassword: '',
            newPassword: '',
            confirmPassword: ''
        });
        this.props.closeModal();
    }
}
