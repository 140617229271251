import { ApiResult, LoginAttempt, LogInResponse, SetPassword, ChangePassword, PasswordResponse } from '../models/Api';
import { httpRequest, Urls } from './Shared';

const ACCOUNT_URL = '/Account';
const LOGIN_URL = '/Login';
const SET_PASSWORD_URL = '/SetPassword';
const CHANGE_PASSWORD_URL = '/ChangePassword';
const RESET_URL = '/RequestReset';

export const postLogin = (login: LoginAttempt): Promise<ApiResult<LogInResponse>> => {
    return httpRequest(`${Urls.BASE_URL}${ACCOUNT_URL}${LOGIN_URL}`,
                       'post',
                       {
                            'email': login.email,
                            'password': login.password
                       });
};

export const setPassword = (setPasswordObject: SetPassword): Promise<ApiResult<PasswordResponse>> => {
    return httpRequest(`${Urls.BASE_URL}${ACCOUNT_URL}${SET_PASSWORD_URL}`,
                       'post',
                       {
                            'email': setPasswordObject.email,
                            'password': setPasswordObject.password,
                            'confirmPassword': setPasswordObject.confirmPassword,
                            'code': setPasswordObject.code
                       });
};

export const changePassword = (login: ChangePassword): Promise<ApiResult<PasswordResponse>> => {
    return httpRequest(`${Urls.BASE_URL}${ACCOUNT_URL}${CHANGE_PASSWORD_URL}`,
                       'post',
                       {
                            'email': login.email,
                            'currentPassword': login.currentPassword,
                            'newPassword': login.newPassword,
                            'confirmNewPassword': login.confirmNewPassword
                       });
};

export const postResetEmail = (email: string): Promise<ApiResult<PasswordResponse>> => {
    return httpRequest(`${Urls.BASE_URL}${ACCOUNT_URL}${RESET_URL}`,
                       'post',
                       {
                            'Email': email
                       });
};