import * as React from 'react';
import { DeviceConfiguration, Version } from '../models/Api';
import { InlineInputEdit } from './InlineInputEdit';
import { ConfigurationSelect, ConfigurationSelectType } from './ConfigurationSelect';
import Toggle from 'react-toggle';

const types: ConfigurationSelectType[]  = [
    { name: 'Center Pivot', value: 1 },
    { name: 'Corner Pivot', value: 2 },
    { name: 'Lateral Move', value: 3 },
    { name: 'Drip', value: 4 },
    { name: 'Golf/Turf', value: 5 }

];

interface State {
    samePumpAndPivotLocation: boolean;
}

export interface Props {
    deviceConfig: DeviceConfiguration;
    isDisconnected: boolean | null;
    editConfigValue: (name: string, value: string) => void;
    version: Version;
}

export class DeviceSettingsIrrigation extends React.Component<Props, State> {

    constructor(props: Props) {
        super(props);
        this.state = {
            samePumpAndPivotLocation: false
        };
    }

    render() {
        let cName = '';
        this.props.isDisconnected ? cName = 'settings-stale-data' : cName = '';

        return (
            <div className="component-standard w-100">
                {/* Header */}
                <div className="row">
                    <div className="col-12 label-large text-dark-blue">
                        IRRIGATION SYSTEM INFORMATION
                    </div>
                </div>
                <br/>
                {/* Type */}
                <div className="row flex-container list-divider">
                    <div className="col-xl-3 col-12 text-medium-blue">
                        MODE TYPE
                    </div>
                    <div className="col-xl-2 col-12 ">
                        <ConfigurationSelect 
                            name="IrrigationSystemType"
                            value={this.props.deviceConfig.irrigationSystemType}
                            options={types}
                            editValue={this.props.editConfigValue}
                        />
                    </div>
                </div>
                {/* NAME */}
                {this.props.version.major >= 3 &&
                <div className="row flex-container list-divider">
                    <div className="col-xl-3 col-12 text-medium-blue">
                        FIELD NAME
                    </div>
                    <div className="col-xl-3 col-12 ">
                        <InlineInputEdit
                            inputClassname="textbox-style"
                            name="IrrigationSystemName"
                            value={this.props.deviceConfig.irrigationSystemName || ''}
                            editValue={this.props.editConfigValue}
                        />
                    </div>
                </div>}

                <div className="row flex-container list-divider">
                    <div className="col-xl-3 col-12 text-medium-blue ">
                        PIVOT POINT LOCATION
                    </div>
                    <div className="col-xl-3 col-12">
                        <span className="mr-4 align-middle">
                            LATITUDE&nbsp;
                        </span>
                        <InlineInputEdit
                            name="PivotPointLatitude"
                            value={this.props.deviceConfig.pivotPointLatitude.toFixed(6)}
                            editValue={this.props.editConfigValue}
                            inputClassname="textbox-style"
                        />
                    </div>
                    <div className="col-xl-3 col-12">
                        <span className="mr-3 align-middle">
                            LONGITUDE
                        </span>
                        <InlineInputEdit
                            name="PivotPointLongitude"
                            value={this.props.deviceConfig.pivotPointLongitude.toFixed(6)}
                            editValue={this.props.editConfigValue}
                            inputClassname="textbox-style"
                        />
                    </div>
                    <div className="col-xl-3 col-12">
                        <span className="m-3 align-middle">
                            SAME AS REFLEX
                        </span>
                        <label className="mb-0 d-inline-flex align-middle" style={{'cursor': 'pointer'}}>
                            <Toggle
                                icons={false}
                                checked={this.state.samePumpAndPivotLocation}
                                onChange={(e: React.SyntheticEvent<HTMLInputElement>) => {
                                    if (e.currentTarget.checked === true) {
                                        this.props.editConfigValue(
                                        'PivotPointLatitude',
                                        this.props.deviceConfig.latitude.toString()
                                        );
                                        this.props.editConfigValue(
                                            'PivotPointLongitude', 
                                            this.props.deviceConfig.longitude.toString()
                                        );
                                    }
                                    this.setState({
                                            samePumpAndPivotLocation: e.currentTarget.checked
                                        });
                                    }}
                            />
                        </label>
                        
                    </div>
                </div> 
            
                {/* LRDU */}
                <div className={`row flex-container list-divider ${cName}`}>
                    <div className="col-xl-3 text-medium-blue">
                        PIVOT LENGTH - LAST REGULAR DRIVE UNIT
                    </div>
                    <div className="col-xl-2">
                        <InlineInputEdit
                            inputClassname="textbox-style-right"
                            name="IrrigationLrduLength"
                            value={this.props.deviceConfig.irrigationLrduLength.value}
                            editValue={this.props.editConfigValue}
                            disabled={(this.props.isDisconnected === null ? true : this.props.isDisconnected)}
                        />
                    </div>
                    <div className="col-xl-2 ml-0 align-middle">
                        {this.props.deviceConfig.irrigationLrduLength.units}
                    </div>
                </div>

                {/* ****** PARAMETERS BELOW ARE TIED TO LRDU LENGTH UNTIL API IS UPDATED */}
                {/* Watering Length */}
                <div className={`row flex-container list-divider ${cName}`}>
                    <div className="col-xl-3 text-medium-blue">
                        MAXIMUM WATERING LENGTH (INCLUDES THROW OF THE LAST NOZZLE)
                    </div>
                    <div className="col-xl-2">
                        <InlineInputEdit
                            inputClassname="textbox-style-right"
                            name="MaximumWateringLength"
                            value={this.props.deviceConfig.maximumWateringLength.value}
                            editValue={this.props.editConfigValue}
                            disabled={(this.props.isDisconnected === null ? true : this.props.isDisconnected)}
                        />
                    </div>
                    <div className="col-xl-2 ml-0 align-middle">
                        {this.props.deviceConfig.maximumWateringLength.units}
                    </div>
                </div>
                {/* MAX FLOW AT LRDU  */}
                <div className={`row flex-container list-divider ${cName}`}>
                    <div className="col-xl-3 text-medium-blue">
                        TOTAL FLOW AT LAST REGULAR DRIVE UNIT
                    </div>
                    <div className="col-xl-2">
                        <InlineInputEdit
                            inputClassname="textbox-style-right"
                            name="MaxFlowAtLrdu"
                            value={this.props.deviceConfig.maxFlowAtLrdu.value}
                            editValue={this.props.editConfigValue}
                            disabled={(this.props.isDisconnected === null ? true : this.props.isDisconnected)}
                        />
                    </div>    
                    <div className="col-xl-2 ml-0 align-middle">
                        {this.props.deviceConfig.maxFlowAtLrdu.units}
                    </div>
                </div>
                {/* MAX FLOW AT MAX WATERING LENGTH */}
                <div className={`row flex-container list-divider ${cName}`}>
                    <div className="col-xl-3 text-medium-blue">
                        PIVOT SYSTEM MAX FLOW
                    </div>
                    <div className="col-xl-2 ">
                        <InlineInputEdit
                            inputClassname="textbox-style-right"
                            name="MaxFlowAtMaxWateringLength"
                            value={this.props.deviceConfig.maxFlowAtMaxWateringLength.value}
                            editValue={this.props.editConfigValue}
                            disabled={(this.props.isDisconnected === null ? true : this.props.isDisconnected)}
                        />
                    </div>
                        <div className="col-xl-2 ml-0 align-middle">
                            {this.props.deviceConfig.maxFlowAtMaxWateringLength.units}
                        </div>
                </div>
                {/* MINIMUM REVOLUTION TIME */}
                <div className={`row flex-container list-divider ${cName}`}>
                    <div className="col-xl-3 text-medium-blue">
                        MINIMUM REVOLUTION TIME
                    </div>
                    <div className="col-xl-2">
                        <InlineInputEdit
                            inputClassname="textbox-style-right"
                            name="MinimumRevolutionTime"
                            value={this.props.deviceConfig.minimumRevolutionTime.value}
                            editValue={this.props.editConfigValue}
                            disabled={(this.props.isDisconnected === null ? true : this.props.isDisconnected)}
                        />
                    </div>
                        <div className="col-xl-2 ml-0 align-middle">
                            {this.props.deviceConfig.minimumRevolutionTime.units}
                        </div>
                </div>
                {/* USER ENTERED AREA */}
                <div className={`row flex-container list-divider ${cName}`}>
                    <div className="col-xl-3 text-medium-blue">
                        USER ENTERED AREA
                    </div>
                    <div className="col-xl-2">
                        <InlineInputEdit
                            inputClassname="textbox-style-right"
                            name="UserEnteredArea"
                            value={this.props.deviceConfig.userEnteredArea.value}
                            editValue={this.props.editConfigValue}
                            disabled={(this.props.isDisconnected === null ? true : this.props.isDisconnected)}
                        />
                    </div>
                        <div className="col-xl-2 ml-0 align-middle">
                            {this.props.deviceConfig.userEnteredArea.units}
                        </div>
                </div>
                {/* DEGREES OF FIELD BEING TREATED */}
                <div className={`row flex-container list-divider ${cName}`}>
                    <div className="col-xl-3 text-medium-blue">
                        DEGREES OF FIELD BEING TREATED
                    </div>
                    <div className="col-xl-2">
                            <span className="row justify-content-end col-noInput-textboxStyle">
                            {this.props.deviceConfig.degreesTreated.value}
                            </span>
                    </div>
                    <div className="col-xl-2 ml-0 align-middle">
                        Degrees
                    </div>
                </div>
                {/* PUMP START WATER FLOW */}
                <div className={`row flex-container list-divider ${cName}`}>
                    <div className="col-xl-3 text-medium-blue">
                        PUMP START WATER FLOW
                    </div>
                    <div className="col-xl-2">
                        <InlineInputEdit
                            inputClassname="textbox-style-right"
                            name="PumpStartWaterFlow"
                            value={this.props.deviceConfig.pumpStartWaterFlow.value}
                            editValue={this.props.editConfigValue}
                            disabled={(this.props.isDisconnected === null ? true : this.props.isDisconnected)}
                        />
                    </div>
                        <div className="col-xl-2 ml-0 align-middle">
                            {this.props.deviceConfig.pumpStartWaterFlow.units}
                        </div>
                </div>
                {/* PUMP STOP WATER FLOW */}
                <div className={`row flex-container list-divider ${cName}`}>
                    <div className="col-xl-3 text-medium-blue">
                        PUMP STOP WATER FLOW
                    </div>
                    <div className="col-xl-2">
                        <InlineInputEdit
                            inputClassname="textbox-style-right"
                            name="PumpStopWaterFlow"
                            value={this.props.deviceConfig.pumpStopWaterFlow.value}
                            editValue={this.props.editConfigValue}
                            disabled={(this.props.isDisconnected === null ? true : this.props.isDisconnected)}
                        />
                    </div>
                        <div className="col-xl-2 ml-0 align-middle">
                            {this.props.deviceConfig.pumpStopWaterFlow.units}
                        </div>
                </div>
                {/* PUMP START DELAY */}
                {this.props.version.major >= 2 &&
                <div className={`row flex-container list-divider ${cName}`}>
                    <div className="col-xl-3 text-medium-blue">
                        PUMP START DELAY
                    </div>
                    <div className="col-xl-2">
                        <InlineInputEdit
                            inputClassname="textbox-style-right"
                            name="PumpStartDelay"
                            value={this.props.deviceConfig.pumpStartDelay}
                            editValue={this.props.editConfigValue}
                            disabled={(this.props.isDisconnected === null ? true : this.props.isDisconnected)}
                        />
                    </div>
                        <div className="col-xl-2 ml-0 align-middle">
                            Seconds
                        </div>
                </div>}
                {/* PUMP STOP DELAY */}
                {this.props.version.major >= 2 &&
                <div className={`row flex-container list-divider ${cName}`}>
                    <div className="col-xl-3 text-medium-blue">
                        PUMP STOP DELAY
                    </div>
                    <div className="col-xl-2">
                        <InlineInputEdit
                            inputClassname="textbox-style-right"
                            name="PumpStopDelay"
                            value={this.props.deviceConfig.pumpStopDelay}
                            editValue={this.props.editConfigValue}
                            disabled={(this.props.isDisconnected === null ? true : this.props.isDisconnected)}
                        />
                    </div>
                        <div className="col-xl-2 ml-0 align-middle">
                            Seconds
                        </div>
                </div>}
            </div>
        );
    }
}