import * as React from 'react';
import Footer from '../components/Footer';
import Header from '../components/Header';
import * as userApi from '../api/Users';
import * as accountApi from '../api/Account';
import { withRouter, RouteComponentProps, Switch, Route } from 'react-router-dom';
// import { UserAccountInfo } from '../components/UserAccountInfo';
import { AccountInfo } from '../models/Api';
import '../styles/User.css';
import NavButton from 'src/components/NavButton';
import UnitSettingsSubPage from '../components/UnitSettingsSubPage';
import { UserAccountInfo } from 'src/components/UserAccountInfo';

interface State {
    accountInfo: AccountInfo;
    message: string;
    passwordChangeMessage: string;
}

export interface Props extends RouteComponentProps<{}> {
}

class UserPage extends React.Component<Props, State> {
    
    constructor(props: Props) {
        super(props);
        this.state = {
            accountInfo: {
                id: '',
                email: '',
                firstName: '',
                lastName: '',
                phoneNumber: '',
                licenseNumber: '0',
                licenseEffectiveDate: '',
                customerId: 0,
                customerName: '',
                isDeactivated: false,
                roles: [''],
                password: ''
            },
            message: '',
            passwordChangeMessage: '',
        };
        this.handleFieldChange = this.handleFieldChange.bind(this);
        this.getUserInfo = this.getUserInfo.bind(this);
        this.handleSave = this.handleSave.bind(this);
        this.changePassword = this.changePassword.bind(this);
    }

    componentDidMount() {
        this.getUserInfo();
        window.scrollTo(0, 0);
    }

    render() {
        return (
            <div className="flex-root">
                <Header/>
                <div className="user-header">
                    <div className="col">
                        <div className="row">
                            <label className="content text-medium-blue label-large">
                                USER
                            </label>
                        </div>
                    </div>
                
                    <div className="row">
                        <NavButton
                            text="DETAILS"
                            path={'/user/details'}
                            alternatePaths={['/user']}
                            className="ml-0"
                        />
                        <NavButton
                            text="UNITS"
                            path={'/user/units'}
                            className="ml-0"
                        />
                    </div>
                </div>
                <Switch>
                    <Route 
                        path={'/user'}
                        exact={true}
                        render={() => this.displayUserDetails()}
                    />
                    <Route 
                        path={'/user/details'}
                        render={() => this.displayUserDetails()}
                    />
                    <Route
                        path={'/user/units'}
                        render={() => 
                            <UnitSettingsSubPage />
                        }
                    />
                </Switch>
                <Footer/>
            </div>
        );
    }
    
    handleFieldChange(e: React.SyntheticEvent<HTMLInputElement>) {
        var newState = {...this.state.accountInfo, [e.currentTarget.name]: e.currentTarget.value };
        this.setState({accountInfo: newState});
    }

    handleSave() {
        userApi.putUser(this.state.accountInfo).then((result) => {
            if (result.success) {
                this.setState({message: 'Data Saved'});
            } else {
                this.setState({message: 'Could Not Save Changes'});
            }
        });
    }

    getUserInfo() {
        userApi.getUser().then((result) => {
            if (result.success) {
                this.setState({
                    accountInfo: result.data!
                });
            } else {
                this.setState({
                    accountInfo: {
                        ...this.state.accountInfo,
                        email: '',
                        firstName: '',
                        lastName: '',
                        licenseNumber: '0',
                        licenseEffectiveDate: '',
                    },
                });
            }
        });
    }

    private changePassword(currentPassword: string, newPassword: string, newPassword2: string) {
        accountApi.changePassword({
            email: this.state.accountInfo.email,
            currentPassword: currentPassword,
            newPassword: newPassword,
            confirmNewPassword: newPassword2,
        }).then((result) => {
            if (result.success) {
                this.setState({
                    passwordChangeMessage: 'Password updated successfully'
                });
            } else {
                this.setState({
                    passwordChangeMessage: 'Invalid credentials, please retry'
                });
            }
        });
    }
    
    private displayUserDetails = () => {
        return (
            <div className="subpage-standard">
                <div className="row component-standard">
                    <UserAccountInfo
                        accountInfo={this.state.accountInfo} 
                        handleChange={this.handleFieldChange}
                        handleSave={this.handleSave}
                        message={this.state.message}
                        changePassword={this.changePassword}
                        passwordChangeMessage={this.state.passwordChangeMessage}
                    />
                </div>
            </div>
        );
    }
}
    
export default withRouter(UserPage);