import { ApiResult, DeviceStatus  } from '../models/Api';
import { httpRequest, Urls } from './Shared';

const DEVICESTATUS_URL = '/DeviceStatus';

export const getDevice = (deviceId: number): Promise<ApiResult<DeviceStatus>> => {
    return httpRequest(`${Urls.BASE_URL}${DEVICESTATUS_URL}/${deviceId}`,
                       'get'
                        );
};
