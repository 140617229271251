import * as React from 'react';
import { ConfigurationSelect } from './ConfigurationSelect';

export interface Props {
    displayName: string;
    propertyName: string;
    value: number;
    unitOptions: string[];
    editUnitSetting: (name: string, value: string) => void;
}

export class UnitSettingDropdownRow extends React.Component<Props> {
    constructor(props: Props) {
        super(props);
    }

    render() {
        return (
            <div className="list-divider flex-container row">
                <div className="col-2 text-medium-blue align-items-center">
                    {this.props.displayName}
                </div>
                <div className="col-10 align-items-center">
                    <ConfigurationSelect
                        name={this.props.propertyName}
                        value={this.props.value}
                        options={this.props.unitOptions.map(
                            (u, index) => (
                                {
                                    name: u,
                                    value: index
                                })
                        )}
                        editValue={this.props.editUnitSetting}
                    />
                </div>
            </div>
        );
    }
}
