import * as React from 'react';
import { AccountInfo } from '../models/Api';
import { UserAccountComponent } from './UserAccountComponent';
import { EditPasswordModal } from './EditPasswordModal';
import { AddUserModal } from './modal-add-user';

interface State {
    editPasswordModalOpen: boolean;
    addUserModalOpen: boolean;
}

export interface Props {
    accountInfo: AccountInfo;
    handleChange: (e: React.SyntheticEvent<HTMLInputElement>) => void;
    handleSave: () => void;
    message: string;
    changePassword: (currentPassword: string, newPassword: string, newPassword2: string) => void;
    passwordChangeMessage: string;
}

export class UserAccountInfo extends React.Component<Props, State> {

    constructor(props: Props) {
        super(props);
        this.state = {
            editPasswordModalOpen: false,
            addUserModalOpen: false,
        };
        this.closeModal = this.closeModal.bind(this);
        this.openModal = this.openModal.bind(this);
    }

    render() {
        let accountInfo = this.props.accountInfo;
        return (
            <div className="w-100">
                {/* Header */}
                <div className="row">
                    <div className="col-11 label-large text-dark-blue">
                        ACCOUNT INFO
                    </div>
                    <div className="col-1">
                        <div className="row">
                            <button 
                                className="btn button-standard mx-auto"
                                onClick={this.props.handleSave}
                            >
                                SAVE
                            </button>
                        </div>
                        <div className="row">
                            <div className="text-medium-blue label-small pt-2 mx-auto">
                                {this.props.message}
                            </div>
                        </div>
                    </div>         
                </div>
                <div className="row flex-container list-divider">
                    <div className="col-2 text-medium-blue">                            
                        CUSTOMER
                    </div>
                    <div className="col">
                        {accountInfo.customerName}
                    </div>
                </div>
                <div className="row flex-container list-divider">
                    <div className="col-2 text-medium-blue">                            
                        EMAIL
                    </div>
                    <div className="col">
                        {accountInfo.email}
                    </div>
                </div>
                <div className="row flex-container list-divider">
                    <div className="col-2 text-medium-blue">                            
                        PASSWORD
                    </div>
                    <div className="col">
                        <button 
                            className="btn button-standard mr-2"
                            onClick={() => this.openModal()}
                        >
                            CHANGE PASSWORD
                        </button>
                    </div>
                </div>
                <UserAccountComponent 
                    handleChange={this.props.handleChange}
                    value={accountInfo.firstName}
                    fieldName="FIRST NAME"
                    APIName="firstName"
                />
                <UserAccountComponent 
                    handleChange={this.props.handleChange}
                    value={accountInfo.lastName}
                    fieldName="LAST NAME"
                    APIName="lastName"
                />                
                <UserAccountComponent 
                    handleChange={this.props.handleChange}
                    value={accountInfo.phoneNumber}
                    fieldName="PHONE NUMBER"
                    APIName="phoneNumber"
                    type="tel"
                    placeholder="888 888 8888"
                />
                <UserAccountComponent 
                    handleChange={this.props.handleChange}
                    value={accountInfo.licenseNumber}
                    fieldName="APPLICATOR'S LICENSE NO."
                    APIName="licenseNumber"
                    type="number"
                />
                <UserAccountComponent 
                    handleChange={this.props.handleChange}
                    value={accountInfo.licenseEffectiveDate}
                    fieldName="LIC EFF DATE"
                    APIName="licenseEffectiveDate"
                    type="date"
                />  
                <EditPasswordModal 
                    isModalOpen={this.state.editPasswordModalOpen}
                    closeModal={this.closeModal}
                    message={this.props.passwordChangeMessage}
                    changePassword={this.props.changePassword}
                />  
                <div className="list-divider"/>
                <div className="row flex-container list-divider">
                    <div className="col-2 text-medium-blue">                            
                        ADD NEW USER
                    </div>
                    <div className="col">
                        <button 
                            className="btn button-standard mr-2"
                            onClick={() => this.openAddUserModal()}
                        >
                            ADD USER
                        </button>
                    </div>
                </div>
                <AddUserModal
                    isModalOpen={this.state.addUserModalOpen}
                    closeModal={this.closeModal}
                    // addInfo={this.addUser}
                />       
            </div>
        );
    }

    openModal() {
        this.setState({
            editPasswordModalOpen: true
        });
    }

    openAddUserModal() {
        this.setState({
            addUserModalOpen: true
        });
    }

    closeModal() {
        this.setState({
            editPasswordModalOpen: false,
            addUserModalOpen: false,
        });
    }
}
