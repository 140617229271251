import * as React from 'react';
import GoogleMapReact from 'google-map-react';
import 'src/styles/DevicesMap.css';
import { Status } from 'src/models/Api';

export interface Props {
    latitude: number;
    longitude: number;
    pivotLength: Status;
    mapRenderComplete: () => void;
}

export interface State {
    latLongZoom: LatLongZoom;
    mapLoadComplete: boolean;
}

interface LatLongZoom {
    latitude: number;
    longitude: number;
    zoom: number;
}

// Default lat/long and zoom level if there is no device specified
const DEFAULT_LATITUDE: number = 39.09;
const DEFAULT_LONGITUDE: number = -95.71;
const DEFAULT_ZOOM: number = 4;

// Constants for zoom calculation
const MAX_ZOOM: number = 21;
const GRAPH_PIXELS_SIZE: number = 300;
// const MAP_HEIGHT: number = 300;
// const MAP_WIDTH: number = 400;
// const WORLD_HEIGHT: number = 256;
// const WORLD_WIDTH: number = 256;

export class UVRFMap extends React.Component<Props, State> {
    constructor(props: Props) {
        super(props);

        // Determine center of all devices entered
        this.state = {
            latLongZoom: {
                latitude: DEFAULT_LATITUDE,
                longitude: DEFAULT_LONGITUDE,
                zoom: DEFAULT_ZOOM
            },
            mapLoadComplete: false
        };

        this.mapLoaded = this.mapLoaded.bind(this);
    }

    componentWillReceiveProps(nextProps: Props) {
        if (this.state.mapLoadComplete) {
            this.setState({
                latLongZoom: this.calculateCenter(nextProps.latitude, nextProps.longitude, nextProps.pivotLength)
            });
        }
    }

    render() {
        return (
            <div className="devices-map mt-4">
                <div className="map-container">
                    <GoogleMapReact
                        bootstrapURLKeys={{ key: 'AIzaSyC7YxVn65qOy9SzE0-CgjGijkSzvwuxN84'}}
                        // defaultCenter={
                        //     {
                        //         lat: DEFAULT_LATITUDE,
                        //         lng: DEFAULT_LONGITUDE
                        //     }
                        // }
                        defaultZoom={DEFAULT_ZOOM}
                        zoom={this.state.latLongZoom.zoom}
                        center={
                            {
                                lat: this.state.latLongZoom.latitude,
                                lng: this.state.latLongZoom.longitude,
                            }
                        }
                        options={{
                            mapTypeId: 'hybrid'
                        }}
                        onGoogleApiLoaded={this.mapLoaded}
                        yesIWantToUseGoogleMapApiInternals={true}
                    />
                </div>
            </div>
        );
    }

    private mapLoaded() {
        // Map has loaded - set state to true
        this.setState({
            mapLoadComplete: true,
            latLongZoom: this.calculateCenter(this.props.latitude, this.props.longitude, this.props.pivotLength)
        });
        this.props.mapRenderComplete();
    }

    private calculateCenter(latitude: number, longitude: number, pivotLength: Status): LatLongZoom {
        let latLongZoom: LatLongZoom = {
            latitude: DEFAULT_LATITUDE,
            longitude: DEFAULT_LONGITUDE,
            zoom: DEFAULT_ZOOM
        };

        let pivotLengthMeters = pivotLength.value * 0.3048;
        if (pivotLength.units === 'Meters') {
            pivotLengthMeters = pivotLength.value;
        }
        
        // from  metersPerPx = 156543.03392 * Math.cos(inLatitude* Math.PI / 180) / Math.pow(2, zoom)
        
        latLongZoom.zoom = Math.log2(156543.03392 * Math.cos(latitude * Math.PI / 180) 
                    * GRAPH_PIXELS_SIZE / (pivotLengthMeters * 2));

        latLongZoom.latitude = latitude;
        latLongZoom.longitude = longitude;
        latLongZoom.zoom = Math.min(latLongZoom.zoom, MAX_ZOOM);
        return latLongZoom;
    }
}