import * as React from 'react';
import '../styles/Modal.css';
import * as Modal from 'react-modal';
import * as accountApi from '../api/Account';

interface State {
    email: string;
    message: string;
}

export interface Props {
    isModalOpen: boolean;
    closeModal: () => void;
}

export class ForgotPasswordModal extends React.Component<Props, State> {
    constructor(props: Props) {
        super(props);
        
        this.state = {
            email: '',
            message: '',
        };

        this.confirmButtonPressed = this.confirmButtonPressed.bind(this);
        this.onInputChange = this.onInputChange.bind(this);     
        this.cancelButtonPressed = this.cancelButtonPressed.bind(this);   
    }

    render() {
        return ( 
            <div className="Modal">
                <Modal 
                    ariaHideApp={false}
                    isOpen={this.props.isModalOpen}
                    shouldCloseOnEsc={true}
                    shouldCloseOnOverlayClick={true}
                    onRequestClose={this.props.closeModal}
                    className={{
                        base: 'modal-content',
                        afterOpen: 'modal-content_after-open',
                        beforeClose: 'modal-content_before-close'
                    }}
                    overlayClassName={{
                        base: 'modal-overlay',
                        afterOpen: 'modal-overlay_after-open',
                        beforeClose: 'modal-overlay_before-close'
                    }}
                >
                    <div className="container edit-alarm-setpoint-modal">
                        <label className="label-large col-form-label text-medium-blue">
                            RESET PASSWORD
                        </label>
                        <div className="row form-group"/>
                        <div className="row form-group d-flex align-items-center">
                            <div className="col-4 label-medium text-medium-blue">
                                EMAIL
                            </div>
                            <div className="col-8">
                                <input 
                                    className="passwordbox-style"
                                    type="email"
                                    value={this.state.email}
                                    onChange={this.onInputChange}
                                    name="email"
                                />
                            </div>
                        </div>
                        <div className="row d-flex align-items-center">
                            <div className="col d-flex justify-content-center">
                                {this.state.message}
                            </div>
                        </div>
                        <div className="row form-group">
                            <div className="col">
                                <button
                                    className="btn btn-block button-standard confirm-button"
                                    tabIndex={2}
                                    onClick={this.confirmButtonPressed}
                                >
                                    CONFIRM
                                </button>
                            </div>
                            <div className="col">
                                <button
                                    className="btn btn-block button-standard cancel-button"
                                    tabIndex={3}
                                    onClick={this.cancelButtonPressed}
                                >
                                    CLOSE/CANCEL
                                </button>
                            </div>
                        </div>
                    </div>
                </Modal>
            </div>
        );
    }

    private onInputChange(e: React.SyntheticEvent<HTMLInputElement> | React.SyntheticEvent<HTMLSelectElement>) {
        this.setState({
            ...this.state,
            [e.currentTarget.name]: e.currentTarget.value
        });
    }

    private confirmButtonPressed() {
        accountApi.postResetEmail(this.state.email).then((result) => {
            if (result.success) {
                this.setState({
                    message: 'Success. Please check your email for validation code' 
                    // result.data!.message,
                });
            } else {
                this.setState({
                    message: 'Could not validate credentials',
                    email: '',
                });
            }
        });

    }

    private cancelButtonPressed() {
        this.setState({
            email: '',
            message: '',
        });
        this.props.closeModal();
    }
}
