import * as React from 'react';
import { DeviceConfiguration, Mode, Version, DeviceStatus } from 'src/models/Api';
import 'src/styles/toggleSwitch.css';
import { ConfigurationSelect, ConfigurationSelectType } from 'src/components/ConfigurationSelect';
import { EditDeviceControlModal } from './EditDeviceControlModal';
import { EditUVRFControlModal } from './EditUVRFControlModal';
import { ConfigurationToggle } from './ConfigurationToggle';
import { SystemControlComponent } from './SystemControlComponent';

export interface ModalProps {
    title: string;
    value: number;
    units: string;
    currentTitleLabel: string;
    editTitleLabel: string;
    propName: string;
}
interface State {
    isModalOpen: boolean;
    isUVRFModalOpen: boolean;
    modalProps: ModalProps;
}

export interface Props {
    version: Version;
    deviceConfig: DeviceConfiguration;
    deviceStatus: DeviceStatus;
    isDisconnected: boolean | null;
    isDisabled: boolean;
    editConfigValue: (name: string, value: string) => void;
    updateUVRF: () => void;
}

export class SystemControl extends React.Component<Props, State> {
    
    constructor(props: Props) {
        super(props);

        this.state = {
            isModalOpen: false,
            isUVRFModalOpen: false, 
            modalProps: {
                title: '',
                value: 0,
                currentTitleLabel: '',
                editTitleLabel: '',
                units: '',
                propName: '',
            },
        };
    }

    handleModeChange = (modeIndex: string) => {
        this.props.editConfigValue('Mode', modeIndex);
    }

    openEditModal = (props: ModalProps) => {
        this.setState({
            isModalOpen: true,
            modalProps: props
        });
    }

    openUVRFModal = (props: ModalProps) => {
        this.setState({
            isUVRFModalOpen: true,
            modalProps: props
        });
    }

    closeEditModal = () => {
        this.setState({ isModalOpen: false, isUVRFModalOpen: false });
        // Update page with latest uvrf table data
        if (this.props.deviceConfig.mode === Mode.uVRF) {
            this.props.updateUVRF();
        }
    }

    handleValueChange = (newValue: number) => {
        this.props.editConfigValue(this.state.modalProps.propName, `${newValue}`);
        this.setState({
            isModalOpen: false,
            isUVRFModalOpen: false,
            modalProps: {
                title: '',
                value: 0,
                currentTitleLabel: '',
                editTitleLabel: '',
                units: '',
                propName: '',
            }
        });
    }
    
    renderTargetRateEditButton = (): any => {
        switch (this.props.deviceConfig.mode) {
            case Mode.manual_time:
                return (
                    <button
                        className="button-status"
                        disabled={this.props.isDisabled || this.props.isDisconnected !== false}
                        onClick={() => {
                            this.openEditModal({
                                propName: 'RateTime',
                                title: 'CHANGE RATE',
                                currentTitleLabel: 'CURRENT TARGET RATE',
                                editTitleLabel: 'NEW TARGET RATE',
                                units: this.props.deviceConfig.rateTime.units,
                                value: this.props.deviceConfig.rateTime.value,
                            });
                        }}
                    >
                        <div className="row align-items-center text-box">
                            <div className="col">
                                CHANGE RATE
                            </div>
                        </div>
                    </button>
                );
            case Mode.manual_area:
                return (
                    <button
                        className="button-status"
                        disabled={this.props.isDisabled || this.props.isDisconnected !== false}
                        onClick={() => {
                            this.openEditModal({
                                propName: 'RateArea',
                                title: 'CHANGE RATE',
                                currentTitleLabel: 'CURRENT TARGET RATE',
                                editTitleLabel: 'NEW TARGET RATE',
                                units: this.props.deviceConfig.rateArea.units,
                                value: this.props.deviceConfig.rateArea.value,
                            });
                        }}
                    >
                        <div className="row align-items-center text-box">
                            <div className="col">
                                CHANGE RATE
                            </div>
                        </div>
                    </button>
                );
            case Mode.uVRF:
                return (
                    <button
                        className="button-status"
                        disabled={this.props.isDisabled || this.props.isDisconnected === true}
                        onClick={() => {
                            this.openUVRFModal({
                                propName: 'UVRF',
                                title: 'CONFIGURE UVRF',
                                currentTitleLabel: '',
                                editTitleLabel: '',
                                units: this.props.deviceConfig.rateArea.units,
                                value: 0,
                            });
                        }}
                    >
                        <div className="row align-items-center text-box">
                            <div className="col">
                                CONFIGURE UVRF
                            </div>
                        </div>
                    </button>
                );
            default:
                return (
                    <button
                        className="button-status"
                        disabled={this.props.isDisabled || this.props.isDisconnected === true}
                        onClick={() => {
                            this.openEditModal({
                                propName: 'RateArea',
                                title: 'CHANGE RATE',
                                currentTitleLabel: 'CURRENT APPLICATION RATE',
                                editTitleLabel: 'NEW APPLICATION RATE',
                                units: this.props.deviceConfig.rateArea.units,
                                value: this.props.deviceConfig.rateArea.value,
                            });
                        }}
                    >
                        <div className="row align-items-center text-box">
                            <div className="col">
                                CHANGE RATE
                            </div>
                        </div>
                    </button>
                );
        }
    }

    // Provide minimum version number to support the feature
    hideSelection = (major: number, minor: number, rev: number): boolean => {
        let validVersion = (this.props.version.major > major
            || this.props.version.major == major && this.props.version.minor > minor
            || this.props.version.major == major && this.props.version.minor == minor 
                                                 && this.props.version.revision >= rev);

        // Return wether to HIDE selection, so hide if NOT valid version
        return !validVersion;
    }
    render() {
        let modeOptions: ConfigurationSelectType[] = [
            { name: 'Select operating mode', value: 0, hidden: true },
            { name: 'Manual (gallons per hour)', value: Mode.manual_time },
            { name: 'Manual (gallons per acre)', value: Mode.manual_area, hidden: this.hideSelection(3, 0, 0) },
            { name: 'uVRF', value: Mode.uVRF, hidden: this.hideSelection(3, 0, 0) },
            { name: 'VRI/VRF', value: Mode.vri_vrf, hidden: this.hideSelection(2, 0, 0) },
            { name: 'Speed Sense', value: Mode.speed_sense, hidden: this.hideSelection(3, 0, 0) },
            { name: 'Flow Proportional - Basic', value: Mode.prop_basic, hidden: this.hideSelection(2, 0, 0) },
            { name: 'Flow Proportional - Center Pivot',
                value: Mode.prop_center},
                // hidden: this.hideSelection(2, 0, 0) },
            { name: 'Flow Proportional - Corner Pivot',
                value: Mode.prop_corner},
                // hidden: this.hideSelection(2, 0, 0) },
            { name: 'Flow Proportional - Drip', value: Mode.prop_drip, hidden: this.hideSelection(2, 0, 0) },
            { name: 'Flow Proportional - Golf', value: Mode.prop_golf, hidden: this.hideSelection(2, 0, 0) }
        ];

        return (
            
            <div 
                className={`component-standard w-100 
                    ${ (this.props.isDisabled || this.props.isDisconnected !== false) ? 'details-stale-data' : '' } `}
            >
                <EditDeviceControlModal
                    isModalOpen={this.state.isModalOpen}
                    title={this.state.modalProps.title}
                    units={this.state.modalProps.units}
                    currentTitleLabel={this.state.modalProps.currentTitleLabel}
                    editTitleLabel={this.state.modalProps.editTitleLabel}
                    value={this.state.modalProps.value}
                    closeModal={this.closeEditModal}
                    confirmValueChange={(v) => this.handleValueChange(v)}
                />
                <EditUVRFControlModal
                    isModalOpen={this.state.isUVRFModalOpen}
                    title={this.state.modalProps.title}
                    units={this.state.modalProps.units}
                    currentAngle={this.props.deviceStatus.pivotAngle}
                    currentTitleLabel={this.state.modalProps.currentTitleLabel}
                    deviceId={this.props.deviceConfig.id}
                    closeModal={this.closeEditModal}
                    latitude={this.props.deviceConfig.latitude}
                    longitude={this.props.deviceConfig.longitude}
                    pivotLength={this.props.deviceConfig.maximumWateringLength}
                    pivotSpeed={this.props.deviceStatus.pivotSpeed}
                    maxCyclePivot={this.props.deviceConfig.maxCyclePivot}
                    editConfigValue={this.props.editConfigValue}
                />
                {/* Header */}
                <div className="row">
                    <div className="col-8 label-large text-dark-blue">
                        CONTROL
                    </div>
                </div>

                {/* Injection Pump */}
                <div className="row content flex-container list-divider">
                    <div className="col-sm-4 text-medium-blue">
                        INJECTION PUMP
                    </div>
                    <div className="col-sm col-6 mr-sm-5">
                        <button 
                            className="btn button-start w-100"
                            disabled={this.props.isDisabled || this.props.isDisconnected !== false}
                            onClick={() => this.props.editConfigValue('InjectionPump', 'true')}
                        >
                            START
                        </button>
                    </div>
                    <div className="col-sm col-6">
                        <button
                            className="btn button-stop w-100"
                            disabled={this.props.isDisabled || this.props.isDisconnected !== false}
                            onClick={() => this.props.editConfigValue('StopInjectionPump', 'true')}
                        >
                            STOP
                        </button>
                    </div>
                </div>

                {/* Control Mode dropdown selection*/}
                <div className="row content flex-container list-divider">
                    <div className="col-sm-4 text-medium-blue">
                        MODE
                    </div>
                    <div className="col-sm-4">
                        <ConfigurationSelect
                            disabled={this.props.isDisabled || this.props.isDisconnected === true}
                            name="Mode"
                            value={this.props.deviceConfig.mode}
                            options={modeOptions}
                            editValue={this.props.editConfigValue}
                        />
                    </div>
                </div>

                {/* Target Rate */}
                { (this.props.deviceConfig.mode === Mode.prop_center || 
                this.props.deviceConfig.mode === Mode.prop_corner ||
                this.props.deviceConfig.mode === Mode.prop_drip ||
                this.props.deviceConfig.mode === Mode.speed_sense ||
                this.props.deviceConfig.mode === Mode.uVRF ||
                this.props.deviceConfig.mode === Mode.manual_area) &&
                <div className="row content flex-container list-divider">
                    <div className="col-sm-4 col-12 text-medium-blue">
                        APPLICATION RATE
                    </div>
                    <div className="col-sm-8 col-12">
                        <div className="row align-items-center">
                            <div className="col-8">
                                <span className="text-bold">
                                    {this.props.deviceConfig.rateArea.value}
                                </span>
                                <span className="ml-2">
                                    {this.props.deviceConfig.rateArea.units}
                                </span>
                            </div>
                            <p className="col-4 col-sm-3 justify-content-end">
                                {this.renderTargetRateEditButton()}
                            </p>
                        </div>
                    </div>
                </div>
                }

                {/* Target Rate - Manual Time */}
                { this.props.deviceConfig.mode === Mode.manual_time &&
                <div className="row content flex-container list-divider">
                    <div className="col-sm-4 col-12 text-medium-blue">
                        APPLICATION RATE
                    </div>
                    <div className="col-sm-8 col-12">
                        <div className="row align-items-center">
                            <div className="col-8">
                                <span className="text-bold">
                                    {this.props.deviceConfig.rateTime.value}
                                </span>
                                <span className="ml-2">
                                    {this.props.deviceConfig.rateTime.units}
                                </span>
                            </div>
                            <p className="col-4 col-sm-3 justify-content-end">
                                {this.renderTargetRateEditButton()}
                            </p>
                        </div>
                    </div>
                </div>
                }

                {/* Drip irrigated Area */}
                {this.props.deviceConfig.mode === Mode.prop_drip &&
                    <SystemControlComponent
                        fieldName="IRRIGATED AREA"
                        buttonName="AREA"
                        apiPropName="IrrigatedArea"
                        value={this.props.deviceConfig.irrigatedArea.value}
                        units={this.props.deviceConfig.irrigatedArea.units}
                        isDisabled={this.props.isDisabled || this.props.isDisconnected === true}
                        openEditModal={(modalProps) => this.openEditModal(modalProps)}
                        decimalPlaces={2}
                    />
                }
                
                {/* Irrigation Cycle Time */}
                {(this.props.deviceConfig.mode === Mode.prop_center ||
                    this.props.deviceConfig.mode === Mode.prop_corner ||
                    this.props.deviceConfig.mode === Mode.prop_drip ||
                    this.props.deviceConfig.mode === Mode.speed_sense) && 
                    <SystemControlComponent
                        fieldName="IRRIGATION CYCLE TIME"
                        buttonName="CYCLE TIME"
                        apiPropName="IrrigationCycleTime"
                        value={this.props.deviceConfig.irrigationCycleTime}
                        units="Hours"
                        isDisabled={this.props.isDisabled || this.props.isDisconnected === true}
                        openEditModal={(modalProps) => this.openEditModal(modalProps)}
                        decimalPlaces={2}
                    />
                }

                    {/* Degrees of Field Treated */}
                    {(this.props.deviceConfig.mode === Mode.prop_center ||
                        this.props.deviceConfig.mode === Mode.prop_corner) && 
                        <SystemControlComponent
                            fieldName="DEGREES OF FIELD BEING TREATED"
                            buttonName="DEGREES TREATED"
                            apiPropName="DegreesTreated"
                            value={this.props.deviceConfig.degreesTreated.value}
                            units={this.props.deviceConfig.degreesTreated.units}
                            isDisabled={this.props.isDisabled || this.props.isDisconnected === true}
                            openEditModal={(modalProps) => this.openEditModal(modalProps)}
                        />
                    }
                {/* UVRF Mode  */}
                {(this.props.deviceConfig.mode === Mode.uVRF) && 
                    <div>
                        <div className="row content flex-container list-divider">
                            <div className="col-sm-4 col-12 text-medium-blue">
                                CURRENT PIVOT POSITION
                            </div>
                            <div className="col-sm-8 col-12">
                                <div className="row align-items-center">
                                    <div className="col-8">
                                        <span className="text-bold">
                                            {this.props.deviceStatus.pivotAngle.toFixed(1)}
                                        </span>
                                        <span className="ml-2">
                                            {this.props.deviceConfig.degreesTreated.units}
                                        </span>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="row content flex-container list-divider">
                            <div className="col-sm-4 col-12 text-medium-blue">
                                CURRENT PIVOT SPEED
                            </div>
                            <div className="col-sm-8 col-12">
                                <div className="row align-items-center">
                                    <div className="col-8">
                                        <span className="text-bold">
                                            {this.props.deviceStatus.pivotSpeed.toFixed(1)}
                                        </span>
                                        <span className="ml-2">
                                            %
                                        </span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                }
                {/* VRI/VRF Mode */}
                {this.props.deviceConfig.mode === Mode.vri_vrf &&
                    <div>
                        <div className="row content flex-container list-divider">
                            <div className="col-sm-4 col-12 text-medium-blue">
                                0-5V
                            </div>
                            <div className="col-sm-8 col-12">
                                <div className="row align-items-center">
                                    <div className="col-sm-3 justify-content-end">
                                        <ConfigurationToggle
                                            checked={this.props.deviceConfig.b5volt}
                                            name="B5volt"
                                            editValue={(name, value) => this.props.editConfigValue(
                                                name, 
                                                value )}
                                            onLabel="5V"
                                            offLabel="0V"
                                            disabled={this.props.isDisabled 
                                                    || this.props.isDisconnected === true 
                                                    || this.props.version.major < 3}
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="row content flex-container list-divider">
                            <div className="col-sm-4 col-12 text-medium-blue">
                                0-10V
                            </div>
                            <div className="col-sm-8 col-12">
                                <div className="row align-items-center">
                                    <div className="col-sm-3 justify-content-end">
                                        <ConfigurationToggle
                                            checked={!this.props.deviceConfig.b5volt}
                                            name="B5volt"
                                            editValue={(name, value) => this.props.editConfigValue(
                                                name, 
                                                value == 'true' ? 'false' : 'true')}
                                            onLabel="10V"
                                            offLabel="0V"
                                            disabled={this.props.isDisabled 
                                                    || this.props.isDisconnected === true 
                                                    || this.props.version.major < 3}
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="row content flex-container list-divider">
                            <div className="col-sm-4 col-12 text-medium-blue">
                                FLOW COMPENSATION
                            </div>
                            <div className="col-sm-8 col-12">
                                <div className="row align-items-center">
                                    <div className="col-sm-3 justify-content-end">
                                        <ConfigurationToggle
                                            checked={this.props.deviceConfig.flowCompensation}
                                            name="FlowCompensation"
                                            editValue={(name, value) => this.props.editConfigValue(name, value)}
                                            onLabel="ON"
                                            offLabel="OFF"
                                            disabled={this.props.isDisabled || this.props.isDisconnected === true}
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                        <SystemControlComponent
                            fieldName="MAX FLOW"
                            buttonName="MAX FLOW"
                            apiPropName="MaxFlow"
                            value={this.props.deviceConfig.maxFlow.value}
                            units={this.props.deviceConfig.maxFlow.units}
                            isDisabled={this.props.isDisabled || this.props.isDisconnected === true}
                            openEditModal={(modalProps) => this.openEditModal(modalProps)}
                            decimalPlaces={2}
                        />
                        <SystemControlComponent
                            fieldName="PUMP PERCENT SETPOINT"
                            buttonName="PUMP %"
                            apiPropName="SetPumpAt"
                            value={this.props.deviceConfig.setPumpAt.value}
                            units={this.props.deviceConfig.setPumpAt.units}
                            isDisabled={this.props.isDisabled || this.props.isDisconnected === true}
                            openEditModal={(modalProps) => this.openEditModal(modalProps)}
                            decimalPlaces={1}
                        />
                    </div>
                }
                
                {/* Golf Mode */}
                {this.props.deviceConfig.mode === Mode.prop_golf && 
                    <div>
                        <SystemControlComponent
                            fieldName="APPLICATION 7 DAY"
                            buttonName="APP 7 DAY"
                            apiPropName="App7Day"
                            value={this.props.deviceConfig.app7Day.value}
                            units={this.props.deviceConfig.app7Day.units}
                            isDisabled={this.props.isDisabled || this.props.isDisconnected === true}
                            openEditModal={(modalProps) => this.openEditModal(modalProps)}
                            decimalPlaces={2}
                        />
                        <SystemControlComponent
                            fieldName="EVENT HOURS"
                            buttonName="EVENT HOURS"
                            apiPropName="EventHours"
                            value={this.props.deviceConfig.eventHours}
                            units="Hours"
                            isDisabled={this.props.isDisabled || this.props.isDisconnected === true}
                            openEditModal={(modalProps) => this.openEditModal(modalProps)}
                            decimalPlaces={2}
                        />
                        <SystemControlComponent
                            fieldName="TREATED ACRES"
                            buttonName="TREATED ACRES"
                            apiPropName="TreatedAcres"
                            value={this.props.deviceConfig.treatedAcres.value}
                            units={this.props.deviceConfig.treatedAcres.units}
                            isDisabled={this.props.isDisabled || this.props.isDisconnected === true}
                            openEditModal={(modalProps) => this.openEditModal(modalProps)}
                            decimalPlaces={2}
                        />
                        <SystemControlComponent
                            fieldName="EVENT 7 DAY"
                            buttonName="EVENT 7 DAY"
                            apiPropName="Event7Day"
                            value={this.props.deviceConfig.event7Day}
                            units=""
                            isDisabled={this.props.isDisabled || this.props.isDisconnected === true}
                            openEditModal={(modalProps) => this.openEditModal(modalProps)}
                        />
                    </div> 
                }
            </div>
        );
    }
}
