import * as React from 'react';
import { DeviceConfiguration, DeviceStatus, SystemStatus } from '../models/Api';
import '../styles/StatusIndicator.css';

interface State {
    isFound: boolean | null;
}

export interface Props {
    deviceId: number;
    deviceStatus: DeviceStatus;
    deviceConfiguration: DeviceConfiguration;
    heartbeat: Date;
    isDisconnected: boolean | null;
    tankLevelIsDerived: boolean;
}

export class DetailedStatus extends React.Component<Props, State> {

    constructor(props: Props) {
        super(props);

        this.state = {
            isFound: null
        };
    }

    getClassName = (): string => {
        let cName = 'col-6 col-sm-2 d-flex justify-content-center text-medium ';
        cName = cName + ` status-indicator-box ${SystemStatus[this.props.deviceStatus.systemStatus]}`;
        return cName;
    }

    render() {
        let status = { ...this.props.deviceStatus };
        let cName = '';
        this.props.isDisconnected ? cName = 'details-stale-data' : cName = '';
        
        // The tank level display can change depending on if the device has a tank level sensor.
        // This configures how to display it, and is rendered below.
        let tankLevellabel;
        if (this.props.tankLevelIsDerived === true) {
            tankLevellabel = (
                <div className="col text-medium-blue">
                    TANK LEVEL (DERIVED)
                </div>
            );
        } else {
            tankLevellabel = (
                <div className="col text-medium-blue">
                    TANK LEVEL
                </div>
            );
        } 

        return (

            <div className={`component-standard w-100 ${cName}`}>
            
                {/* Header */}
                <div className="row" style={{ 'marginRight': '-22px' }}>
                    <div className="col-6 col-sm-10 label-large text-dark-blue">
                        STATUS
                    </div>
                    <div className={this.getClassName()}>
                        <div className="align-self-center text-white text-bold">
                            {SystemStatus[status.systemStatus].toUpperCase().split('_').join(' ')}
                        </div>
                    </div>
                </div>

                {/* Rate */}
                <div className="row content flex-container list-divider">
                    <div className="col text-medium-blue">
                        INJECTION RATE - ACTUAL
                    </div>
                    <div className="col">
                        <div className="row">
                            <span className={`text-bold ml-2`}>
                                {status.rateTime.value.toFixed(1)}
                            </span>
                            <span className="ml-2">
                                {status.rateTime.units}
                            </span>
                        </div>
                    </div>
                </div>
                <div className="row content flex-container list-divider">
                    <div className="col text-medium-blue">
                        INJECTION RATE - CALCULATED
                    </div>
                    <div className="col">
                        <div className="row">
                            <span className={`text-bold ml-2`}>
                                {this.props.deviceConfiguration.calculatedRateTime.value}
                            </span>
                            <span className="ml-2">
                                {this.props.deviceConfiguration.calculatedRateTime.units}
                            </span>
                        </div>
                    </div>
                </div>

                {/* Discharge Pressure */}
                <div className="row content flex-container list-divider">
                    <div className="col text-medium-blue">
                        INJECTION PRESSURE
                    </div>
                    <div className="col">
                        <div className="row">
                            <span className={`text-bold ml-2`}>
                                {status.dischargePressure.value.toFixed(1)}
                            </span>
                            <span className="ml-2">
                                {status.dischargePressure.units}
                            </span>
                        </div>
                    </div>
                </div>

                {/* Tank Level */}
                <div className="row content flex-container list-divider">
                    {tankLevellabel}
                    <div className="col">
                        <div className="row">
                            <span className="text-bold ml-2">
                                {status.tankLevel.value}
                            </span>
                            <div className="col-6">
                                {status.tankLevel.units}
                            </div>
                        </div>
                    </div>
                </div>

                {/* Irrigation Flow */}
                <div className="row content flex-container list-divider">
                    <div className="col text-medium-blue">
                        IRRIGATION WATER FLOW
                    </div>
                    <div className="col">
                        <div className="row">
                            <span className={`text-bold ml-2`}>
                                {status.irrigationFlow.value.toFixed(0)}
                            </span>
                            <span className="ml-2">
                                {status.irrigationFlow.units}
                            </span>
                        </div>
                    </div>
                </div>

                {/* Pump Knob Setting */}
                <div className="row content flex-container list-divider">
                    <div className="col text-medium-blue">
                        SET PUMP KNOB TO:
                    </div>
                    <div className="col">
                        <div className="row">
                            <span className="text-bold ml-2">
                                {status.pumpKnobSetting.value.toFixed(1)}
                            </span>
                            <span className="ml-2">
                                {status.pumpKnobSetting.units}
                            </span>

                        </div>
                    </div>
                </div>

                {/* Field Preset/Application Preset Setting */}
                {status.currentField !== '' && status.currentField !== null &&
                <div className="row content flex-container list-divider">
                    <div className="col text-medium-blue">
                        APPLICATION PRESET:
                    </div>
                    <div className="col">
                        <div className="row">
                            <span className="text-bold ml-2">
                                {status.currentField}
                            </span>
                        </div>
                    </div>
                </div>
            }
            </div>
        );
    }
}