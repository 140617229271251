import * as React from 'react';
import { ModalProps } from './SystemControl';

export interface Props {
    fieldName: string;
    apiPropName: string;
    value: number;
    units: string;
    tankLevelIsDerived: boolean;
    openEditModal: (modalProps: ModalProps) => void;
    decimalPlaces?: number;
    isDisabled: boolean;
}

export class UpdateTankLevel extends React.Component<Props> {
    constructor(props: Props) {
        super(props);        
    }

    render() {
        return(
            <div className="row content flex-container list-divider">
                <div className="col-sm-4 col-12 text-medium-blue">
                    {this.props.fieldName}
                </div>
                <div className="col-sm-8 col-12">
                    <div className="row align-items-center">
                        <div className="col-8">
                            <span className="text-bold">
                                {this.props.decimalPlaces === undefined ? 
                                this.props.value : 
                                this.props.value.toFixed(this.props.decimalPlaces)
                                }
                            </span>
                            <span className="ml-2">
                                {this.props.units}
                            </span>
                        </div>
                        <div className="col-4 col-sm-3 justify-content-end">
                            <button
                                className="button-status"
                                disabled={this.props.isDisabled}
                                onClick={() => {
                                    this.props.openEditModal({
                                        propName: this.props.apiPropName,
                                        title: 'CHANGE TANK LEVEL',
                                        currentTitleLabel: 'CURRENT TANK LEVEL',
                                        editTitleLabel: 'ADD CHEMICAL',
                                        units: this.props.units,
                                        value: this.props.value,
                                    });
                                }}
                            >
                                <div className="text-box row align-items-center">
                                    {'CHANGE TANK LEVEL'}
                                </div>

                            </button>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}