import * as React from 'react';
import { Route, RouteProps, Redirect } from 'react-router-dom';
import * as identity from '../identity';

export interface Props extends RouteProps {
  roles?: string[];
}

const AuthorizedRoute = (props: Props) => (
    identity.isAuthenticated() ?
        props.roles === undefined || props.roles.includes(identity.getRole()) ?
            <Route {...props} /> :
            (<Redirect to={{ pathname: '/' }} />) :
        (<Redirect to={{ pathname: '/log-in', state: { from: props.location }}} />)
);

export default AuthorizedRoute;