import * as React from 'react';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import { UnitSettings } from '../models/Api';
import { UserSettingsUnits } from './UserSettingsUnits';
import * as unitSettingsApi from '../api/UnitSettings';

interface State {
    unitSettings: UnitSettings;
}

export interface Props extends RouteComponentProps<{}> {
}

class UnitSettingsSubPage extends React.Component<Props, State> {

    constructor(props: Props) {
        super(props);

        this.state = {
            unitSettings: {
                id: 0,
                userId: '',
                chemicalFlowUnits: 0,
                irrigationFlowUnits: 0,
                chemicalTotalUnits: 0,
                irrigationTotalUnits: 0,
                areaUnits: 0,
                lengthUnits: 0,
                applicationRateUnits: 0,
                pressureUnits: 0
            }
        };
    }

    componentDidMount() {
        // Get unit settings
        this.getUnitSettings();
        window.scrollTo(0, 0);
    }

    render() {
        return (
            <div className="subpage-standard">
                <div className="row component-standard">
                    <UserSettingsUnits 
                        unitSettings={this.state.unitSettings}
                        editUnitSettings={this.editUnitSettings}
                    />
                </div>
            </div>
        );
    }

    private getUnitSettings = () => {
        unitSettingsApi.getUnitSettings()
        .then((result) => {
            if (result.success && result.data) {
                // Device found - set state appropriately
                this.setState({
                    unitSettings: result.data!
                });
            }
        });
    }

    private editUnitSettings = (unitSettings: UnitSettings) => {
        unitSettingsApi.editUnitSettings(unitSettings)
        .then((result) => {
            // Regardless of success or failure, update unit settings
            this.getUnitSettings();
        });
    }
}

export default withRouter(UnitSettingsSubPage);