import { ApiResult, UnitSettings  } from '../models/Api';
import { httpRequest, Urls } from './Shared';

const UNITS_URL = '/UnitSettings';

export const getUnitSettings = (): Promise<ApiResult<UnitSettings>> => {
    return httpRequest(`${Urls.BASE_URL}${UNITS_URL}`);
};

export const editUnitSettings = (unitSettings: UnitSettings):  
    Promise<ApiResult<UnitSettings[]>> => {
    return httpRequest( `${Urls.BASE_URL}${UNITS_URL}`,
                        'put',
                        {
                            'chemicalFlowUnits': unitSettings.chemicalFlowUnits,
                            'irrigationFlowUnits': unitSettings.irrigationFlowUnits,
                            'chemicalTotalUnits': unitSettings.chemicalTotalUnits,
                            'irrigationTotalUnits': unitSettings.irrigationTotalUnits,
                            'areaUnits': unitSettings.areaUnits,
                            'lengthUnits': unitSettings.lengthUnits,
                            'applicationRateUnits': unitSettings.applicationRateUnits,
                            'pressureUnits': unitSettings.pressureUnits
                        });
};