import { ApiResult, DeviceHistory  } from '../models/Api';
import { httpRequest, Urls, downloadFile } from './Shared';

const DEVICEHISTORY_URL = '/DeviceHistory';
const DATA_URL = '/Data';
const EXPORT_URL = '/CsvExport';

export const getHistory = (deviceId: number): Promise<ApiResult<DeviceHistory>> => {
    return httpRequest(`${Urls.BASE_URL}${DEVICEHISTORY_URL}/${deviceId}`,
                       'get'
                        );
};

export const getHistoryRange = (deviceId: number, start: Date, end: Date): Promise<ApiResult<DeviceHistory>> => {
    const startString = start.toISOString();
    const endString = end.toISOString();
    const url = `${Urls.BASE_URL}${DEVICEHISTORY_URL}/${deviceId}${DATA_URL}/${startString}/${endString}`;
    console.log(`Getting historical data: ${url}`);
    return httpRequest(
        url,
        'get'
    );
};

export const exportHistoryReport =
    (deviceId: number, start: Date, end: Date, filename: String): Promise <boolean | void> => {
    // Can't download a file via AJAX so do some trickery
    const startString = start.toISOString();
    const endString = end.toISOString();
    // getTimezoneOffset returns the offset in minutes, so convert to hours.
    // also, it returns a positive number even though Denver is -6. Lets assume all the users will
    // be in the western hemisphere, so make the offset negative
    const tzOffset = end.getTimezoneOffset() / -60;
    const url = `${Urls.BASE_URL}${DEVICEHISTORY_URL}/${deviceId}${EXPORT_URL}/${startString}
    /${endString}/${tzOffset}/${filename}`;
    return downloadFile(url, `${filename}.csv`);
};
