import * as React from 'react';
import { withRouter, RouteComponentProps } from 'react-router-dom';
import * as deviceApi from '../api/Devices';
import { DeviceSummary, DeviceMapData, SystemStatus } from '../models/Api';
import Footer from '../components/Footer';
import Header from '../components/Header';
import { DevicesMap } from '../components/DevicesMap';
import '../styles/DevicesPage.css';
import { DevicesList } from 'src/components/DevicesList';
import * as moment from 'moment';

interface State {
    devices: DeviceSummary[];
    filteredDevices: DeviceSummary[];
    groupFilter: string;
}

export interface Props extends RouteComponentProps<{id: string}> {

}

class DevicesPage extends React.Component<Props, State> {
    private dataTimerId: number | null = null;
    constructor(props: Props) {
        super(props);

        let filter: string | null = sessionStorage.getItem('selected-group');
        if (filter === null) {
            filter = '';
        }
        let emptyDevice: DeviceSummary = {
            id: 0,
            name: '',
            group: '',
            customerId: 0,
            heartBeat: new Date(Date.now()),
            lastUpdated: moment(new Date(Date.now())).subtract(1, 'year').toDate(),
            systemStatus: null,
            rateTimeTarget: null,
            rateAreaTarget: null,
            rateTimeStatus: null,
            rateAreaStatus: null,
            total1: null,
            total2: null,
            latitude: null,
            longitude: null,
            oldHeartbeat: null,
            isDisabled: false,
            mode: 0,
            connectedTime: null,
            disconnectedTime: null,
            isDisconnected: null
        };
        this.state = {
            devices: [emptyDevice],
            filteredDevices: [],
            groupFilter: filter,
        };

        this.getDevices = this.getDevices.bind(this);
        this.updateDropdown = this.updateDropdown.bind(this);
    }

    componentDidMount() {
        this.getDevices();
        
        window.scrollTo(0, 0); 
    }

    componentWillUnmount() {
        if (this.dataTimerId !== null) {
            window.clearInterval(this.dataTimerId);
        }
    }

    render() {
        return (
            <div className="flex-root">
                <Header/>
                <div className="devices-page-header">
                    <div className="col">
                        <div className="row">
                            <div className="col-md-10">
                                <label className="content text-medium-blue label-large">
                                    SYSTEMS
                                </label>
                            </div>
                            <div className="col-md-2 text-medium-blue d-flex align-items-center ">
                                <span className="label-large text-medium-blue mr-4">
                                    GROUP
                                </span>
                                <select 
                                    className="dropdown-style float-right"
                                    value={this.state.groupFilter}
                                    onChange={this.updateDropdown}
                                >
                                    <option value="">All</option>
                                    {Array.from(new Set(this.state.devices.map(d => d.group))).map(
                                        d => d !== '' && <option key={d} value={d}>{d}</option>)
                                    }
                                </select>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="d-flex content-no-padding">
                    <div className="row w-100">
                        <div className="col-md-6 subpage-standard">
                            <div className="label-medium font-weight-bold text-medium-gray">
                                { (this.state.devices[0] && this.state.devices[0].name === '') ? 'Loading...' : 
                                    (this.state.filteredDevices.length) + ' system' + 
                                    (this.state.filteredDevices.length !== 1 && 's') + ' found' }
                            </div>
                            <br/>
                            <DevicesList devices={this.state.filteredDevices} />
                        </div>
                        <div className="col-md-6">
                            <div className="row devices-page-map">
                                <DevicesMap 
                                    devices={this.state.filteredDevices.map(d => this.getMapData(d))} 
                                />
                            </div>
                        </div>
                    </div>
                </div>
                <Footer/>
            </div>
        );
    }

    private getDevices() {
        if (this.dataTimerId !== null) {
            window.clearInterval(this.dataTimerId);
            this.dataTimerId = null;
        }

        deviceApi.getDevices().then((result) => {
            if (this.dataTimerId === null) {
                this.dataTimerId = window.setInterval(this.getDevices, 60000);
            }
            
            if (result.success) {
                // Device found - set state appropriately
                let filteredDevices = result.data!;
                if (this.state.groupFilter !== '') {
                    filteredDevices = result.data!.filter(d => d.group === this.state.groupFilter);
                }

                this.setState({
                    ...this.state,
                    devices: result.data!,
                    filteredDevices: filteredDevices
                });
            } else {
                console.log('get devices error: ' + result.error);
            }
        });
    }

    private updateDropdown(e: any) {
        let filteredDevices = this.state.devices;
        if (e.target.value !== '') {
            filteredDevices = this.state.devices.filter(d => d.group === e.target.value);
        }

        // Save the selected group to session data
        sessionStorage.setItem('selected-group', e.target.value);
        // Update dropdown state
        this.setState({
            groupFilter: e.target.value,
            filteredDevices: filteredDevices
        });
    }

    private getMapData(deviceSummary: DeviceSummary): DeviceMapData {
        let mapData: DeviceMapData = {
            id: deviceSummary.id,
            name: deviceSummary.name,
            group: deviceSummary.group,
            systemStatus: deviceSummary.isDisconnected ? SystemStatus.unknown : deviceSummary.systemStatus,
            irrigationSystemName: '',
            showPivotPoint: false,
            showLabel: true,
            deviceLatitude: deviceSummary.latitude,
            deviceLongitude: deviceSummary.longitude,
            pivotPointLatitude: 0,
            pivotPointLongitude: 0
        };

        return mapData;
    }
}

export default withRouter(DevicesPage);