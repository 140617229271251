import * as React from 'react';
import { DeviceAlarmSectionHeader } from './DeviceAlarmSectionHeader';
import { DeviceAlarmComponent } from 'src/components/DeviceAlarmComponent';
import { DeviceSimpleAlarmComponent } from 'src/components/DeviceSimpleAlarmComponent';
import { AlarmSetpoint, AccountInfo, Version } from '../models/Api';
import * as alarmSetpointApi from '../api/AlarmSetpoints';
import * as usersApi from '../api/Users';
import { getDevice } from 'src/api/Devices';

const setpointTypes = {
    ChemicalFlowHigh: 0,
    ChemicalFlowLow: 1,
    ChemicalPressureHigh: 2,
    ChemicalPressureLow: 3,
    TankLevelHigh: 4,
    TankLevelLow: 5,
    WaterFlowHigh: 6,
    WaterFlowLow: 7,
    ChemicalFlowTotal1: 8,
    ChemicalFlowTotal2: 9,
    WaterFlowTotal1: 10,
    WaterFlowTotal2: 11,
    OperatingTime1: 12,
    OperatingTime2: 13,
    WindSpeed: 14,
    Temperature: 15,
    PumpStatusChange: 16,
    ConnectionStatusChange: 17,
    WaterPressureHigh: 18,
    WaterPressureLow: 19
};

interface State {
    alarmSetpoints: AlarmSetpoint[];
    users: AccountInfo[];    
    isDisconnected: boolean | null;
}

export interface Props {
    deviceId: number;
    version: Version;
}

export class DeviceAlarms extends React.Component<Props, State> {
    private numRequests: number;
    private alarmUpdateTimerId: number | null = null;

    constructor(props: Props) {
        super(props);

        this.numRequests = 0;

        this.state = {
            alarmSetpoints: [],
            users: [],
            isDisconnected: false,
        };

    }

    componentDidMount() {
        this.numRequests = 0;
        this.getAlarmSetpoints();
        this.getUsers();
        this.getHeartbeatStatus();

        window.scrollTo(0, 0);
    }

    componentWillUnmount() {
        if (this.alarmUpdateTimerId !== null) {
            window.clearInterval(this.alarmUpdateTimerId);
        }
    }
    
    render() {

        let cName = this.state.isDisconnected ? 'alarms-stale-data' : '';

        let isStale: boolean = false;
        if (this.state.isDisconnected != null) {
            isStale = this.state.isDisconnected;
        }

        return (
            <div>
            <div className={cName}>
                <div className={`component-standard `}>
                    <DeviceAlarmSectionHeader
                        sectionName="CHEMICAL PRESSURE ALARM"
                        showTimeout={true}
                        isDisabled={isStale}
                        alarmSetpoint={this.getAlarmSetpoint(setpointTypes.ChemicalPressureHigh)}
                        editAlarmSetpoint={this.editAlarmSetpoint}
                    />
                    <DeviceAlarmComponent
                        setpointName="HIGH PRESSURE"
                        alarmSetpoint={this.getAlarmSetpoint(setpointTypes.ChemicalPressureHigh)}
                        editAlarmSetpoint={this.editAlarmSetpoint}
                        setpointPreValueString={this.getAlarmPreSetpointString(setpointTypes.ChemicalPressureHigh)}
                        setpointValueString={this.getAlarmSetpointValueString(setpointTypes.ChemicalPressureHigh)}
                        setpointPostValueString={this.getAlarmPostSetpointString(setpointTypes.ChemicalPressureHigh)}
                        users={this.state.users}
                        isDisabled={isStale}
                        showTimeout={true}
                    />
                    <DeviceAlarmComponent
                        setpointName="LOW PRESSURE"
                        alarmSetpoint={this.getAlarmSetpoint(setpointTypes.ChemicalPressureLow)}
                        editAlarmSetpoint={this.editAlarmSetpoint}
                        setpointPreValueString={this.getAlarmPreSetpointString(setpointTypes.ChemicalPressureLow)}
                        setpointValueString={this.getAlarmSetpointValueString(setpointTypes.ChemicalPressureLow)}
                        setpointPostValueString={this.getAlarmPostSetpointString(setpointTypes.ChemicalPressureLow)}
                        users={this.state.users}
                        isDisabled={isStale}
                        showTimeout={true}
                    />
                </div>
                <br />
                <div className="component-standard">
                    <DeviceAlarmSectionHeader
                        sectionName="CHEMICAL FLOW ALARM"
                        showTimeout={true}
                        isDisabled={isStale}
                        alarmSetpoint={this.getAlarmSetpoint(setpointTypes.ChemicalFlowHigh)}
                        editAlarmSetpoint={this.editAlarmSetpoint}
                    />
                    <DeviceAlarmComponent
                        setpointName="HIGH FLOW"
                        alarmSetpoint={this.getAlarmSetpoint(setpointTypes.ChemicalFlowHigh)}
                        editAlarmSetpoint={this.editAlarmSetpoint}
                        setpointPreValueString={this.getAlarmPreSetpointString(setpointTypes.ChemicalFlowHigh)}
                        setpointValueString={this.getAlarmSetpointValueString(setpointTypes.ChemicalFlowHigh)}
                        setpointPostValueString={this.getAlarmPostSetpointString(setpointTypes.ChemicalFlowHigh)}
                        users={this.state.users}
                        isDisabled={isStale}
                        showTimeout={true}
                    />
                    <DeviceAlarmComponent
                        setpointName="LOW FLOW"
                        alarmSetpoint={this.getAlarmSetpoint(setpointTypes.ChemicalFlowLow)}
                        editAlarmSetpoint={this.editAlarmSetpoint}
                        setpointPreValueString={this.getAlarmPreSetpointString(setpointTypes.ChemicalFlowLow)}
                        setpointValueString={this.getAlarmSetpointValueString(setpointTypes.ChemicalFlowLow)}
                        setpointPostValueString={this.getAlarmPostSetpointString(setpointTypes.ChemicalFlowLow)}
                        users={this.state.users}
                        isDisabled={isStale}
                        showTimeout={true}
                    />
                </div>
                <br />
                <div className="component-standard">
                    <DeviceAlarmSectionHeader
                        sectionName="WATER PRESSURE ALARM"
                        showTimeout={true}
                        isDisabled={isStale}
                        alarmSetpoint={this.getAlarmSetpoint(setpointTypes.WaterPressureHigh)}
                        editAlarmSetpoint={this.editAlarmSetpoint}
                    />
                    <DeviceAlarmComponent
                        setpointName="HIGH PRESSURE"
                        alarmSetpoint={this.getAlarmSetpoint(setpointTypes.WaterPressureHigh)}
                        editAlarmSetpoint={this.editAlarmSetpoint}
                        setpointPreValueString={this.getAlarmPreSetpointString(setpointTypes.WaterPressureHigh)}
                        setpointValueString={this.getAlarmSetpointValueString(setpointTypes.WaterPressureHigh)}
                        setpointPostValueString={this.getAlarmPostSetpointString(setpointTypes.WaterPressureHigh)}
                        users={this.state.users}
                        isDisabled={isStale}
                        showTimeout={true}
                    />
                    <DeviceAlarmComponent
                        setpointName="LOW PRESSURE"
                        alarmSetpoint={this.getAlarmSetpoint(setpointTypes.WaterPressureLow)}
                        editAlarmSetpoint={this.editAlarmSetpoint}
                        setpointPreValueString={this.getAlarmPreSetpointString(setpointTypes.WaterPressureLow)}
                        setpointValueString={this.getAlarmSetpointValueString(setpointTypes.WaterPressureLow)}
                        setpointPostValueString={this.getAlarmPostSetpointString(setpointTypes.WaterPressureLow)}
                        users={this.state.users}
                        isDisabled={isStale}
                        showTimeout={true}
                    />
                </div>
                <br />
                {this.props.version.major >= 3 && 
                <div>
                    <div className="component-standard">
                        <DeviceAlarmSectionHeader 
                            sectionName="IRRIGATION WATER FLOW" 
                            showTimeout={true}
                            isDisabled={isStale}
                            alarmSetpoint={this.getAlarmSetpoint(setpointTypes.WaterFlowHigh)}
                            editAlarmSetpoint={this.editAlarmSetpoint}
                        />
                        <DeviceAlarmComponent
                            setpointName="HIGH FLOW"
                            alarmSetpoint={this.getAlarmSetpoint(setpointTypes.WaterFlowHigh)}
                            editAlarmSetpoint={this.editAlarmSetpoint}
                            setpointPreValueString={this.getAlarmPreSetpointString(setpointTypes.WaterFlowHigh)}
                            setpointValueString={this.getAlarmSetpointValueString(setpointTypes.WaterFlowHigh)}
                            setpointPostValueString={this.getAlarmPostSetpointString(setpointTypes.WaterFlowHigh)}
                            users={this.state.users}
                            isDisabled={isStale}
                            disableSetpoint={true}
                        />
                        <DeviceAlarmComponent
                            setpointName="LOW FLOW"
                            alarmSetpoint={this.getAlarmSetpoint(setpointTypes.WaterFlowLow)}
                            editAlarmSetpoint={this.editAlarmSetpoint}
                            setpointPreValueString={this.getAlarmPreSetpointString(setpointTypes.WaterFlowLow)}
                            setpointValueString={this.getAlarmSetpointValueString(setpointTypes.WaterFlowLow)}
                            setpointPostValueString={this.getAlarmPostSetpointString(setpointTypes.WaterFlowLow)}
                            users={this.state.users}
                            isDisabled={isStale}
                        />
                    </div>
                    <br/>
                </div>}
                {this.props.version.major >= 2 && 
                <div>
                    <div className="component-standard">
                        <DeviceAlarmSectionHeader
                            sectionName="TANK LEVEL ALARM"
                            showTimeout={false}
                            isDisabled={isStale}
                            alarmSetpoint={this.getAlarmSetpoint(setpointTypes.TankLevelHigh)}
                            editAlarmSetpoint={this.editAlarmSetpoint}
                        />
                        <DeviceAlarmComponent
                            setpointName="HIGH LEVEL"
                            alarmSetpoint={this.getAlarmSetpoint(setpointTypes.TankLevelHigh)}
                            editAlarmSetpoint={this.editAlarmSetpoint}
                            setpointPreValueString={this.getAlarmPreSetpointString(setpointTypes.TankLevelHigh)}
                            setpointValueString={this.getAlarmSetpointValueString(setpointTypes.TankLevelHigh)}
                            setpointPostValueString={this.getAlarmPostSetpointString(setpointTypes.TankLevelHigh)}
                            users={this.state.users}
                            isDisabled={isStale}
                            showTimeout={true}
                        />
                        <DeviceAlarmComponent
                            setpointName="LOW LEVEL"
                            alarmSetpoint={this.getAlarmSetpoint(setpointTypes.TankLevelLow)}
                            editAlarmSetpoint={this.editAlarmSetpoint}
                            setpointPreValueString={this.getAlarmPreSetpointString(setpointTypes.TankLevelLow)}
                            setpointValueString={this.getAlarmSetpointValueString(setpointTypes.TankLevelLow)}
                            setpointPostValueString={this.getAlarmPostSetpointString(setpointTypes.TankLevelLow)}
                            users={this.state.users}
                            isDisabled={isStale}
                            showTimeout={true}
                        />
                    </div>
                    <br />
                </div>}
                <div className="component-standard">
                    <DeviceAlarmSectionHeader
                        sectionName="UTILITY ALARMS"
                        isDisabled={false}
                        editAlarmSetpoint={this.editAlarmSetpoint}
                    />
                    <DeviceAlarmComponent
                        setpointName="CHEMICAL FLOW"
                        alarmSetpoint={this.getAlarmSetpoint(setpointTypes.ChemicalFlowTotal1)}
                        editAlarmSetpoint={this.editAlarmSetpoint}
                        setpointPreValueString={this.getAlarmPreSetpointString(setpointTypes.ChemicalFlowTotal1)}
                        setpointValueString={this.getAlarmSetpointValueString(setpointTypes.ChemicalFlowTotal1)}
                        setpointPostValueString={this.getAlarmPostSetpointString(setpointTypes.ChemicalFlowTotal1)
                             + ' (one time alarm)'}
                        users={this.state.users}
                        disableDivider={true}
                        includeTotalizer={false}
                        isDisabled={isStale}
                    />
                    <DeviceAlarmComponent
                        setpointName=""
                        alarmSetpoint={this.getAlarmSetpoint(setpointTypes.ChemicalFlowTotal2)}
                        editAlarmSetpoint={this.editAlarmSetpoint}
                        setpointPreValueString={this.getAlarmPreSetpointString(setpointTypes.ChemicalFlowTotal2)}
                        setpointValueString={this.getAlarmSetpointValueString(setpointTypes.ChemicalFlowTotal2)}
                        setpointPostValueString={this.getAlarmPostSetpointString(setpointTypes.ChemicalFlowTotal2)
                             + ' (repeat alarm)'}
                        users={this.state.users}
                        includeTotalizer={false}
                        isDisabled={isStale}
                        disablePumpPivotStops={true}
                    />
                    <DeviceAlarmComponent
                        setpointName="IRRIGATION WATER FLOW"
                        alarmSetpoint={this.getAlarmSetpoint(setpointTypes.WaterFlowTotal1)}
                        editAlarmSetpoint={this.editAlarmSetpoint}
                        setpointPreValueString={this.getAlarmPreSetpointString(setpointTypes.WaterFlowTotal1)}
                        setpointValueString={this.getAlarmSetpointValueString(setpointTypes.WaterFlowTotal1)}
                        setpointPostValueString={this.getAlarmPostSetpointString(setpointTypes.WaterFlowTotal1)
                             + ' (one time alarm)'}
                        users={this.state.users}
                        disableDivider={true}
                        includeTotalizer={false}
                        isDisabled={isStale}
                    />
                    <DeviceAlarmComponent
                        setpointName=""
                        alarmSetpoint={this.getAlarmSetpoint(setpointTypes.WaterFlowTotal2)}
                        editAlarmSetpoint={this.editAlarmSetpoint}
                        setpointPreValueString={this.getAlarmPreSetpointString(setpointTypes.WaterFlowTotal2)}
                        setpointValueString={this.getAlarmSetpointValueString(setpointTypes.WaterFlowTotal2)}
                        setpointPostValueString={this.getAlarmPostSetpointString(setpointTypes.WaterFlowTotal2)
                             + ' (repeat alarm)'}
                        users={this.state.users}
                        disableDivider={false}
                        includeTotalizer={false}
                        isDisabled={isStale}
                        disablePumpPivotStops={true}
                    />
                    <DeviceAlarmComponent
                        setpointName="OPERATING TIME"
                        alarmSetpoint={this.getAlarmSetpoint(setpointTypes.OperatingTime1)}
                        editAlarmSetpoint={this.editAlarmSetpoint}
                        setpointPreValueString={this.getAlarmPreSetpointString(setpointTypes.OperatingTime1)}
                        setpointValueString={this.getAlarmSetpointValueString(setpointTypes.OperatingTime1)}
                        setpointPostValueString={this.getAlarmPostSetpointString(setpointTypes.OperatingTime1)
                             + ' (one time alarm)'}
                        users={this.state.users}
                        disableDivider={true}
                        isDisabled={isStale}
                    />
                    <DeviceAlarmComponent
                        setpointName=""
                        alarmSetpoint={this.getAlarmSetpoint(setpointTypes.OperatingTime2)}
                        editAlarmSetpoint={this.editAlarmSetpoint}
                        setpointPreValueString={this.getAlarmPreSetpointString(setpointTypes.OperatingTime2)}
                        setpointValueString={this.getAlarmSetpointValueString(setpointTypes.OperatingTime2)}
                        setpointPostValueString={this.getAlarmPostSetpointString(setpointTypes.OperatingTime2)
                             + ' (repeat alarm)'}
                        users={this.state.users}
                        disableDivider={false}
                        isDisabled={isStale}
                        disablePumpPivotStops={true}
                    />
                </div>
                </div >
                <br/>
                <div className="component-standard">
                    <div className="row">
                        <div className="col-12 label-large text-dark-blue">
                            STATUS CHANGE NOTIFICATION
                        </div>
                    </div>
                    <div className="component">
                        <div className="row d-none d-xl-flex text-medium-blue">
                            <div className="col-2" />
                            <div className="col-1 "> ENABLE </div>
                            <div className="col-5 "/>
                            <div className="col-3"> NOTIFY </div>
                            <div className="col-1" />
                        </div>
                    </div>
                    <DeviceSimpleAlarmComponent
                        setpointName="PUMP STATUS"
                        alarmSetpoint={this.getAlarmSetpoint(setpointTypes.PumpStatusChange)}
                        editAlarmSetpoint={this.editAlarmSetpoint}
                        users={this.state.users}
                        disableDivider={false}
                        isDisabled={false} // not sent to PLC, so it can always be enabled
                    />
                    <DeviceSimpleAlarmComponent
                        setpointName="CONNECTION STATUS"
                        alarmSetpoint={this.getAlarmSetpoint(setpointTypes.ConnectionStatusChange)}
                        editAlarmSetpoint={this.editAlarmSetpoint}
                        users={this.state.users}
                        disableDivider={true}
                        isDisabled={false} // not sent to PLC, so it can always be enabled
                    />
                </div>

                {/* <div className="component-standard">
                    <DeviceAlarmSectionHeader
                        sectionName="WEATHER" 
                    />
                    <DeviceAlarmComponent
                        setpointName="WIND SPEED"
                        alarmSetpoint={this.getAlarmSetpoint(setpointTypes.WindSpeed)}
                        editAlarmSetpoint={this.editAlarmSetpoint}
                        setpointPreValueString={this.getAlarmPreSetpointString(setpointTypes.WindSpeed)}
                        setpointValueString={this.getAlarmSetpointValueString(setpointTypes.WindSpeed)}
                        setpointPostValueString={this.getAlarmPostSetpointString(setpointTypes.WindSpeed)}
                        users={this.state.users}
                    />
                    <DeviceAlarmComponent
                        setpointName="TEMPERATURE"
                        alarmSetpoint={this.getAlarmSetpoint(setpointTypes.Temperature)}
                        editAlarmSetpoint={this.editAlarmSetpoint}
                        setpointPreValueString={this.getAlarmPreSetpointString(setpointTypes.Temperature)}
                        setpointValueString={this.getAlarmSetpointValueString(setpointTypes.Temperature)}
                        setpointPostValueString={this.getAlarmPostSetpointString(setpointTypes.Temperature)}
                        users={this.state.users}
                    />
                </div> */}
            </div>
        );
    }

    private getAlarmSetpoints = () => {
        if (this.alarmUpdateTimerId !== null) {
            window.clearInterval(this.alarmUpdateTimerId);
            this.alarmUpdateTimerId = null;
        }
        if (this.numRequests === 0) {
            alarmSetpointApi.getAlarmSetpoints(this.props.deviceId)
            .then((result) => {
                if (this.alarmUpdateTimerId === null) {
                    this.alarmUpdateTimerId = window.setInterval(this.getAlarmSetpoints, 5000);
                }

                if (result.success && result.data && this.numRequests === 0) {
                    // Device found - set state appropriately
                    this.setState({
                        ...this.state,
                        alarmSetpoints: result.data!
                    });
                }
            });
        }
    }

    private getUsers() {
        usersApi.getUsers().then((result) => {
            if (result.success && result.data) {
                // Device found - set state appropriately
                this.setState({
                    ...this.state,
                    users: result.data!
                });
            }
        });
    }

    private getHeartbeatStatus = () => {
        getDevice(this.props.deviceId).then((result) => {
            if (result.success) {
                // Device found - set state appropriately
                this.setState({
                    ...this.state,
                    isDisconnected: result.data!.isDisconnected,
                });
            }
        });
    }

    // Send updated alarm setpoint details to the API
    private editAlarmSetpoint = async (setpoint: AlarmSetpoint): Promise<any> => {
        // Increment number of requests so that we don't update local state until this request resolves
        this.numRequests = this.numRequests + 1;

        // Perform 'optimistic' update and update local state with new alarm data
        let alarms = this.state.alarmSetpoints;
        alarms[alarms.findIndex(a => a.alarmSetpointType === setpoint.alarmSetpointType)] = setpoint;

        // Update state
        this.setState({
            alarmSetpoints: alarms
        });

        // Actually call the edit alarm API method
        await alarmSetpointApi.editAlarmSetpoint(this.props.deviceId, setpoint);

        // Decrement number of requests since this one has resolved
        this.numRequests = this.numRequests - 1;

        // Call for update to alarm list if there are no more pending requests
        this.getAlarmSetpoints();
    }

    private getAlarmSetpoint = (setpointType: number): AlarmSetpoint | undefined => {
        if (this.state.alarmSetpoints.length <= 0) {
            return undefined;
        }

        // Try to find the alarm setpoint
        return this.state.alarmSetpoints.find(s => s.alarmSetpointType.toString() === setpointType.toString());
    }

    private getAlarmPreSetpointString = (setpointType: number): string => {
        // Try to get the setpoint from the state
        let setpoint = this.getAlarmSetpoint(setpointType);

        if (setpoint === undefined) {
            return '';
        }

        // Got the setpoint. Depending on type get appropriate string
        switch (setpoint.alarmSetpointType) {
            case setpointTypes.ChemicalPressureHigh:
            case setpointTypes.ChemicalPressureLow:
            case setpointTypes.WaterPressureHigh:
            case setpointTypes.WaterPressureLow:
            case setpointTypes.TankLevelHigh:
            case setpointTypes.TankLevelLow:
            case setpointTypes.WaterFlowHigh:
            case setpointTypes.WaterFlowLow:
            case setpointTypes.WindSpeed:
                return '';

            case setpointTypes.ChemicalFlowTotal1:
            case setpointTypes.WaterFlowTotal1:
            case setpointTypes.OperatingTime1:
                return 'After ';

            case setpointTypes.ChemicalFlowTotal2:
            case setpointTypes.WaterFlowTotal2:
            case setpointTypes.OperatingTime2:
                return 'Every ';

            default:
                return '';
        }
    }

    private getAlarmSetpointValueString = (setpointType: number): string  => {
        // Try to get the setpoint from the state
        let setpoint = this.getAlarmSetpoint(setpointType);

        if (setpoint === undefined) {
            return '';
        }

        // Got the setpoint. Depending on type get appropriate string
        switch (setpoint.alarmSetpointType) {
            case setpointTypes.ChemicalPressureHigh:
            case setpointTypes.ChemicalPressureLow:
            case setpointTypes.TankLevelHigh:
            case setpointTypes.TankLevelLow:
            case setpointTypes.WaterFlowHigh:
            case setpointTypes.WaterFlowLow:
            case setpointTypes.WindSpeed:
            case setpointTypes.Temperature:
            case setpointTypes.ChemicalFlowHigh:
            case setpointTypes.ChemicalFlowLow:
            case setpointTypes.ChemicalFlowTotal1:
            case setpointTypes.WaterFlowTotal1:
            case setpointTypes.ChemicalFlowTotal2:
            case setpointTypes.WaterFlowTotal2:
            case setpointTypes.OperatingTime1:
            case setpointTypes.OperatingTime2:
            case setpointTypes.WaterPressureHigh:
            case setpointTypes.WaterPressureLow:
                return `${setpoint.setpoint.toLocaleString()}`;
            default:
                return '';
        }
    }

    private getAlarmPostSetpointString = (setpointType: number): string => {
        // Try to get the setpoint from the state
        let setpoint = this.getAlarmSetpoint(setpointType);

        if (setpoint === undefined) {
            return '';
        }

        // Got the setpoint. Depending on type get appropriate string
        switch (setpoint.alarmSetpointType) {
            case setpointTypes.ChemicalPressureHigh:
            case setpointTypes.ChemicalPressureLow:
            case setpointTypes.TankLevelHigh:
            case setpointTypes.TankLevelLow:
            case setpointTypes.WaterFlowHigh:
            case setpointTypes.WaterFlowLow:
            case setpointTypes.WaterPressureHigh:
            case setpointTypes.WaterPressureLow:
            case setpointTypes.WindSpeed:
                return ` ${setpoint.units}`;

            case setpointTypes.Temperature:
                return `${setpoint.units}`;

            case setpointTypes.ChemicalFlowHigh:
                return `${setpoint.units} above target rate`;

            case setpointTypes.ChemicalFlowLow:
                return `${setpoint.units} below target rate`;

            case setpointTypes.ChemicalFlowTotal1:
            case setpointTypes.ChemicalFlowTotal2:
                return ` total ${setpoint.units} of chemical`;

            case setpointTypes.WaterFlowTotal1:
            case setpointTypes.WaterFlowTotal2:
                return ` total ${setpoint.units} on Totalizer`;

            case setpointTypes.OperatingTime1:
                return ` ${setpoint.units} from alarm enable`;
            case setpointTypes.OperatingTime2:
                return ` ${setpoint.units} the system is running`;

            default:
                return '';
        }
    }
}
