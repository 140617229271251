import * as React from 'react';
import '../styles/Modal.css';
import * as Modal from 'react-modal';
import * as deviceApi from '../api/Devices';
import * as userApi from '../api/Users';
import { isAdminRole } from 'src/identity';
import { ConfigurationSelect } from './ConfigurationSelect';
import { DropdownList, CustomerInfo } from 'src/models/Api';
import { getCustomers } from 'src/api/Users';

interface State {
    name: string;
    group: string;
    userCustomerId: number;
    customerId: number;
    customerOptions: DropdownList[];
    success: boolean;
    message: string;
    showMessage: boolean;
}

export interface Props {
    isModalOpen: boolean;
    closeModal: () => void;
}

export class AddDeviceModal extends React.Component<Props, State> {
    constructor(props: Props) {
        super(props);

        this.state = {
            name: '',
            group: '',
            success: false,
            message: '',
            showMessage: false,
            userCustomerId: -1,
            customerId: -1,
            customerOptions: []
        };

        this.getDefaultCustomerId = this.getDefaultCustomerId.bind(this);
        this.closeOrCancelButtonPressed = this.closeOrCancelButtonPressed.bind(this);
    }

    componentDidMount() {
        if (isAdminRole()) {
            this.getDropDownOptions();
            this.getDefaultCustomerId();
        }
    }

    getDropDownOptions = () => {
        // Variables to promises
        // Wait till all promises are fulfilled to set the state once
        let cPromise: Promise<CustomerInfo[]>;
    
        cPromise = getCustomers();

        Promise.resolve(cPromise).then(value =>
        this.setDropDownOptions(value)
        );
    }
    
      // Given an array of arrays, set the values as the drop down options:
    setDropDownOptions = ( value: CustomerInfo[] ) => {
        let cOptions = value.map(c => {
            return { name: c.name, value: c.id };
        });

        // Add an empty selection to the beginning of the options
        cOptions.unshift({ name: '', value: 0 });

        this.setState({
            customerOptions: cOptions,
        });
    }

    render() {
        return ( 
            <div className="Modal">
                <Modal 
                    ariaHideApp={false}
                    isOpen={this.props.isModalOpen}
                    shouldCloseOnEsc={true}
                    shouldCloseOnOverlayClick={true}
                    onRequestClose={this.props.closeModal}
                    className={{
                        base: 'modal-content',
                        afterOpen: 'modal-content_after-open',
                        beforeClose: 'modal-content_before-close'
                    }}
                    overlayClassName={{
                        base: 'modal-overlay',
                        afterOpen: 'modal-overlay_after-open',
                        beforeClose: 'modal-overlay_before-close'
                    }}
                >
                    <div className="container edit-alarm-setpoint-modal">
                        <label className="label-large col-form-label text-medium-blue">
                            ADD DEVICE
                        </label>
                        <div className="row form-group"/>
                        <div className="row form-group d-flex align-items-center">
                            <div className="col-sm-5 label-medium text-medium-blue">
                                SERIAL NUMBER
                            </div>
                            <div className="col">
                                <input
                                    className="textbox-style w-100"
                                    placeholder="Serial Number"
                                    name="name"
                                    value={this.state.name}
                                    onChange={this.onInputChange}
                                    tabIndex={1}
                                />
                            </div>
                        </div>
                        <div className="row form-group d-flex align-items-center">
                            <div className="col-sm-5 label-medium text-medium-blue">
                                GROUP
                            </div>
                            <div className="col">
                                <input
                                    className="textbox-style w-100"
                                    placeholder="Group"
                                    name="group"
                                    value={this.state.group}
                                    onChange={this.onInputChange}
                                    tabIndex={1}
                                />
                            </div>
                        </div>
                        {/* Only show custom selection dropdown if the current user is an Admin */}
                        {isAdminRole() &&
                        <div className="row form-group d-flex align-items-center">
                            <div className="col-sm-5 label-medium text-medium-blue">
                                CUSTOMER
                            </div>
                            <div className="col">
                                <div className="row justify-content-end align-items-center">
                                    <div className="col align-items-center">
                                    <ConfigurationSelect
                                        name=" "
                                        value={this.state.customerId}
                                        options={this.state.customerOptions}
                                        editValue={this.handleCustomerEdit}
                                    />
                                    </div>
                                </div>
                            </div>
                        </div>}

                        { this.state.showMessage &&
                            <div>
                                {
                                    this.state.success ?
                                        <div>
                                            <div className="text-dark-gray">
                                                Device created. Please use the key below:
                                            </div>
                                            <div className="key-display">
                                                {this.state.message}
                                            </div>
                                        </div>
                                    :
                                    <span className="text-dark-gray">{this.state.message}</span>
                                }
                            </div>
                        }
                        { this.state.success &&
                            <div className="row form-group">
                                <div className="col">
                                    <button
                                        className="btn btn-block button-standard confirm-button"
                                        tabIndex={2}
                                        onClick={this.closeOrCancelButtonPressed}
                                    >
                                        Close
                                    </button>
                                </div>
                            </div>
                        }
                        { !this.state.success &&
                            <div className="row form-group">
                                <div className="col">
                                    <button
                                        className="btn btn-block button-standard confirm-button"
                                        tabIndex={2}
                                        onClick={this.confirmButtonPressed}
                                    >
                                        Confirm
                                    </button>
                                </div>
                                <div className="col">
                                    <button
                                        className="btn btn-block button-standard cancel-button"
                                        tabIndex={3}
                                        onClick={this.closeOrCancelButtonPressed}
                                    >
                                        Cancel
                                    </button>
                                </div>
                            </div>
                        }
                    </div>
                </Modal>
            </div>
        );
    }

    private getDefaultCustomerId() {
        userApi.getUser().then((result) => {
            if (result.success && result.data) {
                this.setState({
                    ...this.state,
                    userCustomerId: result.data.customerId,
                    customerId: result.data.customerId
                });
            } else {
                this.setState({
                    ...this.state,
                    userCustomerId: -1,
                    customerId: -1
                });
            }
        });
    }

    private onInputChange = (e: React.SyntheticEvent<HTMLInputElement> | React.SyntheticEvent<HTMLSelectElement>) => {
        this.setState({
            ...this.state,
            [e.currentTarget.name]: e.currentTarget.value
        });
    }

    private handleCustomerEdit = (name: string, value: string) => {
        this.setState({customerId: parseInt(value, 10)});
    }

    private confirmButtonPressed = () => {
        deviceApi.addDevice(this.state.name, this.state.group, this.state.customerId)
        .then((result) => {
            if (result.success && result.data) {
                this.setState({
                    ...this.state,
                    success: true,
                    message: result.data.iotKey,
                    showMessage: true
                });
            } else {
                this.setState({
                    ...this.state,
                    success: false,
                    message: 'Error creating device. Please try again.',
                    showMessage: true
                });
            }
        });

    }

    private closeOrCancelButtonPressed = () => {
        this.setState({
            ...this.state,
            name: '',
            group: '',
            success: false,
            message: '',
            showMessage: false,
            customerId: this.state.userCustomerId
        });
        this.props.closeModal();
    }
}
