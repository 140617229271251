import * as React from 'react';
import {createRoot} from "react-dom/client"
import { Router, Route, Switch } from 'react-router-dom';
// import App from './App';
import AuthorizedRoute from './components/AuthorizedRoute';
import { history } from './history';
// import AboutPage from './pages/AboutPage';
import AlarmsPage from './pages/AlarmsPage';
import DevicesPage from './pages/DevicesPage';
import DeviceDetailsPage from './pages/DeviceDetailsPage';
import './styles/index.css';
import AdminPage from './pages/AdminPage';
import UserPage from './pages/UserPage';
import LoginPage from './pages/LoginPage';

const rootElement = document.getElementById('root')!;
const root = createRoot(rootElement);

root.render(
  <Router history={history}>    
    <Switch>
      <AuthorizedRoute path="/" exact={true} component={DevicesPage} />
      <AuthorizedRoute path="/systems" component={DevicesPage} />
      <AuthorizedRoute path="/device/:id" component={DeviceDetailsPage} />
      <AuthorizedRoute path="/alarms" component={AlarmsPage} />
      <AuthorizedRoute path="/admin" component={AdminPage} />
      <AuthorizedRoute path="/user" component={UserPage} />
      <Route path="/log-in:email?:token?" component={LoginPage} />
    </Switch>
  </Router>,
);
