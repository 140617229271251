import { ApiResult, Alarm } from '../models/Api';
import { httpRequest, Urls } from './Shared';

const ALARMS_URL = '/Alarms';

export const getAlarms = (): Promise<ApiResult<Alarm[]>> => {
    return httpRequest(`${Urls.BASE_URL}${ALARMS_URL}`,
                       'get'
                        );
};
