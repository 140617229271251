// Table styles
export const columnHeaderStyle = {
    textAlign: 'left',
    fontWeight: 'bold',
    background:  '#ffffff',
    font: '18px',
    borderRadius: '2px'
};
  
export const dataStyle = {
    textAlign: 'left',
};
 
export const columnSubHeaderStyle = {
    textAlign: 'left',
    fontWeight: 'normal',
    background:  '#ffffff',
};