import * as React from 'react';
import { AlarmSetpoint, AccountInfo } from '../models/Api';
import { ConfigurationToggleAsync } from './ConfigurationToggleAsync';
import 'react-toggle/style.css';
import { EditAlarmSetpointModal } from 'src/components/EditAlarmSetpointModal';
// const settingsIcon = require('../images/settings.png');

export interface Props {
    alarmSetpoint?: AlarmSetpoint;
    setpointName: string;
    setpointPreValueString: string;
    setpointValueString: string;
    setpointPostValueString: string;
    editAlarmSetpoint: (alarmSetpoint: AlarmSetpoint) => Promise<any>;
    includeTotalizer?: boolean;
    disableDivider?: boolean;
    disablePumpPivotStops?: boolean;
    disableSetpoint?: boolean;
    showTimeout?: boolean;
    users: AccountInfo[];
    isDisabled: boolean;
}

interface State {
    editSetpointModalOpen: boolean;
}

export class DeviceAlarmComponent extends React.Component<Props, State> {
    constructor(props: Props) {
        super(props);

        this.state = {
            editSetpointModalOpen: false
        };

    }

    render() {
        if (this.props.alarmSetpoint === undefined) {
            return (<div/>);
        } else {
            let className = 'row d-flex align-items-center mb-xl-0 mb-4 list-divider';
            if (this.props.disableDivider) {
                className = 'row d-flex align-items-center mb-xl-0 mb-4';
            }
            let setpointColName = 'col-12 col-xl-3';

            return (
                <div className={className}>
                    <EditAlarmSetpointModal
                        setpoint={this.props.alarmSetpoint}
                        editSetpoint={this.props.editAlarmSetpoint}
                        isModalOpen={this.state.editSetpointModalOpen}
                        closeModal={this.closeModal}
                        setpointPreValueString={this.props.setpointPreValueString}
                        setpointValueString={this.props.setpointValueString}
                        setpointPostValueString={this.props.setpointPostValueString}
                        includeTotalizer={this.props.includeTotalizer}
                        users={this.props.users}
                        showTimeout={this.props.showTimeout}
                        disableSetpoint={this.props.disableSetpoint}
                    />
                    <div className="col-7 col-xl-2 text-medium-blue">
                        {this.props.setpointName}
                    </div>
                    <div className="col-5 col-xl-1 ">
                        <ConfigurationToggleAsync
                            checked={this.props.alarmSetpoint.active}
                            name="active"
                            editValue={this.toggleAlarm}
                            onLabel="On"
                            offLabel="Off"
                            disabled={this.props.isDisabled}
                        />
                    </div>
                    <div className={setpointColName}>
                        <span>{this.props.setpointPreValueString}</span>
                        <span className="text-bold">{this.props.setpointValueString}</span>
                        <span>{this.props.setpointPostValueString}</span>
                    </div>

                    <div className="col-7 d-flex d-xl-none">
                        <div className="text-medium-blue">
                            KILL PUMP
                        </div>
                    </div>
                    <div className="col-5 col-xl-1">
                        {!this.props.disablePumpPivotStops &&
                        <ConfigurationToggleAsync
                            checked={this.props.alarmSetpoint.killPump}
                            name="killPump"
                            editValue={this.toggleAlarm}
                            onLabel="On"
                            offLabel="Off"
                            disabled={this.props.isDisabled || !this.props.alarmSetpoint.active}
                        /> 
                        }
                    </div>
                        
                    <div className="col-7 d-flex d-xl-none">
                        <div className="text-medium-blue">
                            KILL PIVOT
                        </div>
                    </div>
                    <div className="col-5 col-xl-1">
                    {!this.props.disablePumpPivotStops &&
                        <ConfigurationToggleAsync
                            checked={this.props.alarmSetpoint.killPivot}
                            name="killPivot"
                            editValue={this.toggleAlarm}
                            onLabel="On"
                            offLabel="Off"
                            disabled={this.props.isDisabled || !this.props.alarmSetpoint.active}
                        />
                    }
                    </div>
                    
                    <div className="col-5 d-flex d-xl-none">
                        <div className="text-medium-blue">
                            NOTIFY
                        </div>
                    </div>
                    <div className="col-7 col-xl-3">
                        {this.props.alarmSetpoint.users.length <= 0 ? 'N/A' :
                            this.props.alarmSetpoint.users.map(u => u.name).join(', ')
                        }
                    </div>

                    <div className="col-7 d-flex d-xl-none">
                        <div className="text-medium-blue">
                            EDIT
                        </div>
                    </div>
                    <div className="col-5 col-xl-1 d-flex justify-content-end mt-2 mb-2">
                        <button 
                            className="button-status"
                            onClick={() => this.openModal()}
                            disabled={this.props.isDisabled}
                        >
                            <div className="text-box row align-items-center">
                                <div>
                                    CHANGE ALARM SETTINGS
                                </div>
                            </div>
                        </button>
                    </div>
                </div>
            );
        }
    }

    private toggleAlarm = (name: string, value: string): Promise<void> => {
        if (this.props.alarmSetpoint === undefined) {
            // No object passed in - exit
            return Promise.resolve();
        }

        // Grab a copy of the input alarm setpoint
        let alarmSetpoint = this.props.alarmSetpoint;

        // Convert from string to boolean and set it in our setpoint 
        alarmSetpoint = {
            ...alarmSetpoint,
            [name]: value === 'true'
        };

        // Check to see if pivot kill is enabled but pivot kill is disabled
        if (name === 'killPivot' && value === 'true') {
            alarmSetpoint.killPump = true;
        }

        if (name === 'killPump' && value !== 'true') {
            alarmSetpoint.killPivot = false;
        }

        // Call method to edit this setpoint
        return this.props.editAlarmSetpoint(alarmSetpoint);
    }

    private openModal = () => {
        this.setState({
            editSetpointModalOpen: true
        });
    }

    private closeModal = () => {
        this.setState({
            editSetpointModalOpen: false
        });
    }
}