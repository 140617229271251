import * as React from 'react';
import { Alarm, AlarmLevel } from '../models/Api';
import * as alarmApi from '../api/DeviceAlarms';
import { AlarmIndicator } from 'src/components/AlarmIndicator';

interface State {
    isFound: boolean | null;
    alarms: Alarm[];
}

export interface Props {
    deviceId: number;
}

export class ActiveAlarms extends React.Component<Props, State> {

    private alarmUpdateTimerId: number | null = null;

    constructor(props: Props) {
        super(props);
        
        this.state = {
            isFound: null,
            alarms: [],
        };

        this.getCurrentData = this.getCurrentData.bind(this);
    }

    componentDidMount() {
        this.getCurrentData();
        
        window.scrollTo(0, 0);
    }

    componentDidUpdate(prevProps: Props) {
        if (prevProps.deviceId !== this.props.deviceId) {
            this.getCurrentData();
        }
    }

    componentWillUnmount() {
        if (this.alarmUpdateTimerId !== null) {
            window.clearInterval(this.alarmUpdateTimerId);
        }
    }

    render() {
        let alarms = this.state.alarms;
        
        if (this.state.alarms[0] !== undefined && alarms[0].active) {
        return (
            <div className="row mb-4 mr-lg-2">
                <div className="component-standard-max300 w-100">
                    {/* Header */}                        
                    <div className="row">
                        <div className="col label-large text-dark-blue">
                            ACTIVE ALARMS
                        </div>
                    </div>
                    <div className="row d-none d-sm-flex mb-3 mb-sm-1">
                        <div className="col-8 col-sm-4 text-medium-blue">
                            TYPE
                        </div>
                        <div className="col-4 col-sm-4 text-medium-blue">
                            SEVERITY
                        </div>                        
                    </div>

                    {/* Alarms */}
                    {alarms.map(alarm => (
                        <div className="row mb-3 mb-sm-0" key={alarm.id}>
                            <div className="col-8 col-sm-4 text-medium-blue">
                                {alarm.type}
                            </div>
                            <div className="col-4 col-sm-4">
                                <AlarmIndicator
                                    severity={alarm.severity}
                                />
                                {alarm.severity}
                            </div>                            
                        </div>
                    ))}
                </div>     
            </div>
            );
        } else {
            return ( null );
        }
    }

    private getCurrentData() {
        // API is SUPER slow. Shut off the timer while waiting for the API so 
        // hundreds of requests don't get queued up
        if (this.alarmUpdateTimerId !== null) {
            window.clearInterval(this.alarmUpdateTimerId);
            this.alarmUpdateTimerId = null;
        }
        alarmApi.getAlarms(this.props.deviceId)
        .then((result) => {
            if (this.alarmUpdateTimerId === null) {
                // restart the timer
                this.alarmUpdateTimerId = window.setInterval(this.getCurrentData, 10000);
            }
            if (result.success) {
                // Device found - set state appropriately
                this.setState({
                    ...this.state,
                    alarms: result.data!.filter(
                        alarm => ( alarm.severity !== AlarmLevel.notification )
                        ),
                    isFound: true
                });
            } else {
                // Device not found
                this.setState({
                    isFound: false
                });
            }
        });
    }

}