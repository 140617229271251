import * as React from 'react';
import '../styles/Modal.css';
import '../styles/EditAlarmSetpointModal.css';
import * as Modal from 'react-modal';
import { AlarmSetpoint } from '../models/Api';

interface State {
    delaySec: number;
}

export interface Props {
    setpoint: AlarmSetpoint;
    showTimeout?: boolean;
    isModalOpen: boolean;
    editSetpoint: (setpoint: AlarmSetpoint) => void;
    closeModal: () => void;
}

export class EditTimeDelayModal extends React.Component<Props, State> {
    constructor(props: Props) {
        super(props);
        let localDelaySec = 0;
        if (props.setpoint.delaySec !== null) {
            localDelaySec = props.setpoint.delaySec;
        }
        this.state = {
            delaySec: localDelaySec
        };

        this.confirmButtonPressed = this.confirmButtonPressed.bind(this);
        this.onInputChange = this.onInputChange.bind(this);       
    }

    componentWillReceiveProps(nextProps: Props) {
        let localDelaySec = 0;
        if (nextProps.setpoint.delaySec !== null) {
            localDelaySec = nextProps.setpoint.delaySec;
        }
        if (!nextProps.isModalOpen) {
            this.setState({
                delaySec: localDelaySec
            });
        }
    }

    render() {
        return ( 
            <div className="Modal">
                <Modal 
                    ariaHideApp={false}
                    isOpen={this.props.isModalOpen}
                    shouldCloseOnEsc={true}
                    shouldCloseOnOverlayClick={true}
                    onRequestClose={this.props.closeModal}
                    className={{
                        base: 'modal-content',
                        afterOpen: 'modal-content_after-open',
                        beforeClose: 'modal-content_before-close'
                    }}
                    overlayClassName={{
                        base: 'modal-overlay',
                        afterOpen: 'modal-overlay_after-open',
                        beforeClose: 'modal-overlay_before-close'
                    }}
                >
                    <div className="container edit-alarm-setpoint-modal">
                        <label className="label-large col-form-label">
                            CHANGE ALARM TIME DELAY
                        </label>
                        <div className="row form-group"/>
                        {this.props.showTimeout && this.props.setpoint.delaySec !== null &&
                        <div className="row form-group d-flex align-items-center">
                            <div className="col-sm-5 text-medium-blue">
                                CURRENT DELAY
                            </div>
                            <div className="col justify-content-end">
                                <span className="text-bold">{this.props.setpoint.delaySec}</span>
                                <span>  s</span>
                            </div>
                        </div>}
                        {this.props.showTimeout && this.props.setpoint.delaySec !== null &&
                        <div className="row form-group d-flex align-items-center">
                            <div className="col-sm-5 text-medium-blue">
                                NEW DELAY
                            </div>
                            <div className="col">
                                <input
                                    className="textbox-style-right w-100"
                                    placeholder="Enter Alarm Delay"
                                    name="delaySec"
                                    value={this.state.delaySec}
                                    onChange={this.onInputChange}
                                    tabIndex={1}
                                />
                            </div>
                            <div className="col text-medium-blue no-padding-left">
                                     s 
                            </div>
                        </div>}
                        <div className="row form-group">
                            <div className="col">
                                <button
                                    className="btn btn-block button-standard confirm-button"
                                    tabIndex={2}
                                    onClick={this.confirmButtonPressed}
                                >
                                    Confirm
                                </button>
                            </div>
                            <div className="col">
                                <button
                                    className="btn btn-block button-standard cancel-button"
                                    tabIndex={3}
                                    onClick={this.props.closeModal}
                                >
                                    Cancel
                                </button>
                            </div>
                        </div>
                    </div>
                </Modal>
            </div>
        );
    }

    private onInputChange(e: React.SyntheticEvent<HTMLInputElement> | React.SyntheticEvent<HTMLSelectElement>) {
        this.setState({
            ...this.state,
            [e.currentTarget.name]: e.currentTarget.value
        });
    }

    private confirmButtonPressed() {
        // Get current setpoint object
        let setpoint = this.props.setpoint;

        // Overwrite setpoint value and totalizer ID
        setpoint.delaySec = this.state.delaySec;
        
        // Edit the setpoint
        this.props.editSetpoint(setpoint);

        // Then close the modal
        this.props.closeModal();
    }
}
