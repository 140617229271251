import * as React from 'react';
import { withRouter, RouteComponentProps } from 'react-router-dom';
import * as accountApi from '../api/Account';
import * as identity from '../identity';
import { history } from '../history';
import { ForgotPasswordModal } from '../components/ForgotPasswordModal';
import '../styles/Login.css';
import { ResetPasswordModal } from 'src/components/ResetPasswordModal';
const headerLogo = require('../images/reflex-logo-transparent.png');

interface State {
    email: string;
    password: string;
    message: string;
    forgotPasswordModalOpen: boolean;
    resetPasswordModalOpen: boolean;
}

export interface Props extends RouteComponentProps<{}> {}

class LoginPage extends React.Component<Props, State> {
    email: string | null = null;
    token: string | null = null;
    
    constructor(props: Props) {
        super(props);
        this.state = {
            email: '',
            password: '',
            message: '',
            forgotPasswordModalOpen: false,
            resetPasswordModalOpen: false,
        };
        this.confirmButtonPressed = this.confirmButtonPressed.bind(this);
        this.onInputChange = this.onInputChange.bind(this); 
        this.logoPressed = this.logoPressed.bind(this); 
        this.closeModal = this.closeModal.bind(this);
        this.openModal = this.openModal.bind(this);
    }
    
    componentWillMount() {
        const params = this.props.location.search;
        let e = params.match(new RegExp('email=(.*)&'));
        let t = params.match(new RegExp('token=(.*)'));

        if (e && t) {
            this.email = e[1];
            this.token = t[1];
            this.setState({resetPasswordModalOpen: true});
        }
    }

    render() {
        return (
            <div className="flex-root login-page">
                <div className="container">
                    <div className="row d-flex justify-content-center align-items-center">
                        <button className="logo-button">
                            <img src={headerLogo} onClick={this.logoPressed}/>
                        </button>
                    </div>
                    <br/>
                    <div className="row d-flex justify-content-center align-items-center">
                        <div className="col-md-5">
                            <div className="component-standard container">
                                <div
                                    className="component-login"
                                    onKeyDown={(k) => {
                                        if (k.key === 'Enter'
                                            && this.state.email
                                            && this.state.password) {
                                            this.confirmButtonPressed();
                                        }
                                    }}
                                >
                                    <label className="label-large col-form-label">
                                        LOGIN
                                    </label>
                                    
                                    <div className="row form-group d-flex align-items-center">
                                        <div className="col label-medium text-medium-blue">
                                            EMAIL
                                        </div>
                                    </div>
                                    <div className="row form-group d-flex align-items-center">
                                        <div className="col d-flex">
                                            <input 
                                                className="loginbox-style"
                                                type="email"
                                                value={this.state.email}
                                                onChange={this.onInputChange}
                                                name="email"
                                            />
                                        </div>
                                    </div>
                                    <div className="row form-group d-flex align-items-center">
                                        <div className="col label-medium text-medium-blue">
                                            PASSWORD
                                        </div>
                                    </div>
                                    <div className="row form-group d-flex align-items-center">
                                        <div className="col d-flex">
                                            <input 
                                                className="loginbox-style"
                                                type="password"
                                                value={this.state.password}
                                                onChange={this.onInputChange}
                                                name="password"
                                            />
                                        </div>
                                    </div>
                                    <div className="row form-group d-flex align-items-center justify-content-center">
                                        <div className="col-12 d-flex justify-content-center">
                                            <button
                                                className="btn button-standard login-button"
                                                tabIndex={2}
                                                disabled={!(this.state.email && this.state.password)}
                                                onClick={this.confirmButtonPressed}
                                            >
                                                LOGIN
                                            </button>
                                        </div>
                                    </div>
                                    <div className="row form-group d-flex align-items-center justify-content-center">
                                        <div className="col d-flex justify-content-center text-error">
                                            {this.state.message}
                                        </div>
                                    </div>
                                    <ForgotPasswordModal 
                                        isModalOpen={this.state.forgotPasswordModalOpen}
                                        closeModal={this.closeModal}
                                    />
                                    <ResetPasswordModal
                                        isModalOpen={this.state.resetPasswordModalOpen}
                                        closeModal={this.closeModal}
                                        loginUser={this.loginUser}
                                        email={this.email}
                                        token={this.token}
                                    />
                                </div>    
                            </div>
                        </div>
                    </div>
                    <div className="row d-flex justify-content-center align-items-center">
                        <div className="col-5 d-flex justify-content-left">
                            <button className="btn forgot-button" onClick={this.openModal}>
                                FORGOT PASSWORD?
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        );
    }

    openModal() {
        this.setState({
            forgotPasswordModalOpen: true
        });
    }

    openResetModal = () => {
        this.setState({
            resetPasswordModalOpen: true
        });
    }

    closeModal() {
        this.setState({
            forgotPasswordModalOpen: false,
            resetPasswordModalOpen: false
        });
    }

    private onInputChange(e: React.SyntheticEvent<HTMLInputElement> | React.SyntheticEvent<HTMLSelectElement>) {
        this.setState({
            ...this.state,
            [e.currentTarget.name]: e.currentTarget.value
        });
    }

    private confirmButtonPressed() {
        this.loginUser(this.state.email, this.state.password);
    }
    
    private loginUser = (user: string, pw: string) => {
        this.closeModal();
        accountApi.postLogin({
            email: user,
            password: pw,
        }).then((result) => {
            if (result.success && result.data) {
                identity.setTokens(result.data!);
                this.setState({
                    message: ''
                });
                history.push('/'); 
            } else {
                this.setState({
                    message: 'Invalid credentials, please retry',
                    password: ''
                });
            }
        });
    }

    private logoPressed() {
        history.push('/');
    }

}
    
export default withRouter(LoginPage);