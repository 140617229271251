import * as React from 'react';

interface State {

}

export interface Props {
    handleChange: (e: React.SyntheticEvent<HTMLInputElement>) => void;
    fieldName: string;
    value: string | number;
    APIName: string;
    type?: string;
    placeholder?: string;
}
 
export class UserAccountComponent extends React.Component<Props, State> {

    constructor(props: Props) {
        super(props);
    }

    render() {
        return (
                <div className="row flex-container list-divider">
                    <div className="col-2 text-medium-blue">                            
                        {this.props.fieldName}
                    </div>
                    <div className="col">                        
                        <input 
                            className="textbox-style"
                            type={this.props.type ? this.props.type : 'text'} 
                            value={this.props.value} 
                            onChange={(event) => this.props.handleChange(event)}
                            name={this.props.APIName}
                            placeholder={this.props.placeholder}
                        />                        
                    </div>
                </div>
        );
    }
}
