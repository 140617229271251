import * as React from 'react';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import { DeviceSettingsIrrigation } from '../../components/DeviceSettingsIrrigation';
import { DeviceSettingsWeather } from '../../components/DeviceSettingsWeather';
import { DeviceConfiguration, Device, Version } from '../../models/Api';
import { DeviceSettingsGeneral } from 'src/components/DeviceSettingsGeneral';

interface State {
}

export interface Props extends RouteComponentProps<{}> {
    deviceConfig: DeviceConfiguration;
    deviceInfo: Device;
    editConfigValue: (name: string, value: string) => void;
}

class DeviceSettingsSubPage extends React.Component<Props, State> {

    constructor(props: Props) {
        super(props);

        this.state = {
        };
    }

    render() {
        var version: Version = {
            major: this.props.deviceInfo.versionMajor,
            minor: this.props.deviceInfo.versionMinor,
            revision: this.props.deviceInfo.versionRev
        };

        return (
            <div className="subpage-standard">
                <div className="col">
                    <div className="row mb-4">
                        <DeviceSettingsGeneral 
                            deviceConfig={this.props.deviceConfig} 
                            deviceInfo={this.props.deviceInfo}
                            editConfigValue={this.props.editConfigValue}
                        />
                    </div>
                    <div className="row mb-4">
                        <DeviceSettingsIrrigation
                            deviceConfig={this.props.deviceConfig}
                            isDisconnected={this.props.deviceInfo.isDisconnected || this.props.deviceInfo.isDisabled}
                            editConfigValue={this.props.editConfigValue}
                            version={version}
                        />
                    </div >
                    <div className="row mb-4">
                        <DeviceSettingsWeather 
                            deviceConfig={this.props.deviceConfig}
                            editConfigValue={this.props.editConfigValue}
                        />
                    </div>
                </div>
            </div>
        );
    }
}

export default withRouter(DeviceSettingsSubPage);