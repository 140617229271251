import * as React from 'react';
import { AlarmSetpoint } from '../models/Api';
import { EditTimeDelayModal } from 'src/components/EditTimeDelayModal';

export interface Props {
    sectionName: string;
    showTimeout?: boolean;
    isDisabled: boolean;
    alarmSetpoint?: AlarmSetpoint;
    editAlarmSetpoint: (alarmSetpoint: AlarmSetpoint) => Promise<any>;
}

interface State {
    editTimeDelayModalOpen: boolean;
}

export class DeviceAlarmSectionHeader extends React.Component<Props, State> {
    constructor(props: Props) {
        super(props);

        this.state = {
            editTimeDelayModalOpen: false
        };
        
        this.closeModal = this.closeModal.bind(this);
    }

    render() {
        return (    
            /* Header */
            <div>
                {this.props.alarmSetpoint !== undefined && <EditTimeDelayModal
                    setpoint={this.props.alarmSetpoint}
                    isModalOpen={this.state.editTimeDelayModalOpen}
                    closeModal={this.closeModal}
                    showTimeout={this.props.showTimeout}
                    editSetpoint={this.props.editAlarmSetpoint}
                />}
                <div className="row">
                    <div className="col-9 label-large text-dark-blue mt-2">
                        {this.props.sectionName}
                    </div>
                    {/* <div className="col-2 text-medium-blue text-align-left mt-2">TIME DELAY : </div> */}
                    {this.props.alarmSetpoint !== undefined && this.props.showTimeout &&
                        <div className="col-2 mt-2">
                            <div className="row">
                                <div className="col-7 text-medium-blue">TIME DELAY:</div>
                                <div className="col-5">
                                    <span className="text-bold">{this.props.alarmSetpoint.delaySec}</span>
                                    <span> s </span>
                                </div>
                            </div>
                        </div>}
                    <div className="col-1 d-flex justify-content-end">
                        {this.props.showTimeout && 
                        <button 
                            className="button-status"
                            onClick={() => this.openModal()}
                            disabled={this.props.isDisabled}
                        >
                            <div className="text-box row align-items-center">
                                <div>
                                    CHANGE TIME DELAY
                                </div>
                            </div>
                        </button>}
                    </div>
                </div>

            {/* Table */}
                <div className="component">
                    <div className="row d-none d-xl-flex text-medium-blue">
                        <div className="col-2"/>
                        <div className="col-1 "> ENABLE </div>
                        <div className="col-3"> SETPOINT </div>
                        <div className="col-1 "> PUMP STOP </div>
                        <div className="col-1 "> PIVOT STOP </div>
                        <div className="col-3"> NOTIFY </div>
                        <div className="col-1"/>
                    </div>
                </div>
            </div>
        ); 
    }

    private openModal() {
        this.setState({
            editTimeDelayModalOpen: true
        });
    }

    private closeModal() {
        this.setState({
            editTimeDelayModalOpen: false
        });
    }
}