import * as React from 'react';
import Toggle from 'react-toggle';

export interface State {
    checked: boolean;
    disabled: boolean;
}

export interface Props {
    checked: boolean;
    name: string;
    onLabel: string;
    offLabel: string;
    editValue: (name: string, value: string) => void;
    inputClassname?: string;
    disabled?: boolean;
}

export class ConfigurationToggle extends React.Component<Props, State> {
    constructor(props: Props) {
        super(props);

        this.state = {
            checked: props.checked,
            disabled: false
        };

        this.onChange = this.onChange.bind(this);
    }

    componentWillReceiveProps(nextProps: Props) {
        this.setState({
            checked: nextProps.checked,
            disabled: false
        });
    }

    render() {
        return (
            <label className="mb-0 d-inline-flex" style={{'cursor': 'pointer'}}>
                <Toggle
                    icons={false}
                    checked={this.state.checked}
                    onChange={this.onChange}
                    disabled={this.state.disabled || this.props.disabled}
                />
                <span className="ml-3 align-middle">
                    {this.state.checked ? this.props.onLabel : this.props.offLabel}
                </span>
            </label>
        );
    }

    private onChange(e: React.SyntheticEvent<HTMLInputElement>) {
        // Toggle button has been clicked
        // Temporarily set state to new value - this value will get overwritten when this component receives props
        // Also disable the component. When server receives change, props get updated and component is reenabled
        this.setState({
            checked: e.currentTarget.checked,
            disabled: true
        });
        // Make call to API to set value
        this.props.editValue(this.props.name, e.currentTarget.checked.toString());
    }
}