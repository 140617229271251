import { AccountInfo } from 'src/models/Api';

export const getDefaultUserInfo = (): AccountInfo => {
    return {
        firstName : '',
        lastName : '',
        id: '',
        email : '',
        phoneNumber: '',
        licenseNumber: '0',
        licenseEffectiveDate: '2019-01-01',
        customerId: 0,
        customerName: '',
        isDeactivated: false,
        roles: ['Customer'],
        password: ''
    };
};