import * as React from 'react';
import * as Modal from 'react-modal';
import '../styles/Modal.css';
import { CustomerInfo } from 'src/models/Api';
import { addNewCustomerApi } from 'src/api/Users';
import { UserAddFieldComponent } from './UserAddFieldComponent';

interface State {
    setpoint: CustomerInfo;    
    message: string;
}

export interface Props {
    // addInfo: (responseInfo: AccountInfo) => void;
    isModalOpen: boolean;
    closeModal: () => void;
}

const getDefaultCustomerInfo = (): CustomerInfo => {
    return {
        name : '',
        id: 0,
    };
};

export class AddCustomerModal extends React.Component<Props, State> {
    constructor(props: Props) {
        super(props);
        
        this.state = {
            setpoint: getDefaultCustomerInfo(),
            message: ''
        };

        this.confirmButtonPressed = this.confirmButtonPressed.bind(this);
        this.onInputChange = this.onInputChange.bind(this);
    }    
    
    render() {
        return ( 
            <div className="Modal">
                <Modal 
                    ariaHideApp={false}
                    isOpen={this.props.isModalOpen}
                    shouldCloseOnEsc={true}
                    shouldCloseOnOverlayClick={true}
                    onRequestClose={this.props.closeModal}
                    className={{
                        base: 'modal-content',
                        afterOpen: 'modal-content_after-open',
                        beforeClose: 'modal-content_before-close'
                    }}
                    overlayClassName={{
                        base: 'modal-overlay',
                        afterOpen: 'modal-overlay_after-open',
                        beforeClose: 'modal-overlay_before-close'
                    }}
                >
                    <div className="container add-user-modal">
                        <label className="label-large col-form-label">
                            ADD CUSTOMER
                        </label>
                        <div className="row form-group"/>                                                
                        <UserAddFieldComponent 
                            handleChange={this.onInputChange}
                            value={this.state.setpoint.name}
                            fieldName="CUSTOMER NAME"
                            APIName="name"
                        />
                        
                        <div className="row mt-2 form-group d-flex align-items-center justify-content-center">
                            <div className="col d-flex justify-content-center text-error">
                                {this.state.message}
                            </div>
                        </div>                    
                        <div className="row form-group mt-2">
                            <div className="col">
                                <button
                                    className="btn btn-block button-standard confirm-button"
                                    tabIndex={2}
                                    onClick={this.confirmButtonPressed}
                                >
                                    CONFIRM
                                </button>
                            </div>
                            <div className="col">
                                <button
                                    className="btn btn-block button-standard cancel-button"
                                    tabIndex={3}
                                    onClick={this.cancelButtonPressed}
                                >
                                    CANCEL
                                </button>
                            </div>
                        </div>
                    </div>
                </Modal>
            </div>
        );
    }

    private onInputChange = (e: React.SyntheticEvent<HTMLInputElement>) => { 
        let newSetpoint = {...this.state.setpoint, [e.currentTarget.name]: e.currentTarget.value};
        this.setState({
            setpoint: newSetpoint
        });
    }

    private confirmButtonPressed = () => {
        // Edit the setpoint
        // this.props.addInfo(this.state.setpoint);
        addNewCustomerApi(this.state.setpoint.name).then(result => {
            if (result) {
                this.setState({
                    setpoint: getDefaultCustomerInfo(),
                    message: ''
                });
        
                // Then close the modal
                this.props.closeModal();
            } else {
                this.setState({
                    message: 'Error Adding Customer, is the name already in use?'
                });
            }
        });        
    }

    private cancelButtonPressed = (): void => {
        this.setState({
            message: ''
        });
        this.props.closeModal();
    }
}
